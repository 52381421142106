import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmDialogComponent } from './../dialogs/confirm/confirm-dialog.component';

import { IRow } from '../../interfaces/row';
import { IRowAction } from '../../interfaces/row-action';
import { ITableConfiguration } from '../../interfaces/table-configuration';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[fmx-table-row]',
  templateUrl: './row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowComponent implements OnInit {
  @Output() actionOutput = new EventEmitter<IRowAction>();

  @Input() row: IRow;
  @Input() index: number;
  @Input() tableConfiguration: ITableConfiguration;

  form: FormGroup;

  // Modals
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private fb: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    this.form = this.fb.group({});
  }

  clickCell(cellClickData: any) {
    this.clickCellAction(cellClickData);
  }

  // tslint:disable-next-line:no-reserved-keywords
  action(type: string) {
    switch (type) {
      case 'delete':
        this.deleteAction();
        break;
      case 'edit':
        this.editAction();
        break;
      case 'save':
        this.saveAction();
        break;
      case 'cancelEdit':
        this.cancelEditAction();
        break;
      default:
    }
  }

  clickCellAction(data: any) {
    const rowAction: IRowAction = {
      id: this.row.id,
      index: this.index,
      action: 'clickCell',
      data
    };
    this.actionOutput.emit(rowAction);
  }

  editAction() {
    this.updateForm();
    this.row.editMode = true;
  }

  cancelEditAction() {
    this.row.editMode = false;
  }

  saveAction() {
    if (this.form.valid) {
      const rowAction: IRowAction = {
        id: this.row.id,
        index: this.index,
        action: 'save',
        data: this.form.value
      };
      this.actionOutput.emit(rowAction);
    }
    this.row.editMode = false;
  }

  deleteAction() {
    this.openConfirmDialog();
  }

  confirmDelete() {
    const rowAction: IRowAction = {
      id: this.row.id,
      index: this.index,
      action: 'delete',
      data: null
    };
    this.actionOutput.emit(rowAction);
  }

  updateForm() {
    const group = {};
    this.row.cells.forEach((cell: any) => {
      if (cell.editable) {
        const value = cell.editable.value || cell.value;
        let parseValue = `${value}`;
        if (cell.editable.type === 'checkbox') {
          parseValue = value;
        }
        group[cell.editable.name] = [
          parseValue,
          cell.editable.validators || []
        ];
      }
    });

    this.form = this.fb.group(group);
  }

  openConfirmDialog() {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: getResolutionModal(),
      data: {
        title: 'Confirmación',
        content: '¿Seguro que desea eliminar esta fila?'
      }
    });

    this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.confirmDelete();
      }
    });
  }
}
