import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { INITIAL_PAGE } from '../const/initial-pages.const';
import { CurrentUser } from '../models/current-user.model';
import { CurrentUserService } from '../services/current-user/current-user.service';
import { navigateToUserPages } from '../utils/redirect-initial-page.utils';

@Injectable()
export class InitGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService
  ) {}

  canActivate(): boolean {
    const currentUser: CurrentUser = this.currentUserService.currentUser;

    if (currentUser) {
      this.router.navigate([navigateToUserPages(currentUser)], {
        replaceUrl: true
      });
    } else {
      this.router.navigate([INITIAL_PAGE.login], { replaceUrl: true });
    }

    return false;
  }
}
