var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
var ZonesService = /** @class */ (function () {
    function ZonesService(http) {
        this.http = http;
    }
    ZonesService.prototype.createZone = function (data) {
        var endpoint = 'zones';
        return this.http.post(endpoint, data);
    };
    ZonesService.prototype.updateZone = function (idZone, data) {
        var endpoint = "zones/" + idZone;
        return this.http.patch(endpoint, data);
    };
    ZonesService.prototype.deleteZone = function (idZone) {
        var endpoint = "zones/" + idZone;
        return this.http.delete(endpoint);
    };
    ZonesService.prototype.getZones = function (sort, pagination) {
        var endpoint = "zones";
        var params = __assign({}, sort, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ZonesService.prototype.getSpacesWithoutZones = function (sort, pagination) {
        var endpoint = "zones";
        var params = __assign({}, sort, pagination);
        return this.http.get(endpoint, { params: params });
    };
    return ZonesService;
}());
export { ZonesService };
