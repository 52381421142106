import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ICell } from './../../../interfaces/cell';
import { ICellConfiguration } from './../../../interfaces/cell-configuration';

import { ConfigurationService } from './../../../services/configuration.service';

@Component({
  selector: 'fmx-cell-price',
  templateUrl: './cell-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellPriceComponent {
  styles: string;
  _cell: any = {};

  @Input()
  set cell(item: ICell) {
    this._cell.value = item.value;
    this._cell.configuration = {
      ...this.defaultConf,
      ...(item.configuration || {})
    };
    this.setStyles();
  }

  defaultConf: ICellConfiguration = {
    bold: true,
    align: 'left',
    color: 'primary',
    width: 'medium'
  };

  constructor(private configStyles: ConfigurationService) {}

  setStyles() {
    this.styles = this.configStyles.getStylesString(this._cell.configuration);
  }
}
