export class Operator {
  id: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  attributes: Attributes;

  constructor(that: any) {
    Object.assign(this, that);
  }

  fullName(): string {
    return `${this.attributes.first_name} ${this.attributes.last_name}`;
  }
}

interface Attributes {
  email: string;
  email_confirmed: boolean;
  first_name: string;
  last_name: string;
  level: string;
  visible: boolean;
}
