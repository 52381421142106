import { Injectable } from '@angular/core';

@Injectable()
export class RouteRefererService {
  private _routeReferer: string;

  set routeReferer(value: string) {
    this._routeReferer = value;
  }

  get routeReferer(): string {
    return this._routeReferer;
  }

  get hasRoute(): boolean {
    return !!this._routeReferer;
  }

  reset() {
    this._routeReferer = null;
  }
}
