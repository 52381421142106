export const environment = {
  production: false,
  environment: 'qa',
  apiUrl: 'https://api.famaex.dev/api/v1/',
  enabledFirebase: true,
  firebase: {
    apiKey: 'AIzaSyB_UPxyvu04TIEyOO-Fi_wCfp9wQ5hBtJs',
    authDomain: 'famaex-web.firebaseapp.com',
    databaseURL: 'https://famaex-web.firebaseio.com',
    projectId: 'famaex-web',
    storageBucket: 'famaex-web.appspot.com',
    messagingSenderId: '140437434011',
    appId: '1:140437434011:web:6abf8b42b75e2ca6c6603e',
    measurementId: 'G-X1HQDZ85XZ'
  },
  enabledAirbrake: true,
  airbrake: {
    projectId: 422843,
    projectKey: '1eb57cc0e45b12f87744fff622811bce',
    environment: 'qa'
  }
};
