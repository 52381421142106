var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { filter, mergeMap } from 'rxjs/operators';
// Components
import { ConfirmDialogComponent } from '@app/fmx-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AddEditComplaintModalComponent } from '../add-edit-complaint-modal/add-edit-complaint-modal.component';
import { FmxSelectModalComponent } from '../fmx-select-modal/fmx-select-modal.component';
import { MarkAsCompleteComplaintModalComponent } from '../mark-as-complete-complaint-modal/mark-as-complete-complaint-modal.component';
// Services
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { SpacesService } from '@app/core/services/spaces/spaces.service';
import { ComplaintsFacadeService } from '@app/features/complaints/services/complaints-facade.service';
// Extends Class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';
import { Complaint } from '@app/features/complaints/models/complaint.model';
var ServiceComplaintItemComponent = /** @class */ (function (_super) {
    __extends(ServiceComplaintItemComponent, _super);
    function ServiceComplaintItemComponent(dialog, facade, spaceService, fmxToastr, router, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.dialog = dialog;
        _this.facade = facade;
        _this.spaceService = spaceService;
        _this.fmxToastr = fmxToastr;
        _this.router = router;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.inServiceDetail = false;
        _this.refreshList = new EventEmitter();
        _this.icon = {
            svgIcon: 'assets/svg/map.svg#semaphore_square'
        };
        _this.configModal = {
            title: 'Relacionar servicio',
            list: [],
            confirmButton: 'CONFIRMAR',
            showTextarea: false,
            selectLabel: 'Servicio'
        };
        return _this;
    }
    Object.defineProperty(ServiceComplaintItemComponent.prototype, "attributes", {
        get: function () {
            return this.complaint && this.complaint.attributes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceComplaintItemComponent.prototype, "hideBtnCreate", {
        get: function () {
            return (this.complaint.attributes.from_issue ||
                !!this.complaint.generatedServiceId());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceComplaintItemComponent.prototype, "isPendingToProvider", {
        get: function () {
            return this.complaint.isPendingToProvider();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceComplaintItemComponent.prototype, "expiredComplaint", {
        get: function () {
            return this.complaint.expiredComplaint();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceComplaintItemComponent.prototype, "isPendingOrOnGoing", {
        get: function () {
            return this.complaint.isPending() || this.complaint.isOnGoing();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceComplaintItemComponent.prototype, "generatedServiceId", {
        get: function () {
            return this.complaint.generatedServiceId();
        },
        enumerable: true,
        configurable: true
    });
    ServiceComplaintItemComponent.prototype.refreshComplaintList = function () {
        this.refreshList.emit();
    };
    ServiceComplaintItemComponent.prototype.openConfirmDelete = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: getResolutionModal(),
            data: {
                title: 'Confirmación',
                content: '¿Seguro que desea eliminar la reclamación seleccionada?'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.deleteComplaint();
            }
        });
    };
    ServiceComplaintItemComponent.prototype.editComplaint = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AddEditComplaintModalComponent, {
            width: getResolutionModal(),
            data: {
                complaint: this.complaint,
                title: 'Editar reclamación',
                submit: 'Actualizar reclamación'
            }
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (response) { return response; }))
            .subscribe(function () { return _this.refreshComplaintList(); });
    };
    ServiceComplaintItemComponent.prototype.markAsCompleteComplaint = function () {
        var _this = this;
        var dialogRef = this.dialog.open(MarkAsCompleteComplaintModalComponent, {
            width: getResolutionModal(),
            data: {
                complaint: this.complaint
            }
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (response) { return response; }))
            .subscribe(function () { return _this.refreshComplaintList(); });
    };
    ServiceComplaintItemComponent.prototype.goToService = function () {
        this.router.navigate([
            "/service-detail/" + this.complaint.generatedServiceId()
        ]);
    };
    ServiceComplaintItemComponent.prototype.createIssue = function () {
        this.router.navigate([
            "/services/complaint/" + this.complaint.id + "/new-service"
        ]);
    };
    ServiceComplaintItemComponent.prototype.relatedService = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FmxSelectModalComponent, {
            width: getResolutionModal(),
            data: this.configModal
        });
        dialogRef.afterClosed().subscribe(function (data) {
            if (data && data.result) {
                var params = {
                    service: {
                        source_complaint_id: _this.complaint.id
                    }
                };
                var serviceId = data.data.list;
                _this.facade.updateService(serviceId, params);
            }
        });
    };
    ServiceComplaintItemComponent.prototype.refresh = function () {
        this.facade.loadComplaints();
    };
    ServiceComplaintItemComponent.prototype.getServices = function () {
        var _this = this;
        var serviceId = this.complaint.relationships.service.data.id;
        this.facade
            .getServiceSpace(serviceId)
            .pipe(mergeMap(function (service) {
            return _this.facade.getSpaceServices(service.data.attributes.space_id);
        }))
            .subscribe(function (response) {
            var formatServices = response.data.map(function (service) {
                return {
                    value: service.id,
                    name: service.id + " - " + service.attributes.description
                };
            });
            (_a = _this.configModal.list).push.apply(_a, formatServices);
            if (response.meta.current_page === response.meta.last_page ||
                response.meta.last_page === 0) {
                _this.spaceService.expandObservable = false;
                _this.relatedService();
            }
            var _a;
        });
    };
    ServiceComplaintItemComponent.prototype.deleteComplaint = function () {
        var _this = this;
        this.facade.deleteComplaint(this.complaint.id).subscribe(function () {
            _this.fmxToastr.success('Reclamación eliminada correctamente');
            _this.refreshComplaintList();
        }, function () {
            _this.fmxToastr.error('Error al eliminar reclamación, por favor pruebe en unos minutos.');
        });
    };
    return ServiceComplaintItemComponent;
}(BaseCurrentUserComponent));
export { ServiceComplaintItemComponent };
