import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { MatSelectModule } from '@angular/material';

// Components
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FmxSelectComponent } from './components/fmx-select/fmx-select.component';

const MODULES = [
  MatSelectModule,
  FormsModule,
  ReactiveFormsModule,
  NgxMatSelectSearchModule
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  declarations: [FmxSelectComponent],
  exports: [FmxSelectComponent]
})
export class FmxSelectModule {}
