import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Const
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';

// Models
import { DataPage } from '@app/fmx-shared/interfaces/data-page.interface';

// Utils
import { generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';

@Injectable()
export class CompanyAggrupationsService {
  constructor(private http: HttpClient) {}

  getSearch(
    idClient: number,
    filter: any,
    sort: any,
    pagination: any
  ): Observable<any> {
    const endpoint = `company_aggrupations/${idClient}/search`;

    const params = {
      ...filter,
      ...sort,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  createClient(data: any): Observable<any> {
    const endpoint = 'accounts';

    return this.http.post(endpoint, data);
  }

  getCompanyAggrupations(
    filter: any,
    sort: any,
    pagination: any
  ): Observable<any> {
    const endpoint = `company_aggrupations`;

    const params = {
      ...filter,
      ...sort,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getCompanyAggrupation(idCompanyAggrupation: string): Observable<any> {
    const endpoint = `company_aggrupations/${idCompanyAggrupation}`;

    return this.http.get(endpoint);
  }

  getCompanyAggrupationsByPage(
    page: number,
    search: string = ''
  ): Observable<DataPage> {
    let params = new HttpParams()
      .set('sort_by', 'name')
      .set('sort_dir', 'asc')
      .set('services', 'true')
      .set('minimum_api', 'true')
      .set('per_page', ELEMENTS_PER_PAGE)
      .set('page', page.toString());

    if (search) {
      params = params.append('name', search);
    }

    const endpoint = 'company_aggrupations';

    return this.http
      .get(endpoint, { params })
      .pipe(map(generateResponseDataPage));
  }

  updateCompanyAggrupation(
    idClient: number | string,
    params: any
  ): Observable<any> {
    const endpoint = `company_aggrupations/${idClient}`;

    return this.http.patch(endpoint, params);
  }

  getChildCompanyAggrupation(caId: string): Observable<any> {
    const endpoint = `company_aggrupations/${caId}/children`;

    return this.http.get(endpoint);
  }

  createCompany(idCompanyAgg: number, data: any): Observable<any> {
    const endpoint = `company_aggrupations/${idCompanyAgg}/companies`;

    return this.http.post(endpoint, data);
  }

  getCurrentBasePricelist(clientId: any): Observable<any> {
    return this.http.get(`company_aggrupations/${clientId}/pricelists`);
  }

  getUsersClient(idClient: string): Observable<any> {
    const endpoint = `company_aggrupations/${idClient}/users`;

    return this.http.get(endpoint);
  }

  addUserClient(parent: string, idClient: number, data: any): Observable<any> {
    const endpoint = `${parent}/${idClient}/users`;

    return this.http.post(endpoint, data);
  }

  getFiscalInfoCompanyAggrupation(idClient: string): Observable<any> {
    const endpoint = `company_aggrupations/${idClient}/fiscal_info?include=state.country`;

    return this.http.get(endpoint);
  }

  getPricelistPdf(idCompanyAgg: number, idPricelist: number): Observable<Blob> {
    const endpoint = `company_aggrupations/${idCompanyAgg}/pricelists/${idPricelist}.pdf`;

    return this.http.get(endpoint, { responseType: 'blob' });
  }

  getServiceLogsClient(
    idClient: string,
    filter: any,
    pagination: any
  ): Observable<any> {
    const endpoint = `company_aggrupations/${idClient}/service_logs`;

    const params = { ...filter, ...pagination };

    return this.http.get(endpoint, { params });
  }
}
