import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanP'
})
export class BooleanPipe implements PipeTransform {
  transform(value: string): string {
    return value ? 'Si' : 'No';
  }
}
