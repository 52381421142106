import { Pipe, PipeTransform } from '@angular/core';

const BREACH_KINDS = {
  other: 'Otros',
  budget_confirmation_delay: 'Retraso confirmación presupuesto',
  review_delay: 'Retraso revisión',
  visit_delay: 'Retraso en visita',
  finalization_delay: 'Retraso finalización',
  budget_selection_delay: 'Retraso en selección de presupuesto',
  documentation_delay: 'Retraso en documentación',
  urgent_visit_delay: 'Retraso en visita urgente',
  urgent_user_contact_delay: 'Retraso en contacto con usuario urgente',
  price_deviation: 'Desviación de precio',
  issues_accumulation: 'Acumulación de incidencias',
  issues_solving_delay: 'Retraso solución de incidencias',
  issues_creation_assignment_delay: 'Retraso asignando incidencia',
  issues_reporting_delay: 'Retraso reportando incidencias',
  not_answering_urgency_phone: 'No se responde al teléfono de urgencias',
  lateness: 'Llegaron tarde',
  not_showing_up: 'No se presentaron',
  fraud: 'Fraude',
  bad_execution: 'Mala ejecución',
  bad_clothing: 'Vestimenta inadecuada',
  bad_attitude: 'Mala educación',
  billing_pending: 'Facturación pendiente',
  breaches_accumulation: 'Acumulación de incidencias',
  user_contact_delay: 'Retraso en contacto con usuario'
};

@Pipe({
  name: 'breachKind'
})
export class BreachKindPipe implements PipeTransform {
  transform(value: string): string {
    return BREACH_KINDS[value] || value;
  }
}
