import { Injectable } from '@angular/core';

// Extends Service
import { CurrentUserFacadeService } from '../current-user/current-user.facade.service';

const REJECTED_STATUS = 'rejected';
const FINALIZED_MANDATORY_STATUSES = ['completed', REJECTED_STATUS];
const CANT_EDIT_STATUSES = ['on_hold'];

@Injectable()
export class PermitsService {
  service: any;

  get serviceStatus(): string {
    return this.service ? this.service.status : '';
  }

  constructor(private currentUserServiceFacade: CurrentUserFacadeService) {}

  canEditService(): boolean {
    if (CANT_EDIT_STATUSES.includes(this.serviceStatus)) {
      return false;
    }

    if (FINALIZED_MANDATORY_STATUSES.includes(this.serviceStatus)) {
      if (!this.currentUserServiceFacade.isOperator()) {
        return false;
      }

      if (
        this.currentUserServiceFacade.hasLevelRegular() ||
        this.currentUserServiceFacade.hasLevelFinance()
      ) {
        return false;
      }

      return true;
    }

    if (
      this.currentUserServiceFacade.isOperator() &&
      this.currentUserServiceFacade.hasLevelRegular() &&
      this.currentUserServiceFacade.currentServiceIsMine
    ) {
      return true;
    }

    if (
      this.currentUserServiceFacade.isOperator() &&
      !this.currentUserServiceFacade.hasLevelRegular()
    ) {
      return true;
    }

    return false;
  }

  canEditBudget(budget: any): boolean {
    if (
      !budget.locked &&
      this.serviceStatus !== REJECTED_STATUS &&
      !this.service.invoice_id
    ) {
      return true;
    }

    return false;
  }

  canCancelService(): boolean {
    return (
      !CANT_EDIT_STATUSES.includes(this.serviceStatus) &&
      !FINALIZED_MANDATORY_STATUSES.includes(this.serviceStatus)
    );
  }

  canCancelInvoice(): boolean {
    return this.isAdminOrFinanceOperator();
  }

  canCancelReceiptAcquittance(): boolean {
    return this.isAdminOrFinanceOperator();
  }

  canClientEditComplaint(): boolean {
    return (
      this.currentUserServiceFacade.isClient() &&
      !CANT_EDIT_STATUSES.includes(this.serviceStatus)
    );
  }

  private isAdminOrFinanceOperator(): boolean {
    return (
      this.currentUserServiceFacade.isOperator() &&
      (this.currentUserServiceFacade.hasLevelSuperAdmin() ||
        this.currentUserServiceFacade.hasLevelAdmin() ||
        this.currentUserServiceFacade.hasLevelFinance())
    );
  }
}
