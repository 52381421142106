var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
// Components
import { BaseCurrentUserComponent } from './core/extends/base-current-user.component';
import { AuthService } from './core/services/auth/auth.service';
// Services
import { CurrentUserFacadeService } from './core/services/current-user/current-user.facade.service';
import { FirebaseService } from './core/services/firebase/firebase.service';
import { LoadingService } from './core/services/loading/loading.service';
import { PermitsService } from './core/services/permits/permits.service';
import { ScriptLoaderService } from './core/services/script-loader/script-loader.service';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(authService, translate, loadingService, firebaseService, router, scriptLoaderService, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.authService = authService;
        _this.translate = translate;
        _this.loadingService = loadingService;
        _this.firebaseService = firebaseService;
        _this.router = router;
        _this.scriptLoaderService = scriptLoaderService;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.title = 'Famaex';
        _this.isLoading = false;
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.translate.setDefaultLang('es');
        this.translate.addLangs(['es', 'en']);
        this.handleLoading();
        if (environment.production) {
            this.firebaseService.initApp();
        }
        if (this.isOperator()) {
            this.scriptLoaderService.load('Hotjar');
        }
        this.checkCurrentUrl();
    };
    AppComponent.prototype.checkCurrentUrl = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.currentUrl = event.urlAfterRedirects;
            }
        });
    };
    AppComponent.prototype.switchLanguage = function (language) {
        this.translate.use(language);
    };
    AppComponent.prototype.handleLoading = function () {
        var _this = this;
        this.loadingService.loading.subscribe(function (data) {
            setTimeout(function () {
                _this.isLoading = data;
            });
        });
    };
    return AppComponent;
}(BaseCurrentUserComponent));
export { AppComponent };
