import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface RechargesRepaysStateModel {
  rechargesRepaysList: any[];
  filterRechargesRepays: any;
  loading: boolean;
  pagination: any;
}

const DEFAULT_STATE: RechargesRepaysStateModel = {
  rechargesRepaysList: null,
  filterRechargesRepays: {
    company_aggrupation: '',
    provider: '',
    service: '',
    created_at_from: moment().startOf('month').toDate(),
    created_at_to: moment().endOf('month').toDate(),
    pending: ''
  },
  loading: false,
  pagination: {
    per_page: 30,
    current_page: 1,
    last_page: null,
    page: 1
  }
};

@Injectable()
export class RechargesRepaysState {
  rechargesRepaysListSub = new BehaviorSubject<any>(
    DEFAULT_STATE.rechargesRepaysList
  );
  rechargesRepaysList$ = this.rechargesRepaysListSub.asObservable();

  filterRechargesRepaysSub = new BehaviorSubject<boolean>(
    DEFAULT_STATE.filterRechargesRepays
  );
  filterRechargesProviders$ = this.filterRechargesRepaysSub.asObservable();

  paginationSub = new BehaviorSubject<boolean>(DEFAULT_STATE.pagination);
  pagination$ = this.paginationSub.asObservable();

  loadingSub = new BehaviorSubject<boolean>(DEFAULT_STATE.loading);
  loading$ = this.loadingSub.asObservable();

  // tslint:disable:no-reserved-keywords
  set(key: string, payload: any) {
    this[key].next(payload);
  }

  get(key: string) {
    return this[key].getValue();
  }

  reset() {
    this.filterRechargesRepaysSub.next(DEFAULT_STATE.filterRechargesRepays);

    this.rechargesRepaysListSub.next(DEFAULT_STATE.rechargesRepaysList);

    this.loadingSub.next(DEFAULT_STATE.loading);

    this.paginationSub.next(DEFAULT_STATE.pagination);
  }

  resetKey(key: string, keySub: string) {
    this[key].next(DEFAULT_STATE[keySub]);
  }
}
