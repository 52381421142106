import { Injectable } from '@angular/core';

import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { ComplaintsService } from '@app/core/services/complaints/complaints.service';
import { DataService } from '@app/core/services/data/data.service';
import { ProvidersService } from '@app/core/services/providers/providers.service';
import { ServicesService } from '@app/core/services/services/services.service';
import { SpacesService } from '@app/core/services/spaces/spaces.service';
import { UserService } from '@app/core/services/user/user.service';
import { ComplaintsState } from '../state/complaints.state';

// Extends Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { CurrentUserService } from '@app/core/services/current-user/current-user.service';

// Models
import { SelectOption } from '@app/core/models/select-option.model';

// Utils
import { Complaint } from '../models/complaint.model';

@Injectable({
  providedIn: 'root'
})
export class ComplaintsFacadeService extends CurrentUserFacadeService {
  constructor(
    private state: ComplaintsState,
    private complaintsService: ComplaintsService,
    private dataService: DataService,
    private servicesService: ServicesService,
    private spacesService: SpacesService,
    private providersService: ProvidersService,
    private userService: UserService,
    private fmxToastr: FmxToastr,
    protected currentUserService: CurrentUserService
  ) {
    super(currentUserService);
  }

  loadComplaints(): void {
    this.state.set('loadingComplaintsSub', true);
    const pagination = this.state.get('paginationComplaintsSub');

    const service: Observable<Complaint[]> = this.getEndpointComplaints().pipe(
      tap((response: any) => this.setPagination(pagination, response)),
      map((response: any) =>
        response.data.map(
          (complaint: any) => new Complaint(complaint, response.included)
        )
      )
    );

    service
      .pipe(finalize(() => this.state.set('loadingComplaintsSub', false)))
      .subscribe((response: Complaint[]) =>
        this.state.set('complaintsSub', response)
      );
  }

  changePage(page: number): void {
    const pagination = this.state.get('paginationComplaintsSub');

    this.state.set('paginationComplaintsSub', {
      ...pagination,
      page
    });

    this.loadComplaints();
  }

  changeFilter(filter: any): void {
    this.state.set('filterComplaintsSub', {
      ...filter
    });

    this.loadComplaints();
  }

  resetFilter(): void {
    this.state.resetKey('filterComplaintsSub', 'filterComplaints');
    this.state.resetKey('paginationComplaintsSub', 'paginationComplaints');

    this.loadComplaints();
  }

  deleteComplaint(complaintId: string): Observable<any> {
    return this.complaintsService.deleteComplaint(complaintId);
  }

  createComplaint(serviceId: number, data: any): Observable<any> {
    return this.complaintsService.createComplaint(serviceId, data);
  }

  editComplaint(complaintId: number, data: any): Observable<any> {
    return this.complaintsService.updateComplaint(complaintId, data);
  }

  markAsCompleteComplaint(
    idProvider: string,
    idComplaint: string,
    data: any
  ): Observable<any> {
    return this.providersService.markAsCompleteComplaint(
      idProvider,
      idComplaint,
      data
    );
  }

  getComplaintKinds(): Observable<SelectOption[]> {
    return this.dataService.getComplaintKinds();
  }

  getSeveritiesComplaints(): Observable<SelectOption[]> {
    return this.dataService.getSeveritiesComplaints();
  }

  getComplaintStatuses(): Observable<SelectOption[]> {
    return this.dataService.getComplaintsStatuses();
  }

  getServiceSpace(serviceId: string): Observable<any> {
    return this.servicesService.getService(serviceId);
  }

  getSpaceServices(spaceId: string): Observable<any> {
    return this.spacesService.getSpaceServices(spaceId);
  }

  updateService(serviceId: number, data: any): void {
    this.servicesService.updateService(serviceId, data).subscribe(
      () => {
        this.fmxToastr.success('Servicio asociado correctamente');
        this.loadComplaints();
      },
      () => {
        this.fmxToastr.error(
          'Error al asociar servicio, por favor pruebe en unos minutos.'
        );
      }
    );
  }

  getComplaintProviderStatus(): Observable<SelectOption[]> {
    return this.dataService.getComplaintProviderStatuses();
  }

  private getEndpointComplaints(): Observable<Complaint[]> {
    const filter = this.state.get('filterComplaintsSub');
    const pagination = this.state.get('paginationComplaintsSub');

    if (this.isOperator()) {
      return this.complaintsService.getComplaints(filter, pagination);
    }

    if (this.isProvider()) {
      return this.providersService.getComplaintsProvider(
        filter,
        pagination,
        this.relationships().provider.data.id
      );
    }

    return this.userService.getComplaintsClient(
      filter,
      pagination,
      this.currentUser.id
    );
  }

  private setPagination(pagination: any, response: any): void {
    this.state.set('paginationComplaintsSub', {
      ...pagination,
      current_page: response.meta.current_page,
      last_page: response.meta.last_page
    });
  }
}
