export function prepareBudget(response: any) {
  const data = response.data ? response.data : response;
  const items = response.budget_items
    ? response.budget_items
    : response.included;

  return {
    assessment: { ...data.attributes, id: data.id },
    items
  };
}

export function getBudgetFormat(attributes: any, assessmentItems: any[]) {
  return {
    data: {
      id: attributes.id,
      attributes: { ...attributes }
    },
    included: [...assessmentItems]
  };
}
