import { CurrentUserService } from './current-user.service';
var CurrentUserFacadeService = /** @class */ (function () {
    function CurrentUserFacadeService(currentUserService) {
        this.currentUserService = currentUserService;
        this._currentServiceIsMine = false;
        this._currentServiceIsMineAsNetworkManager = false;
    }
    Object.defineProperty(CurrentUserFacadeService.prototype, "currentUser", {
        get: function () {
            return this.currentUserService.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserFacadeService.prototype, "currentServiceIsMine", {
        get: function () {
            return this._currentServiceIsMine;
        },
        set: function (value) {
            this._currentServiceIsMine = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserFacadeService.prototype, "currentServiceIsMineAsNetworkManager", {
        get: function () {
            return this._currentServiceIsMineAsNetworkManager;
        },
        set: function (value) {
            this._currentServiceIsMineAsNetworkManager = value;
        },
        enumerable: true,
        configurable: true
    });
    CurrentUserFacadeService.prototype.currentUserLoaded = function () {
        return this.currentUserService._currentUser$;
    };
    CurrentUserFacadeService.prototype.isOperator = function () {
        return (this.currentUserService.currentUser &&
            this.currentUserService.currentUser.isOperator());
    };
    CurrentUserFacadeService.prototype.isClient = function () {
        return (this.currentUserService.currentUser &&
            this.currentUserService.currentUser.isClient());
    };
    CurrentUserFacadeService.prototype.isProvider = function () {
        return (this.currentUserService.currentUser &&
            this.currentUserService.currentUser.isProvider());
    };
    CurrentUserFacadeService.prototype.isDoerManager = function () {
        return this.currentUser.attributes && this.currentUser.attributes.manager;
    };
    CurrentUserFacadeService.prototype.hasLevelSuperAdmin = function () {
        return this.currentUserService.currentUser.hasLevelSuperAdmin();
    };
    CurrentUserFacadeService.prototype.hasLevelAdmin = function () {
        return this.currentUserService.currentUser.hasLevelAdmin();
    };
    CurrentUserFacadeService.prototype.hasLevelFinance = function () {
        return this.currentUserService.currentUser.hasLevelFinance();
    };
    CurrentUserFacadeService.prototype.hasLevelRegular = function () {
        return this.currentUserService.currentUser.hasLevelRegular();
    };
    CurrentUserFacadeService.prototype.relationships = function () {
        return this.currentUser.relationships;
    };
    CurrentUserFacadeService.prototype.attributes = function () {
        return this.currentUser.attributes;
    };
    CurrentUserFacadeService.prototype.hasRelationWithCA = function (idCA) {
        return (this.isOperator() ||
            this.currentUser.relationships.company_aggrupations.data.some(function (item) { return +item.id === idCA; }));
    };
    CurrentUserFacadeService.prototype.hasRelationWithCompany = function (idCompany) {
        return (this.isOperator() ||
            this.currentUser.relationships.companies.data.some(function (item) { return +item.id === idCompany; }));
    };
    CurrentUserFacadeService.prototype.hasRelationWithGroup = function (idGroup) {
        return (this.isOperator() ||
            this.currentUser.relationships.groups.data.some(function (item) { return +item.id === idGroup; }));
    };
    return CurrentUserFacadeService;
}());
export { CurrentUserFacadeService };
