<div class="dialog-header">
  <div class="dialog-header-title">Descargar excel</div>
  <div class="dialog-close-button">
    <button
      (click)="close()"
      class="fmx-btn fmx-btn-clear fmx-btn-icon fmx-btn-secondary"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="row">
  <div class="col-12 mt-3">
    <span class="font-weight-bold color-secondary font-xs"
      >La petición de descarga ha sido generada correctamente, en breves
      recibirá en su email {{ emailProfile }} el fichero para su descarga</span
    >
  </div>
</mat-dialog-content>
<mat-dialog-actions class="col-12 d-flex flex-h-space-between">
  <button (click)="close()" class="fmx-btn fmx-btn-green m-l-5 m-r-5">
    ACEPTAR
  </button>
</mat-dialog-actions>
