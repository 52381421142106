// Services
import { CurrentUserFacadeService } from '../services/current-user/current-user.facade.service';
import { PermitsService } from '../services/permits/permits.service';
var BaseCurrentUserComponent = /** @class */ (function () {
    function BaseCurrentUserComponent(currentUserFacade, permitsService) {
        this.currentUserFacade = currentUserFacade;
        this.permitsService = permitsService;
    }
    Object.defineProperty(BaseCurrentUserComponent.prototype, "currentUser", {
        get: function () {
            return this.currentUserFacade.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCurrentUserComponent.prototype, "currentServiceIsMine", {
        get: function () {
            return this.currentUserFacade.currentServiceIsMine;
        },
        set: function (value) {
            this.currentUserFacade.currentServiceIsMine = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCurrentUserComponent.prototype, "currentServiceIsMineAsNetworkManager", {
        get: function () {
            return this.currentUserFacade.currentServiceIsMineAsNetworkManager;
        },
        set: function (value) {
            this.currentUserFacade.currentServiceIsMineAsNetworkManager = value;
        },
        enumerable: true,
        configurable: true
    });
    BaseCurrentUserComponent.prototype.currentUserLoaded = function () {
        return this.currentUserFacade.currentUserLoaded();
    };
    BaseCurrentUserComponent.prototype.isOperator = function () {
        return this.currentUserFacade.isOperator();
    };
    BaseCurrentUserComponent.prototype.isOperatorAdminOrFinance = function () {
        return this.isOperator() && this.isSuperAdminOrFinance();
    };
    BaseCurrentUserComponent.prototype.isOperatorRegularWithServiceAssigned = function () {
        return (this.isOperator() && this.isUserRegular() && this.currentServiceIsMine);
    };
    BaseCurrentUserComponent.prototype.isProvider = function () {
        return this.currentUserFacade.isProvider();
    };
    BaseCurrentUserComponent.prototype.isClient = function () {
        return this.currentUserFacade.isClient();
    };
    BaseCurrentUserComponent.prototype.isOperatorOrClient = function () {
        return this.isOperator() || this.isClient();
    };
    BaseCurrentUserComponent.prototype.isOperatorOrProvider = function () {
        return this.isOperator() || this.isProvider();
    };
    BaseCurrentUserComponent.prototype.isProviderOrClient = function () {
        return this.isProvider() || this.isClient();
    };
    BaseCurrentUserComponent.prototype.isSuperAdminOrFinance = function () {
        return this.isSuperAdmin() || this.isUserFinance();
    };
    BaseCurrentUserComponent.prototype.isClientAdmin = function () {
        return this.isClient() && (this.isUserAdmin() || this.isSuperAdmin());
    };
    BaseCurrentUserComponent.prototype.isClientAdminAndIsAuthorizer = function () {
        return this.isClientAdmin() && this.currentUser.attributes.authorizer;
    };
    BaseCurrentUserComponent.prototype.isOperatorAdmin = function () {
        return this.isOperator() && (this.isUserAdmin() || this.isSuperAdmin());
    };
    BaseCurrentUserComponent.prototype.isOperatorFinance = function () {
        return this.isOperator() && this.isUserFinance();
    };
    BaseCurrentUserComponent.prototype.isOperatorRegular = function () {
        return this.isOperator() && this.isUserRegular();
    };
    BaseCurrentUserComponent.prototype.isClientRegular = function () {
        return this.isClient() && this.isUserRegular();
    };
    BaseCurrentUserComponent.prototype.isUserRegular = function () {
        return this.currentUserFacade.hasLevelRegular();
    };
    BaseCurrentUserComponent.prototype.isUserAdmin = function () {
        return this.currentUserFacade.hasLevelAdmin();
    };
    BaseCurrentUserComponent.prototype.isUserFinance = function () {
        return this.currentUserFacade.hasLevelFinance();
    };
    BaseCurrentUserComponent.prototype.isSuperAdmin = function () {
        return this.currentUserFacade.hasLevelSuperAdmin();
    };
    BaseCurrentUserComponent.prototype.isManager = function () {
        return this.currentUserFacade.isDoerManager();
    };
    BaseCurrentUserComponent.prototype.isUserHomeService = function () {
        return this.currentUser.attributes.home_services;
    };
    BaseCurrentUserComponent.prototype.relationships = function () {
        return this.currentUser.relationships;
    };
    BaseCurrentUserComponent.prototype.canEditService = function () {
        return this.permitsService.canEditService();
    };
    BaseCurrentUserComponent.prototype.canClientEditComplaint = function () {
        return this.permitsService.canClientEditComplaint();
    };
    return BaseCurrentUserComponent;
}());
export { BaseCurrentUserComponent };
