import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ICell } from './../../../interfaces/cell';
import { ICellConfiguration } from './../../../interfaces/cell-configuration';

import { ConfigurationService } from './../../../services/configuration.service';

@Component({
  selector: 'fmx-cell-date',
  templateUrl: './cell-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellDateComponent {
  styles: string;
  _cell: any = {};

  @Input()
  set cell(item: ICell) {
    this._cell.value = item.value;
    this._cell.configuration = {
      ...this.defaultConf,
      ...(item.configuration || {})
    };
    this.setStyles();
  }

  defaultConf: ICellConfiguration = {
    bold: false,
    align: 'left',
    color: 'secondary',
    width: 'large'
  };

  constructor(private configStyles: ConfigurationService) {}

  setStyles() {
    this.styles = this.configStyles.getStylesString(this._cell.configuration);
  }

  isFullDate(): boolean {
    return !this._cell.configuration.fullDate ? false : true;
  }
}
