import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RechargesRepaysService {
  constructor(private http: HttpClient) {}

  getRechargesProviders(filter: any, pagination: any) {
    const endpoint = `recharges`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getRepaysClients(filter: any, pagination: any) {
    const endpoint = `repays`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  updateRechargeProvider(
    serviceId: number,
    rechargeId: number,
    params: any = {}
  ) {
    const endpoint = `services/${serviceId}/recharges/${rechargeId}`;

    return this.http.put(endpoint, params);
  }

  updateRepayClient(serviceId: number, repayId: number, params: any = {}) {
    const endpoint = `services/${serviceId}/repays/${repayId}`;

    return this.http.put(endpoint, params);
  }
}
