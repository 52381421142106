var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
// tslint:disable:only-arrow-functions
// tslint:disable:underscore-consistent-invocation
// tslint:disable:no-function-expression
import { HttpClient, HttpParams } from '@angular/common/http';
import { concatMap, expand, map, reduce, takeWhile } from 'rxjs/operators';
// Utils & Const
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
import { generateResponseDataPage, toSelectOptions } from '@app/fmx-shared/utils/data-utils';
import * as _ from 'lodash';
var ProvidersService = /** @class */ (function () {
    function ProvidersService(http) {
        this.http = http;
    }
    ProvidersService.prototype.getProviders = function (status, currentPage) {
        if (status === void 0) { status = null; }
        if (currentPage === void 0) { currentPage = 1; }
        var params = new HttpParams()
            .set('sort_by', 'name')
            .set('minimum_api', 'true')
            .set('page', currentPage.toString());
        if (status) {
            status.forEach(function (key) {
                params = params.append('status[]', key);
            });
        }
        var endpoint = 'providers';
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getProvidersByPage = function (page, search, status) {
        if (search === void 0) { search = ''; }
        if (status === void 0) { status = null; }
        var params = new HttpParams()
            .set('sort_by', 'name')
            .set('sort_dir', 'asc')
            .set('services', 'true')
            .set('minimum_api', 'true')
            .set('per_page', ELEMENTS_PER_PAGE)
            .set('page', page.toString());
        if (status) {
            status.forEach(function (key) {
                params = params.append('status[]', key);
            });
        }
        if (search) {
            params = params.append('name', search);
        }
        var endpoint = 'providers';
        return this.http
            .get(endpoint, { params: params })
            .pipe(map(generateResponseDataPage));
    };
    ProvidersService.prototype.createProvider = function (params) {
        var endpoint = "providers";
        return this.http.post(endpoint, params);
    };
    ProvidersService.prototype.getProvider = function (providerId) {
        var endpoint = "providers/" + providerId;
        return this.http.get(endpoint);
    };
    ProvidersService.prototype.updateProvider = function (providerId, params) {
        var endpoint = "providers/" + providerId;
        return this.http.patch(endpoint, params);
    };
    ProvidersService.prototype.leaveProvider = function (providerId, params) {
        var endpoint = "providers/" + providerId;
        return this.http.put(endpoint, params);
    };
    ProvidersService.prototype.downloadProvidersList = function (filter) {
        var endpoint = "providers.xlsx";
        var params = __assign({}, filter);
        return this.http.get(endpoint, { params: params, responseType: 'blob' });
    };
    ProvidersService.prototype.getProviderFiscalData = function (providerId) {
        var endpoint = "providers/" + providerId + "/fiscal_info?include=state,country";
        return this.http.get(endpoint);
    };
    ProvidersService.prototype.updateProviderFiscalData = function (providerId, params) {
        var endpoint = "providers/" + providerId + "/fiscal_info";
        return this.http.patch(endpoint, params);
    };
    ProvidersService.prototype.getCoverages = function (filter, sort, pagination) {
        var endpoint = 'provider_coverages';
        var params = __assign({}, filter, sort, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getProviderAllCoverages = function (providerId) {
        var _this = this;
        return this.getAllCoverages(providerId).pipe(expand(function (response) {
            return _this.getAllCoverages(providerId, +response.meta.current_page + 1);
        }), takeWhile(function (response) { return response.meta.current_page <= response.meta.last_page; }), concatMap(function (response) { return response.data; }), reduce(function (acc, val) {
            acc.push(val);
            return acc;
        }, []));
    };
    ProvidersService.prototype.getAllCoverages = function (providerId, currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        var params = new HttpParams().set('page', currentPage.toString());
        var endpoint = "providers/" + providerId + "/provider_coverages";
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.addCoverage = function (providerId, data) {
        var endpoint = "providers/" + providerId + "/provider_coverages";
        return this.http.post(endpoint, data);
    };
    ProvidersService.prototype.removeCoverage = function (providerId, coverageId) {
        var endpoint = "providers/" + providerId + "/provider_coverages/" + coverageId;
        return this.http.delete(endpoint);
    };
    ProvidersService.prototype.updateCoverage = function (providerId, coverageId, params) {
        var endpoint = "providers/" + providerId + "/provider_coverages/" + coverageId;
        return this.http.put(endpoint, params);
    };
    ProvidersService.prototype.getProviderDoers = function (providerId) {
        var endpoint = "providers/" + providerId + "/doers";
        return this.http.get(endpoint);
    };
    ProvidersService.prototype.addDoerToProvider = function (providerId, params) {
        var endpoint = "providers/" + providerId + "/doers";
        return this.http.post(endpoint, params);
    };
    ProvidersService.prototype.updateDoer = function (providerId, doerId, params) {
        var endpoint = "providers/" + providerId + "/doers/" + doerId;
        return this.http.put(endpoint, params);
    };
    ProvidersService.prototype.deleteDoer = function (providerId, doerId) {
        var endpoint = "providers/" + providerId + "/doers/" + doerId;
        return this.http.delete(endpoint);
    };
    ProvidersService.prototype.getProviderReceipts = function (providerId, params) {
        var endpoint = "providers/" + providerId + "/receipts";
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getProviderIssues = function (providerId) {
        var endpoint = "providers/" + providerId + "/issues";
        return this.http.get(endpoint);
    };
    ProvidersService.prototype.getProviderDocuments = function (providerId) {
        var endpoint = "providers/" + providerId + "/documents";
        return this.http.get(endpoint);
    };
    ProvidersService.prototype.createProviderDocuments = function (providerId, data) {
        if (data === void 0) { data = {}; }
        var endpoint = "providers/" + providerId + "/documents";
        return this.http.post(endpoint, data);
    };
    ProvidersService.prototype.deleteProviderDocument = function (providerId, documentId) {
        var endpoint = "providers/" + providerId + "/documents/" + documentId;
        return this.http.delete(endpoint);
    };
    ProvidersService.prototype.downloadProviderDocument = function (providerId, documentId) {
        var endpoint = "providers/" + providerId + "/documents/" + documentId;
        return this.http.get(endpoint, { responseType: 'blob' });
    };
    ProvidersService.prototype.getProviderServices = function (providerId, params) {
        var endpoint = "providers/" + providerId + "/services";
        return this.http.get(endpoint, { params: params }).pipe(map(function (value) {
            value.data = _.sortBy(value.data, [
                function (item) {
                    return !item.attributes.unseen_changes;
                }
            ]);
            return value;
        }));
    };
    ProvidersService.prototype.getProviderInvoicesByProvider = function (providerId, filter, pagination) {
        var endpoint = "providers/" + providerId + "/provider_invoices";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getCompanyAggrupationsByProvider = function (providerId) {
        var endPoint = "providers/" + providerId + "/company_aggrupations";
        return this.http.get(endPoint).pipe(map(toSelectOptions));
    };
    ProvidersService.prototype.getProviderAcquittancesByProvider = function (providerId, filter, pagination) {
        var endpoint = "providers/" + providerId + "/additional_works_acquittances";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getProviderInvoices = function (filter, pagination) {
        var endpoint = "provider_invoices";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getProviderAcquittances = function (filter, pagination) {
        var endpoint = "additional_works_acquittances";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.getComplaintsProvider = function (filter, pagination, idProvider) {
        var endpoint = "providers/" + idProvider + "/complaints";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    ProvidersService.prototype.markAsCompleteComplaint = function (idProvider, idComplaint, data) {
        var endpoint = "providers/" + idProvider + "/complaints/" + idComplaint + "/complaint_resolution";
        return this.http.post(endpoint, data);
    };
    return ProvidersService;
}());
export { ProvidersService };
