import { SelectOption } from '@app/core/models/select-option.model';
import { ServiceLog } from '@app/core/models/service-log.interface';
import { DataPage } from '../interfaces/data-page.interface';

export function toSelectOptions(response: any): SelectOption[] {
  const data = response.data;

  return responseToSelectOptions(data);
}

export function responseToSelectOptions(
  data: any[],
  nameKey: string = 'name',
  valueKey: string = 'id'
): SelectOption[] {
  return data.map((item: any) => ({
    name: getSelectOptionValue(nameKey, item) || '',
    value: getSelectOptionValue(valueKey, item),
    element: item
  }));
}

export function generateResponseDataPage(response: any): DataPage {
  return {
    meta: response.meta,
    data: response.data.map((item: any) => {
      return {
        name: getSelectOptionValue('name', item),
        value: getSelectOptionValue('id', item),
        element: item
      };
    })
  };
}

export function getNameSpace(item: any): string {
  return item.internal_code
    ? `[${item.internal_code}] ${item.name}`
    : `${item.name}`;
}

export function responseToServiceLog(response: any): ServiceLog[] {
  return response.data.map((item: any) => ({
    service_id: item.relationships.service.data.id,
    action: item.attributes.action,
    name_liable: `${item.attributes.first_name} ${item.attributes.last_name}`,
    created_at: item.attributes.created_at,
    role: item.attributes.role
  }));
}

export function filterResponseByActive(data: any[]): SelectOption[] {
  return data.filter((item: any) => item.attributes.active);
}

function getSelectOptionValue(valueKey: string, item: any): string {
  if (valueKey !== 'id') {
    const value =
      (item && item.attributes && item.attributes[valueKey]) || item[valueKey];

    if (!value) {
      return getItemFullNameOperator(item);
    }

    return `${value}`;
  }

  return `${item[valueKey]}`;
}

function getItemFullNameOperator(item: any): string {
  return `${getSelectOptionValue('first_name', item)} ${getSelectOptionValue(
    'last_name',
    item
  )}`;
}
