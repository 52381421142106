var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService() {
        this.align = {
            left: 'left-align',
            right: 'right-align',
            center: 'center-align'
        };
        this.width = {
            small: 'small-width',
            medium: 'medium-width',
            large: 'large-width'
        };
        this.color = {
            success: 'success-color',
            danger: 'danger-color',
            warning: 'warning-color',
            primary: 'primary-color',
            secondary: 'secondary-color'
        };
        this.text = {
            nowrap: 'no-wrap'
        };
        this.LINKABLE_KLASS = 'linkable';
        this.WITH_DOT = 'with-dot';
        this.COLUMN_DEFAULT_CONF = {
            align: 'left',
            color: 'secondary',
            sortable: false,
            sortableKey: null,
            direction: null
        };
    }
    // Columns Methods
    ConfigurationService.prototype.parseColumn = function (column) {
        var conf = __assign({}, this.COLUMN_DEFAULT_CONF, (column.configuration || {}));
        return {
            name: column.name,
            configuration: conf
        };
    };
    ConfigurationService.prototype.setColumnDirection = function (column) {
        var direction = column.configuration.direction;
        if (direction && direction === 'asc') {
            return 'desc';
        }
        return 'asc';
    };
    // Other Methods
    ConfigurationService.prototype.getStylesString = function (configuration, isLinkModal) {
        var styles = [];
        if (configuration.bold) {
            styles.push('bold');
        }
        styles.push(this.align[configuration.align]);
        styles.push(this.width[configuration.width]);
        styles.push(this.color[configuration.color]);
        styles.push(this.text[configuration.text]);
        if (configuration.linkable || isLinkModal) {
            styles.push(this.LINKABLE_KLASS);
        }
        if (configuration.withDot) {
            styles.push(this.WITH_DOT);
        }
        return styles.join(' ');
    };
    ConfigurationService.prototype.getColumnStylesString = function (configuration) {
        var styles = [];
        if (configuration.sortable) {
            styles.push('sortable');
        }
        styles.push(this.align[configuration.align]);
        styles.push(this.color[configuration.color]);
        return styles.join(' ');
    };
    return ConfigurationService;
}());
export { ConfigurationService };
