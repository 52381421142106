import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, format: string = 'DD/MM/YYYY HH:mm'): string {
    const momentDate = (value && !isException(value) && moment(value)) || null;
    if (momentDate && momentDate.isValid()) {
      return momentDate.format(format);
    }

    return value;
  }
}

function isException(value: string) {
  const exceptions = ['Finalizada', 'No programada'];

  return exceptions.includes(value);
}
