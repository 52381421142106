import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';

export class StoreModel<T> {
  private _data: BehaviorSubject<T>;

  data$: Observable<T>;

  constructor(initialData: any) {
    this._data = new BehaviorSubject(initialData);
    this.data$ = this._data.asObservable().pipe(distinctUntilChanged());
  }

  get value(): any {
    return this._data.value;
  }

  // tslint:disable:no-shadowed-variable
  getValue<T>(name: any): T {
    return this.value[name];
  }

  select<T>(name: string): Observable<T> {
    return this.data$.pipe(pluck(name));
  }

  // tslint:disable-next-line:no-reserved-keywords
  set(partialState: any): void {
    const newState = {
      ...this.value,
      ...partialState
    };

    this._data.next(newState);
  }
}
