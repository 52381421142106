var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { filter } from 'rxjs/operators';
// Service
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
// Extends Class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
// Components
import { AddEditComplaintModalComponent } from '../add-edit-complaint-modal/add-edit-complaint-modal.component';
// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';
var ServiceComplaintsListComponent = /** @class */ (function (_super) {
    __extends(ServiceComplaintsListComponent, _super);
    function ServiceComplaintsListComponent(dialog, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.dialog = dialog;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.service = null;
        _this.refreshList = new EventEmitter();
        return _this;
    }
    ServiceComplaintsListComponent.prototype.refreshComplaintList = function () {
        this.refreshList.emit();
    };
    ServiceComplaintsListComponent.prototype.addComplaint = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AddEditComplaintModalComponent, {
            width: getResolutionModal(),
            data: {
                service: this.service,
                title: 'Añadir reclamación',
                submit: 'Crear nueva reclamación'
            }
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (refresh) { return refresh; }))
            .subscribe(function () { return _this.refreshComplaintList(); });
    };
    return ServiceComplaintsListComponent;
}(BaseCurrentUserComponent));
export { ServiceComplaintsListComponent };
