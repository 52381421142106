import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
// Models
import { CurrentUser } from '@app/core/models/current-user.model';
import { Session } from '@app/core/models/session.model';
// Services
import { CurrentUserService } from '@app/core/services/current-user/current-user.service';
import { LocalStorageService } from '@app/core/services/local-storage/local-storage.service';
var AuthService = /** @class */ (function () {
    function AuthService(http, currentUserService, localStorageService) {
        this.http = http;
        this.currentUserService = currentUserService;
        this.localStorageService = localStorageService;
        this.cachedRequests = [];
        this.isLogged = false;
        this.loggedIn = new BehaviorSubject(false);
    }
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            return this.loggedIn.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.getAuthToken = function () {
        var token = this.localStorageService.getItem('Authorization');
        return token ? "Bearer token=" + token : '';
    };
    AuthService.prototype.authenticateUser = function (data) {
        var _this = this;
        var endpoint = 'sessions';
        return this.http.post(endpoint, data).pipe(map(function (response) { return new Session(response); }), tap(function (session) { return _this.saveSessionOnLocalStorage(session); }), mergeMap(function () { return _this.getProfile(); }));
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        var sessionId = this.localStorageService.getItem('SessionId');
        var endpoint = "sessions/" + sessionId;
        return this.http.delete(endpoint).pipe(tap(function () { return _this.removeDataLogout(); }));
    };
    AuthService.prototype.removeDataLogout = function () {
        this.localStorageService.clear();
        this.loggedIn.next(false);
    };
    AuthService.prototype.getProfile = function () {
        var _this = this;
        return this.http.get('profile').pipe(tap(function (response) {
            _this.currentUserService.currentUser = new CurrentUser(response.data);
            _this.loggedIn.next(true);
        }), map(function () { return _this.currentUserService.currentUser; }));
    };
    AuthService.prototype.saveSessionOnLocalStorage = function (session) {
        this.localStorageService.setItem('Authorization', session.token);
        this.localStorageService.setItem('SessionId', session.id);
    };
    return AuthService;
}());
export { AuthService };
