import { Component, OnInit } from '@angular/core';
import { DeviceService } from '@app/core/services/device/device.service';
import { environment } from '@env/environment';
import { getYear } from 'date-fns';

@Component({
  selector: 'fmx-footer',
  templateUrl: 'fmx-footer.component.html'
})
export class FmxFooterComponent implements OnInit {
  year: number;
  version: string;
  environment: string;

  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    this.year = getYear(new Date());
    this.environment = environment.production ? 'prod' : 'dev';
    this.version = this.deviceService.appVersion;
  }
}
