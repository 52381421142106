import { Pipe, PipeTransform } from '@angular/core';

const STREET_TYPE = {
  street: 'Calle',
  avenue: 'Avenida',
  access: 'Acceso',
  sidewalk: 'Acera',
  mall: 'Alameda',
  freeway: 'Autopista',
  highway: 'Autovía',
  commercial_center: 'C. Comercial',
  alley: 'Callejón',
  track: 'Camino',
  glen: 'Cañada',
  carrera: 'Carrer',
  road: 'Carretera',
  slope: 'Cuesta',
  roundabout: 'Glorieta',
  passageway: 'Pasadizo',
  passage: 'Pasaje',
  promenade: 'Paseo',
  square: 'Plaza',
  rambla: 'Rambla',
  round: 'Ronda',
  path: 'Sendero',
  crossing: 'Travesía',
  urbanization: 'Urbanización',
  via: 'Vía',
  neighborhood: 'Barriada'
};

@Pipe({
  name: 'formatHumanAddress'
})
export class FormatHumanAddressPipe implements PipeTransform {
  transform(street: any): string {
    return street
      ? `${STREET_TYPE[street.street_type]} ${this.getValue(
          street.street
        )} nº ${this.getValue(street.number)} ${this.getValue(
          street.floor,
          ''
        )} ${this.getValue(street.door, ' -')} ${this.getValue(
          street.city,
          ' '
        )} (${this.getValue(street.zip_code, '')}) ${this.getValue(
          street.state,
          ','
        )} ${this.getValue(street.country, '')}`
      : '';
  }

  private getValue(value: string, separator: string = ','): string {
    return value ? `${value}${separator}` : '';
  }
}
