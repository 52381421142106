import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { SelectOption } from '@app/core/models/select-option.model';
import {
  responseToSelectOptions,
  toSelectOptions
} from '@app/fmx-shared/utils/data-utils';
import { Observable } from 'rxjs';

@Injectable()
export class RateListService {
  constructor(private http: HttpClient) {}

  getListsItems(
    serviceId: string,
    specialtyId: string
  ): Observable<SelectOption[]> {
    const endpoint = `services/${serviceId}/ratelist/lists_items`;

    const params = new HttpParams()
      .set('specialty', specialtyId)
      .set('exclude_batch', 'materials');

    return this.http.get(endpoint, { params }).pipe(map(toSelectOptions));
  }

  getListMaterials(
    serviceId: string,
    specialtyId: string
  ): Observable<SelectOption[]> {
    const endpoint = `services/${serviceId}/ratelist/lists_items`;

    const params = new HttpParams()
      .set('specialty', specialtyId)
      .set('batch', 'materials');

    return this.http.get(endpoint, { params }).pipe(map(toSelectOptions));
  }

  getBasePricelists(): Observable<SelectOption[]> {
    const endpoint = `base_pricelists`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response.data, 'description', 'id')
        )
      );
  }

  getBaseRatelists(): Observable<SelectOption[]> {
    const endpoint = `base_ratelists`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response.data, 'description', 'id')
        )
      );
  }

  getRatelist(rateListsId: string): Observable<any> {
    const endpoint = `ratelists/${rateListsId}`;

    return this.http.get(endpoint);
  }

  downloadRateslist(ratelistId: string): Observable<Blob> {
    const endpoint = `ratelists/${ratelistId}.pdf`;

    return this.http.get(endpoint, { responseType: 'blob' });
  }

  cloneBasePricelist(idUser: string, pricelistId: string): Observable<any> {
    const endpoint = `base_pricelists/${pricelistId}/clone`;

    return this.http.post(endpoint, { base_pricelist: { to: idUser } });
  }

  cloneBaseRatelist(idUser: string, ratelistId: string): Observable<any> {
    const endpoint = `base_ratelists/${ratelistId}/clone`;

    return this.http.post(endpoint, { base_ratelist: { to: idUser } });
  }
}
