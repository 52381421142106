// tslint:disable: no-void-expression
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { RechargesRepaysFacadeService } from '@app/fmx-shared/services/recharges-repays/recharges-repays-facade.service';

// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';

@Component({
  selector: 'app-fmx-edit-recharges-repays-modal',
  templateUrl: './fmx-edit-recharges-repays-modal.component.html'
})
export class FmxEditRechargesRepaysModalComponent
  extends BaseCurrentUserComponent
  implements OnInit {
  object: any;
  title: string;
  isRecharge: boolean;

  form: any;

  constructor(
    private dialogRef: MatDialogRef<FmxEditRechargesRepaysModalComponent>,
    private fb: FormBuilder,
    private facade: RechargesRepaysFacadeService,
    private toastr: FmxToastr,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(currentUserFacade, permitsService);
  }

  ngOnInit() {
    this.object = (this.data && this.data.object) || null;
    this.title = (this.data && this.data.title) || null;
    this.isRecharge = (this.data && this.data.isRecharge) || null;

    this.createForm();
  }

  success() {
    this.emit(true);
  }

  cancel() {
    this.emit(false);
  }

  emit(result: boolean) {
    this.dialogRef.close(result);
  }

  get attributes(): any {
    return this.object.attributes;
  }

  get copyButton(): string {
    return `Actualizar ${this.isRecharge ? 'recargo' : 'repago'}`;
  }

  updateRechargeOrRepay() {
    this.isRecharge ? this.updateRechargeProvider() : this.updateRepayClients();
  }

  private createForm() {
    this.form = this.fb.group({
      service_id: [{ value: this.attributes.service_id, disabled: true }],
      amount: [Number(this.attributes.amount).toFixed(2)],
      description: [this.attributes.description]
    });
  }

  private updateRechargeProvider() {
    const params = { recharge: { ...this.form.value } };

    this.facade
      .updateRechargeProvider(
        this.attributes.service_id,
        this.object.id,
        params
      )
      .subscribe(
        () => {
          this.toastSuccess('Recargo');
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
  }

  private updateRepayClients() {
    const params = { repay: { ...this.form.value } };

    this.facade
      .updateRepayClient(this.attributes.service_id, this.object.id, params)
      .subscribe(
        () => {
          this.toastSuccess('Repago');
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
  }

  private toastSuccess(msg: string) {
    this.toastr.success(`${msg} editado`);
    this.success();
  }
}
