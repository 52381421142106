export interface ConfigurationState {
  itemTypeList: any;
  rateAndPriceListItems: any[];
  selectedItemId: number | string;
  selectedRateAndPriceListItem: any;
  listItems: any[];
  specialties: any[];
  specialtiesEntities: { [id: string]: any };
  batches: any[];
  batchesEntities: { [id: string]: any };
  filterLoading: boolean;
  tableLoading: boolean;
  tableShow: boolean;
  filtersState: any;
  cloningPricelistLoading: boolean;
  updateFiltersState: any;
}

export const ConfigurationInitialState: ConfigurationState = {
  itemTypeList: [
    {
      value: 4,
      name: 'Baremos'
    },
    {
      value: 2,
      name: 'Baremos padre'
    },
    {
      value: 3,
      name: 'Tarifarios'
    },
    {
      value: 1,
      name: 'Tarifarios padre'
    }
  ],
  rateAndPriceListItems: [],
  selectedItemId: null,
  selectedRateAndPriceListItem: null,
  listItems: [],
  specialties: [],
  specialtiesEntities: {},
  batches: [],
  batchesEntities: {},
  filterLoading: false,
  tableLoading: false,
  tableShow: false,
  filtersState: null,
  cloningPricelistLoading: false,
  updateFiltersState: null
};
