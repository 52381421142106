import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fmx-editable-cell-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="fmx-form">
      <div class="fmx-form-group fmx-form-group-no-padding fmx-form-sm">
        <mat-select [formControl]="control">
          <mat-option
            *ngFor="let option of cell.editable.options"
            [value]="option.value"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  `
})
export class EditableCellSelectComponent {
  @Input() cell: any;
  @Input() control: FormControl;
}
