import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-breach-item',
  templateUrl: './service-breach-item.component.html',
  styleUrls: ['./service-breach-item.component.scss']
})
export class ServiceBreachItemComponent {
  @Input() breach: any;

  stylesIcon: string;

  icon = {
    svgIcon: 'assets/svg/map.svg#semaphore_square'
  };

  get attributes() {
    return this.breach && this.breach.attributes;
  }
}
