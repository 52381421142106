import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: string): string {
    if (!value) {
      return '';
    }
    const intLimit = parseInt(limit, 10);

    return value.length > intLimit
      ? value.substring(0, intLimit) + '...'
      : value;
  }
}
