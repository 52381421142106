export interface GlobalState {
  specialties: any[];
  specialtiesEntities: { [id: string]: any };
  serviceStatuses: any[];
}

export const GlobalInitialState: GlobalState = {
  specialties: [],
  specialtiesEntities: {},
  serviceStatuses: []
};
