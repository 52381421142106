import { Component, forwardRef, OnInit, OnDestroy, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms';
import * as moment from 'moment';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const LOCALE_ES = {
  firstDayOfWeek: 1,
  dayNames: [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado'
  ],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ],
  monthNamesShort: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ],
  today: 'Hoy',
  clear: 'Borrar'
};

@Component({
  selector: 'fmx-datetimepicker',
  templateUrl: './fmx-datetimepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FmxDatetimepickerComponent),
      multi: true
    }
  ]
})
export class FmxDatetimepickerComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() timeOnly = false;
  @Input() inline = false;
  @Input() showTime = true;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;

  control = new FormControl();
  unsubscribe = new Subject();

  localeEs = LOCALE_ES;

  // tslint:disable:no-unused
  // tslint:disable:no-empty
  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnInit() {
    this.subscribeToControlChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  writeValue(value: any) {
    if (value) {
      const date = moment(value).toDate();
      this.control.patchValue(date, { emitEvent: false });
      this.onTouched();
    }
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  private subscribeToControlChanges() {
    this.control.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: any) => {
        this.onControlChange(value);
      });
  }

  private onControlChange(value: any) {
    if (value) {
      const date = moment(value).toISOString();
      this.onChange(date);
    } else {
      this.onChange(null);
    }
    this.onTouched();
  }
}
