import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'fmx-input-email-add-list',
  templateUrl: 'fmx-input-email-add-list.component.html'
})
export class FmxInputEmailAddListComponent implements OnChanges, OnInit {
  @Output() addEmailEmit = new EventEmitter<string[]>();
  @Input() label: string;
  @Input() emailsByDefault: string[];
  @Input() addEmailRecommended: string;
  @Input() required = true;

  form: FormGroup;
  listEmails: string[] = [];
  showIconEnter = false;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.addEmailRecommended &&
      changes.addEmailRecommended.currentValue
    ) {
      this.pushOnListEmail(changes.addEmailRecommended.currentValue);
      this.emitChanges();
    }
  }

  ngOnInit(): void {
    this.initForm();

    if (this.emailsByDefault) {
      this.listEmails.push(...this.emailsByDefault);
      this.emitChanges();
    }
  }

  addEmail(): void {
    if (!this.emailHasError()) {
      this.pushOnListEmail(this.form.controls.email.value);
      this.form.reset();
      this.emitChanges();
    }
  }

  deleteEmail(email: string): void {
    _.remove(this.listEmails, (item: any) => item === email);
    this.emitChanges();
  }

  checkValidateEmail(): void {
    this.showIconEnter = this.emailHasError() ? false : true;
  }

  private pushOnListEmail(email: string): void {
    if (!this.listEmails.includes(email)) {
      this.listEmails.push(email);
    }
  }

  private emitChanges(): void {
    this.addEmailEmit.emit(this.listEmails);
  }

  private initForm(): void {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  private emailHasError(): boolean {
    return (
      this.form.controls.email.hasError('required') ||
      this.form.controls.email.hasError('email')
    );
  }
}
