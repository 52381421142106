import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'fmx-validations-modal',
  templateUrl: './validations-modal.component.html'
})
export class ValidationsModalComponent implements OnInit {
  validations: any;
  constructor(
    private dialogRef: MatDialogRef<ValidationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit() {
    this.validations = this.data;
  }

  success() {
    this.emit(true);
  }

  emit(result: boolean) {
    this.dialogRef.close(result);
  }
}
