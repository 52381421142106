var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// tslint:disable: no-void-expression
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { ComplaintsFacadeService } from '@app/features/complaints/services/complaints-facade.service';
// Utils
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';
var MarkAsCompleteComplaintModalComponent = /** @class */ (function (_super) {
    __extends(MarkAsCompleteComplaintModalComponent, _super);
    function MarkAsCompleteComplaintModalComponent(dialogRef, formBuilder, facade, currentUserFacade, permitsService, fmxToastr, data) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.dialogRef = dialogRef;
        _this.formBuilder = formBuilder;
        _this.facade = facade;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.fmxToastr = fmxToastr;
        _this.data = data;
        _this.complaintProviderStatus = [];
        return _this;
    }
    MarkAsCompleteComplaintModalComponent.prototype.ngOnInit = function () {
        this.complaint = this.data && this.data.complaint;
        this.serviceId = this.data.serviceId;
        this.getComplaintProviderStatus();
        this.initForm();
    };
    MarkAsCompleteComplaintModalComponent.prototype.closeModal = function (refresh) {
        if (refresh === void 0) { refresh = false; }
        this.dialogRef.close(refresh);
    };
    MarkAsCompleteComplaintModalComponent.prototype.markAsCompleteComplaint = function () {
        var _this = this;
        var idProvider = this.relationships().provider.data.id;
        this.facade
            .markAsCompleteComplaint(idProvider, this.complaint.id, this.form.value)
            .subscribe(function () {
            _this.fmxToastr.success('Reclamación resuelta correctamente');
            _this.closeModal(true);
        }, function (error) {
            var errors = error.error.errors;
            checkErrors(errors, _this.form);
        });
    };
    MarkAsCompleteComplaintModalComponent.prototype.initForm = function () {
        this.form = this.formBuilder.group({
            provider_status: [''],
            provider_comment: [null]
        });
    };
    MarkAsCompleteComplaintModalComponent.prototype.getComplaintProviderStatus = function () {
        var _this = this;
        this.facade
            .getComplaintProviderStatus()
            .subscribe(function (response) { return (_this.complaintProviderStatus = response); });
    };
    return MarkAsCompleteComplaintModalComponent;
}(BaseCurrentUserComponent));
export { MarkAsCompleteComplaintModalComponent };
