import { Injectable } from '@angular/core';
import { dynamicScriptList } from '@app/core/const/script-loader.const';

@Injectable()
export class ScriptLoaderService {
  // tslint:disable-next-line: no-empty
  constructor() {}

  load(scriptId: string): void {
    const scriptElement = this.createNewScriptElement(scriptId);

    if (scriptElement) {
      this.appendScriptToDocument(
        scriptElement,
        dynamicScriptList[scriptId].parentTag.value
      );
    }
  }

  removeScript(scriptId: string): void {
    const scriptToInject = dynamicScriptList[scriptId];

    if (scriptToInject) {
      const element = document.getElementById(scriptToInject.src.idTag);
      element.remove();
    }
  }

  private createNewScriptElement(scriptId: string): HTMLScriptElement {
    const scriptToInject = dynamicScriptList[scriptId];

    if (scriptToInject) {
      const scriptElement = document.createElement('script');
      scriptElement.type = scriptToInject.src.scriptType;
      scriptElement.textContent = scriptToInject.src.value;
      scriptElement.id = scriptToInject.src.idTag;

      return scriptElement;
    }

    return null;
  }

  private appendScriptToDocument(
    scriptElement: HTMLScriptElement,
    parentTag: string
  ): void {
    let parentElement: Element | null = null;

    const elementsByTag = document.getElementsByTagName(parentTag);

    if (elementsByTag.length > 0) {
      parentElement = elementsByTag[0];
    }

    parentElement.appendChild(scriptElement);
  }
}
