/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [];
var RenderType_ConfirmDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "p", [["class", "font-16 color-secondary"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "fmx-btn fmx-btn-clear fmx-btn-secondary fmx-btn-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancelar "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "fmx-btn fmx-btn-red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.success() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirmar"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.content; _ck(_v, 5, 0, currVal_2); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-edit-price", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i0.ɵdid(1, 49152, null, 0, i2.ConfirmDialogComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i0.ɵccf("app-confirm-edit-price", i2.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
