// Service
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { ImportFileMassiveService } from './import-file-massive.service';
import * as i0 from "@angular/core";
import * as i1 from "./import-file-massive.service";
import * as i2 from "../../../core/services/fmx-toastr/fmx-toastr.service";
var ImportFileMassiveFacadeService = /** @class */ (function () {
    function ImportFileMassiveFacadeService(httpService, toastr) {
        this.httpService = httpService;
        this.toastr = toastr;
    }
    ImportFileMassiveFacadeService.prototype.sendFileMassive = function (typeUpload, params, groupId) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (groupId === void 0) { groupId = null; }
        this.getRouteSendFile(typeUpload, params, groupId).subscribe(function () {
            return _this.toastr.success('Importación en curso, será notificado por email al finalizar la importación');
        }, function () {
            return _this.toastr.error('Error en la importación, por favor pruebe en unos minutos.');
        });
    };
    ImportFileMassiveFacadeService.prototype.getRouteSendFile = function (typeUpload, params, groupId) {
        if (params === void 0) { params = {}; }
        if (groupId === void 0) { groupId = null; }
        if (typeUpload === 'services') {
            return this.httpService.sendFileServiceMassive(params);
        }
        return this.httpService.sendFileSpacesMassive(groupId, params);
    };
    ImportFileMassiveFacadeService.ngInjectableDef = i0.defineInjectable({ factory: function ImportFileMassiveFacadeService_Factory() { return new ImportFileMassiveFacadeService(i0.inject(i1.ImportFileMassiveService), i0.inject(i2.FmxToastr)); }, token: ImportFileMassiveFacadeService, providedIn: "root" });
    return ImportFileMassiveFacadeService;
}());
export { ImportFileMassiveFacadeService };
