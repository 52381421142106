import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatMenuModule,
  MatDatepickerModule,
  MatChipsModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatDialogModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import * as cModule from 'primeng/components/calendar/calendar';

import { FmxPaginationModule } from '@app/fmx-modules/fmx-pagination/fmx-pagination.module';
import { FmxSelectInfiniteModule } from '@app/fmx-modules/fmx-select-infinite/fmx-select-infinite.module';
import { FmxSelectModule } from '@app/fmx-modules/fmx-select/fmx-select.module';
import { FmxTableModule } from '@app/fmx-modules/fmx-table/fmx-table.module';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { NguCarouselModule } from '@ngu/carousel';

// Services
import { ComplaintsState } from '@app/features/complaints/state/complaints.state';
import { ConfigurationService } from '@app/fmx-modules/fmx-table/services/configuration.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ImagePopupService } from './services/image-popup/image-popup.service';
import { ImportFileMassiveFacadeService } from './services/import-files/import-file-massive-facade.service';
import { ImportFileMassiveService } from './services/import-files/import-file-massive.service';
import { RechargesRepaysFacadeService } from './services/recharges-repays/recharges-repays-facade.service';
import { RechargesRepaysService } from './services/recharges-repays/recharges-repays.service';
import { TablePageService } from './services/table-page/table-page.service';
import { RechargesRepaysState } from './states/recharges-repays.state';

// Directives
import { FmxSliderItemDirective } from './components/fmx-slider/fmx-slider-item.directive';
import { FormErrorsDirective } from './directives/form-errors/form-errors.directive';
import { MarkAsteriskDirective } from './directives/mark-asterisk/mark-asterisk.directive';

// Components
import { ClientHeaderComponent } from './components/client-header/client-header.component';
import { FmxAddImageNoteVisitComponent } from './components/fmx-add-image-note-visit/fmx-add-image-note-visit.component';
import { FmxCarrouselCardsComponent } from './components/fmx-carrousel-cards/fmx-carrousel-cards.component';
import { FmxDatetimepickerComponent } from './components/fmx-datetimepicker/fmx-datetimepicker.component';
import { FmxDownloadExcelMassiveComponent } from './components/fmx-download-excel-massive/components/fmx-download-excel-massive/fmx-download-excel-massive.component';
import { FmxFooterComponent } from './components/fmx-footer/fmx-footer.component';
import { FmxHeaderComponent } from './components/fmx-header/fmx-header.component';
import { FmxSelectModalComponent } from './components/fmx-select-modal/fmx-select-modal.component';
import { FmxUploadExcelMassiveModalComponent } from './components/fmx-upload-excel-massive/components/fmx-upload-excel-massive-modal/fmx-upload-excel-massive-modal.component';
import { FmxUploadExcelMassiveComponent } from './components/fmx-upload-excel-massive/components/fmx-upload-excel-massive/fmx-upload-excel-massive.component';
import { FmxUploadFilesComponent } from './components/fmx-upload-files/fmx-upload-files.component';
import { ValidationsModalComponent } from './components/validations-modal/validations-modal.component';

// tslint:disable:max-line-length
import { FmxInputEmailAddListComponent } from './components/fmx-input-email-add-list/fmx-input-email-add-list.component';
import { FmxListRechargesRepaysComponent } from './components/fmx-list-recharges-repays/fmx-list-recharges-repays/fmx-list-recharges-repays.component';
import { FmxRechargesRepaysFilterComponent } from './components/fmx-recharges-repays-filter/fmx-recharges-repays-filter/fmx-recharges-repays-filter.component';
import { FmxSliderComponent } from './components/fmx-slider/fmx-slider.component';
import { FmxSpinnerComponent } from './components/fmx-spinner/fmx-spinner/fmx-spinner.component';
import { ServiceBreachItemComponent } from './components/service-breach-item/service-breach-item.component';
import { ServiceComplaintItemComponent } from './components/service-complaint-item/service-complaint-item.component';
import { ServiceComplaintsListComponent } from './components/service-complaints-list/service-complaints-list.component';
import { ServiceRepayRechargeItemComponent } from './components/service-repay-recharge-item/service-repay-recharge-item.component';

// Dialogs
import { AddEditComplaintModalComponent } from './components/add-edit-complaint-modal/add-edit-complaint-modal.component';
import { FmxBannerEnvironmentComponent } from './components/fmx-banner-environment/fmx-banner-environment.component';
import { FmxDownloadExcelSuccessModalComponent } from './components/fmx-download-excel-massive/components/fmx-download-excel-success-modal/fmx-download-excel-success-modal.component';
import { FmxEditRechargesRepaysModalComponent } from './components/fmx-edit-recharges-repays-modal/fmx-edit-recharges-repays/fmx-edit-recharges-repays-modal.component';
import { FmxImageModalComponent } from './components/fmx-image-modal/fmx-image-modal.component';
import { MarkAsCompleteComplaintModalComponent } from './components/mark-as-complete-complaint-modal/mark-as-complete-complaint-modal.component';
import { NewRepayRechargeModalComponent } from './components/new-repay-recharge-modal/new-repay-recharge-modal.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

// Pipes
import { KeysPipe } from '@app/shared/pipes/keyspipe';
import { BillingStatusPipe } from './pipes/billing-status/billing-status.pipe';
import { BooleanPipe } from './pipes/boolean/boolean.pipe';
import { BreachKindPipe } from './pipes/breach-kind/breach-kind.pipe';
import { BreachSeverityColorPipe } from './pipes/breach-severity-color/breach-severity-color.pipe';
import { BreachSeverityPipe } from './pipes/breach-severity/breach-severity.pipe';
import { BudgetStatusPipe } from './pipes/budget-status/budget-status.pipe';
import { ComplaintStatusPipe } from './pipes/complaint-status/complaint-status.pipe';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { FmxCurrencyPipe } from './pipes/fmx-currency/fmx-currency.pipe';
import { FormatHumanAddressPipe } from './pipes/format-human-address/format-human-address.pipe';
import { ImageKindPipe } from './pipes/image-kind/image-kind.pipe';
import { InvoiceStatusPipe } from './pipes/invoice-status/invoice-status.pipe';
import { PaymentAdditionalWorkColorStatusPipe } from './pipes/payment-additional-work-color-status/payment-additional-work-color-status.pipe';
import { PaymentAdditionalWorkStatusPipe } from './pipes/payment-additional-work-status/payment-additional-work-status.pipe';
import { ReasonStatusPipe } from './pipes/reason-status/reason-status.pipe';
import { ServiceLogStatusPipe } from './pipes/service-log-status/service-log-status.pipe';
import { ServiceStatusPipe } from './pipes/service-status/service-status.pipe';
import { SeverityClassButtonPipe } from './pipes/severity-class-button/severity-class-button.pipe';
import { StatusNotificationComplaintsColorPipe } from './pipes/status-notification-complaints-color/status-notification-complaints-color.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { UserRolePipe } from './pipes/user-role/user-role.pipe';
import { VisitStatusPipe } from './pipes/visit-status/visit-status.pipe';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY'
  }
};

const PIPES = [
  ServiceStatusPipe,
  DateFormatPipe,
  BooleanPipe,
  TruncatePipe,
  FmxCurrencyPipe,
  VisitStatusPipe,
  BudgetStatusPipe,
  ImageKindPipe,
  ReasonStatusPipe,
  BreachSeverityPipe,
  BreachKindPipe,
  SeverityClassButtonPipe,
  BreachSeverityColorPipe,
  SeverityClassButtonPipe,
  InvoiceStatusPipe,
  BillingStatusPipe,
  FormatHumanAddressPipe,
  ComplaintStatusPipe,
  MarkAsteriskDirective,
  KeysPipe,
  PaymentAdditionalWorkStatusPipe,
  PaymentAdditionalWorkColorStatusPipe,
  StatusNotificationComplaintsColorPipe,
  ServiceLogStatusPipe,
  UserRolePipe
];

const COMPONENTS = [
  ClientHeaderComponent,
  FmxHeaderComponent,
  FmxFooterComponent,
  FmxDatetimepickerComponent,
  FmxAddImageNoteVisitComponent,
  FmxSliderItemDirective,
  FmxSliderComponent,
  ServiceBreachItemComponent,
  ServiceRepayRechargeItemComponent,
  FmxRechargesRepaysFilterComponent,
  FmxEditRechargesRepaysModalComponent,
  FmxListRechargesRepaysComponent,
  FmxSpinnerComponent,
  FmxCarrouselCardsComponent,
  ServiceComplaintsListComponent,
  ServiceComplaintItemComponent,
  AddEditComplaintModalComponent,
  MarkAsCompleteComplaintModalComponent,
  FmxUploadFilesComponent,
  FmxUploadExcelMassiveComponent,
  FmxUploadExcelMassiveModalComponent,
  FmxDownloadExcelMassiveComponent,
  FmxDownloadExcelSuccessModalComponent,
  FmxSelectModalComponent,
  ValidationsModalComponent,
  FmxInputEmailAddListComponent,
  FmxBannerEnvironmentComponent
];

const DIALOGS = [
  ConfirmDialogComponent,
  FmxImageModalComponent,
  NewRepayRechargeModalComponent,
  FmxEditRechargesRepaysModalComponent,
  AddEditComplaintModalComponent,
  MarkAsCompleteComplaintModalComponent,
  FmxUploadExcelMassiveModalComponent,
  FmxDownloadExcelSuccessModalComponent,
  FmxSelectModalComponent,
  ValidationsModalComponent
];

const SERVICES = [
  TablePageService,
  ImagePopupService,
  ConfigurationService,
  RechargesRepaysService,
  RechargesRepaysFacadeService,
  RechargesRepaysState,
  ComplaintsState,
  TranslateService,
  ImportFileMassiveFacadeService,
  ImportFileMassiveService
];

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatMenuModule,
  MatDatepickerModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  FmxTableModule,
  FmxPaginationModule,
  FmxSelectInfiniteModule,
  NgxMatSelectSearchModule,
  cModule.CalendarModule,
  ContentLoaderModule,
  TranslateModule,
  NguCarouselModule,
  FmxSelectModule
];

@NgModule({
  declarations: [FormErrorsDirective, ...COMPONENTS, ...PIPES, ...DIALOGS],
  imports: [...MODULES],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    FmxSelectInfiniteModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    FormErrorsDirective,
    cModule.CalendarModule,
    ContentLoaderModule,
    ...COMPONENTS,
    ...PIPES,
    ...DIALOGS
  ],
  providers: [
    ...SERVICES,
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'es-SP' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  entryComponents: [...DIALOGS]
})
export class FmxSharedModule {}
