import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fmx-download-excel-massive',
  templateUrl: './fmx-download-excel-massive.component.html'
})
export class FmxDownloadExcelMassiveComponent {
  @Output() generateExcelDownload = new EventEmitter<boolean>();

  // tslint:disable-next-line: no-empty
  constructor() {}

  generateExcel(): void {
    this.generateExcelDownload.emit();
  }
}
