import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ImportFileMassiveService = /** @class */ (function () {
    function ImportFileMassiveService(http) {
        this.http = http;
    }
    ImportFileMassiveService.prototype.sendFileServiceMassive = function (params) {
        if (params === void 0) { params = {}; }
        var endpoint = 'services/batches';
        return this.http.post(endpoint, params);
    };
    ImportFileMassiveService.prototype.sendFileSpacesMassive = function (groupId, params) {
        if (params === void 0) { params = {}; }
        var endpoint = "groups/" + groupId + "/spaces/batches";
        return this.http.post(endpoint, params);
    };
    ImportFileMassiveService.ngInjectableDef = i0.defineInjectable({ factory: function ImportFileMassiveService_Factory() { return new ImportFileMassiveService(i0.inject(i1.HttpClient)); }, token: ImportFileMassiveService, providedIn: "root" });
    return ImportFileMassiveService;
}());
export { ImportFileMassiveService };
