import { INITIAL_PAGE } from '../const/initial-pages.const';
import { CurrentUser } from '../models/current-user.model';

export function navigateToUserPages(currentUser: CurrentUser): string {
  if (currentUser.isOperator()) {
    return INITIAL_PAGE.operator;
  }

  if (currentUser.isProvider()) {
    return INITIAL_PAGE.doer;
  }

  return checkAccessTabList(currentUser);
}

export function checkAccessTabList(currentUser: CurrentUser): string {
  if (!currentUser.relationships.company_aggrupations.data[0]) {
    return INITIAL_PAGE.childDetail;
  }

  return INITIAL_PAGE.user;
}
