var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
var StoreModel = /** @class */ (function () {
    function StoreModel(initialData) {
        this._data = new BehaviorSubject(initialData);
        this.data$ = this._data.asObservable().pipe(distinctUntilChanged());
    }
    Object.defineProperty(StoreModel.prototype, "value", {
        get: function () {
            return this._data.value;
        },
        enumerable: true,
        configurable: true
    });
    // tslint:disable:no-shadowed-variable
    StoreModel.prototype.getValue = function (name) {
        return this.value[name];
    };
    StoreModel.prototype.select = function (name) {
        return this.data$.pipe(pluck(name));
    };
    // tslint:disable-next-line:no-reserved-keywords
    StoreModel.prototype.set = function (partialState) {
        var newState = __assign({}, this.value, partialState);
        this._data.next(newState);
    };
    return StoreModel;
}());
export { StoreModel };
