import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

// Models
import { CurrentUser } from '@app/core/models/current-user.model';
import { Session } from '@app/core/models/session.model';

// Services
import { CurrentUserService } from '@app/core/services/current-user/current-user.service';
import { LocalStorageService } from '@app/core/services/local-storage/local-storage.service';

@Injectable()
export class AuthService {
  cachedRequests: HttpRequest<any>[] = [];
  isLogged = false;

  loggedIn = new BehaviorSubject<boolean>(false);

  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService
  ) {}

  getAuthToken(): string {
    const token = this.localStorageService.getItem('Authorization');

    return token ? `Bearer token=${token}` : '';
  }

  authenticateUser(data: any): Observable<CurrentUser> {
    const endpoint = 'sessions';

    return this.http.post(endpoint, data).pipe(
      map((response: any) => new Session(response)),
      tap((session: Session) => this.saveSessionOnLocalStorage(session)),
      mergeMap(() => this.getProfile())
    );
  }

  logout(): Observable<any> {
    const sessionId = this.localStorageService.getItem('SessionId');
    const endpoint = `sessions/${sessionId}`;

    return this.http.delete(endpoint).pipe(tap(() => this.removeDataLogout()));
  }

  removeDataLogout(): void {
    this.localStorageService.clear();
    this.loggedIn.next(false);
  }

  private getProfile(): Observable<CurrentUser> {
    return this.http.get('profile').pipe(
      tap((response: any) => {
        this.currentUserService.currentUser = new CurrentUser(response.data);
        this.loggedIn.next(true);
      }),
      map(() => this.currentUserService.currentUser)
    );
  }

  private saveSessionOnLocalStorage(session: Session): void {
    this.localStorageService.setItem('Authorization', session.token);
    this.localStorageService.setItem('SessionId', session.id);
  }
}
