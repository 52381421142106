import { Pipe, PipeTransform } from '@angular/core';
import { USER_ROLE } from '@app/fmx-shared/constants/user-role.const';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {
  transform(value: string): string {
    return USER_ROLE[value] || 'Estado no conocido';
  }
}
