var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
import { ConfigurationService } from './../../services/configuration.service';
var TableComponent = /** @class */ (function () {
    function TableComponent(configurationService) {
        this.configurationService = configurationService;
        this.loading = false;
        this.rowAction = new EventEmitter();
        this.columnAction = new EventEmitter();
        this.defaultConf = {
            theme: 'default',
            editable: false
        };
    }
    Object.defineProperty(TableComponent.prototype, "headers", {
        set: function (value) {
            var _this = this;
            if (value) {
                this._headers = value.map(function (h) {
                    return _this.configurationService.parseColumn(h);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "configuration", {
        set: function (conf) {
            this._configuration = __assign({}, this.defaultConf, conf);
        },
        enumerable: true,
        configurable: true
    });
    TableComponent.prototype.trackByFn = function (item) {
        return item.id;
    };
    TableComponent.prototype.trackByFnTh = function (item) {
        return item.name;
    };
    TableComponent.prototype.onAction = function (rowActionPayload) {
        this.rowAction.emit(rowActionPayload);
    };
    TableComponent.prototype.sortByColumn = function (index) {
        var columns = JSON.parse(JSON.stringify(this._headers));
        var column = JSON.parse(JSON.stringify(this._headers[index]));
        if (column.configuration.sortable) {
            column.configuration.direction = this.configurationService.setColumnDirection(column);
            // Reset other columns order
            columns.forEach(function (c) {
                c.configuration.direction = null;
            });
            columns[index] = column;
            this.columnAction.emit({
                action: 'sort',
                sortBy: column.configuration.sortableKey,
                sortDirection: column.configuration.direction
            });
            this._headers = columns;
        }
    };
    return TableComponent;
}());
export { TableComponent };
