import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'fmx-ghost-loader',
  templateUrl: './ghost-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GhostLoaderComponent {
  @Input() show: boolean;
}
