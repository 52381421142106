// tslint:disable: no-void-expression
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
// tslint:disable-next-line:max-line-length
import { ServiceDetailFacadeService } from '@app/features/service-detail/shared-service-detail/services/service-detail-facade/service-detail-facade.service';

// Utils
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';

@Component({
  selector: 'fmx-new-repay-recharge-modal',
  templateUrl: './new-repay-recharge-modal.component.html'
})
export class NewRepayRechargeModalComponent implements OnInit {
  title: string;
  isRepay: boolean;
  objectEdit: any;

  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<NewRepayRechargeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private facade: ServiceDetailFacadeService,
    private fmxToastr: FmxToastr
  ) {}

  ngOnInit() {
    this.title = (this.data && this.data.title) || null;
    this.isRepay = this.data.isRepay;
    this.objectEdit = (this.data && this.data.objectEdit) || null;

    this.initForm();
  }

  close(isDirty: boolean = true) {
    this.dialogRef.close({ isDirty });
  }

  createOrUpdateObject() {
    this.objectEdit
      ? this.updateRepayOrRecharge()
      : this.createRepayOrRecharge();
  }

  createRepayOrRecharge() {
    if (this.isRepay) {
      const params = { repay: { ...this.form.value } };

      this.facade.createRepay(params).subscribe(
        () => {
          this.showSuccessToast('Repago realizado');
          this.close(false);
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
    } else {
      const params = { recharge: { ...this.form.value } };

      this.facade.createRecharges(params).subscribe(
        () => {
          this.showSuccessToast('Recobro realizado');
          this.close(false);
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
    }
  }

  updateRepayOrRecharge() {
    if (this.isRepay) {
      const params = { repay: { ...this.form.value } };

      this.facade.updateRepay(this.objectEdit.id, params).subscribe(
        () => {
          this.showSuccessToast('Repago actualizado');
          this.close(false);
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
    } else {
      const params = { recharge: { ...this.form.value } };

      this.facade.updateRecharge(this.objectEdit.id, params).subscribe(
        () => {
          this.showSuccessToast('Recobro actualizado');
          this.close(false);
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
    }
  }

  private initForm() {
    this.form = this.fb.group({
      amount: [null],
      description: [null]
    });

    if (this.objectEdit) {
      this.fillForm();
    }
  }

  private fillForm() {
    this.form.get('amount').setValue(this.objectEdit.attributes.amount);
    this.form
      .get('description')
      .setValue(this.objectEdit.attributes.description);
  }

  private showSuccessToast(msg: string) {
    this.fmxToastr.success(msg);
  }
}
