import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-edit-price',
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <p class="font-16 color-secondary" [innerHTML]="data.content"></p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="fmx-btn fmx-btn-clear fmx-btn-secondary fmx-btn-bold"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button class="fmx-btn fmx-btn-red" (click)="success()">Confirmar</button>
    </mat-dialog-actions>
  `
})
export class ConfirmDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  success() {
    this.emit(true);
  }
  cancel() {
    this.emit(false);
  }

  emit(result: boolean) {
    this.dialogRef.close(result);
  }
}
