// tslint:disable: no-void-expression
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { MatDialog } from '@angular/material';
// tslint:disable-next-line:max-line-length
import { IRowAction } from '@app/fmx-modules/fmx-table/interfaces/row-action';
import { FmxEditRechargesRepaysModalComponent } from '@app/fmx-shared/components/fmx-edit-recharges-repays-modal/fmx-edit-recharges-repays/fmx-edit-recharges-repays-modal.component';
import { RechargesRepaysFacadeService } from '@app/fmx-shared/services/recharges-repays/recharges-repays-facade.service';
import { RechargesRepaysState } from '@app/fmx-shared/states/recharges-repays.state';

import { getTableConfiguration } from '@app/core/utils/configuration-tables-shared.utils';
import {
  getTableHeaders,
  getTableRows
} from '@app/fmx-shared/utils/table-recharges-repays.utils';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

@Component({
  selector: 'fmx-list-recharges-repays',
  templateUrl: './fmx-list-recharges-repays.component.html'
})
export class FmxListRechargesRepaysComponent implements OnInit, OnDestroy {
  @Input() enableListProviders: boolean;
  @Input() titleFilter: string;
  @Input() messageContainer: boolean;

  rechargesRepaysList$: Observable<any[]>;
  loading$: Observable<boolean>;

  pagination: any;
  showMessage = true;

  tableConfiguration: any;
  tableHeaders: any;

  private unsubscribe = new Subject();

  constructor(
    private state: RechargesRepaysState,
    private facade: RechargesRepaysFacadeService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.rechargesRepaysList$ = this.state.rechargesRepaysList$.pipe(
      map(getTableRows)
    );

    this.loading$ = this.state.loading$;
  }

  ngOnDestroy() {
    this.facade.reset();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initData(event: any) {
    this.facade.changeFilter(event);
    this.loadData();
    this.selectPagination();

    this.tableHeaders = getTableHeaders();
    this.tableConfiguration = getTableConfiguration();

    this.showMessage = false;
  }

  openRechargeOrRepay(event: IRowAction) {
    if (event.data.openModal) {
      const dialogRef = this.dialog.open(FmxEditRechargesRepaysModalComponent, {
        width: getResolutionModal(),
        data: {
          object: event.data.object,
          title: this.enableListProviders
            ? 'Recobro a proveedores'
            : 'Repago a clientes',
          isRecharge: this.enableListProviders
        }
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.loadData();
        }
      });
    }
  }

  changePage(event: any) {
    this.facade.changePage(event);
    this.loadData();
  }

  private loadData() {
    this.enableListProviders
      ? this.facade.loadRechargesProviders()
      : this.facade.loadRepaysClients();
  }

  private selectPagination() {
    this.state.pagination$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((pagination: any) => {
        this.pagination = pagination;
      });
  }
}
