import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  loading: Subject<boolean> = new Subject();

  // tslint:disable-next-line: no-empty
  constructor() {}

  showLoading(): void {
    this.loading.next(true);
  }

  hideLoading(): void {
    this.loading.next(false);
  }
}
