import { Pipe, PipeTransform } from '@angular/core';

const BREACH_SEVERITY_TYPES = {
  trivial: 'Irrelevante',
  minor: 'Leve',
  medium: 'Normal',
  serious: 'Grave',
  very_serious: 'Muy grave'
};

@Pipe({
  name: 'breachSeverity'
})
export class BreachSeverityPipe implements PipeTransform {
  transform(value: string): string {
    return BREACH_SEVERITY_TYPES[value] || value;
  }
}
