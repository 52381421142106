<div class="dialog-header">
  <div class="dialog-header-title">{{ title }} a cliente</div>
  <div class="dialog-close-button">
    <button
      (click)="close()"
      class="fmx-btn fmx-btn-clear fmx-btn-icon fmx-btn-secondary"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<form [formGroup]="form" *ngIf="form" class="fmx-form">
  <div class="row m-b-15">
    <mat-form-field class="col-12">
      <div class="fmx-form-group fmx-form-icon">
        <label>Importe</label>
        <div class="fmx-form-input-container full-width">
          <input
            type="number"
            matInput
            formControlError
            formControlName="amount"
          />
          <span class="input-icon">&euro;</span>
        </div>
      </div>
    </mat-form-field>
  </div>

  <div class="row m-b-15">
    <div class="col-12 fmx-form-group">
      <label>Descripción</label>
      <textarea
        matInput
        formControlError
        formControlName="description"
        placeholder="DESCRIPCIÓN"
      ></textarea>
    </div>
  </div>

  <div class="dialog-footer">
    <button
      (click)="close()"
      class="fmx-btn fmx-btn-clear fmx-btn-secondary fmx-btn-block m-l-5 m-r-5"
    >
      CANCELAR
    </button>
    <button
      (click)="createOrUpdateObject()"
      class="fmx-btn fmx-btn-red fmx-btn-block m-l-5 m-r-5"
    >
      APLICAR {{ title | uppercase }}
    </button>
  </div>
</form>
