import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { INITIAL_PAGE } from '../const/initial-pages.const';
import { AuthService } from '../services/auth/auth.service';
import { CurrentUserService } from '../services/current-user/current-user.service';
import { navigateToUserPages } from '../utils/redirect-initial-page.utils';

@Injectable()
export class IsClientGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private currentUserService: CurrentUserService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map(() => {
        if (!this.currentUserService.currentUser) {
          this.router.navigate([INITIAL_PAGE.login]);

          return false;
        }

        if (!this.currentUserService.currentUser.isClient()) {
          this.router.navigate([
            navigateToUserPages(this.currentUserService.currentUser)
          ]);

          return false;
        }

        return true;
      })
    );
  }
}
