var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INITIAL_PAGE } from '../const/initial-pages.const';
import { AuthService } from '../services/auth/auth.service';
import { DeviceService } from '../services/device/device.service';
import { LoadingService } from '../services/loading/loading.service';
var HttpErrorInterceptor = /** @class */ (function () {
    function HttpErrorInterceptor(loadingService, authService, toastrService, router, deviceService) {
        this.loadingService = loadingService;
        this.authService = authService;
        this.toastrService = toastrService;
        this.router = router;
        this.deviceService = deviceService;
        this.isRefreshingToken = false;
    }
    HttpErrorInterceptor.prototype.addHeaders = function (req, token) {
        return req.clone({
            setHeaders: __assign({ Authorization: token, Accept: 'application/json' }, this.getHeadersDevice())
        });
    };
    HttpErrorInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next
            .handle(this.addHeaders(req, this.authService.getAuthToken()))
            .pipe(catchError(function (error) {
            _this.loadingService.hideLoading();
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 401:
                        _this.logoutUser();
                        return throwError(error);
                    case 403:
                        return _this.handle403Error(error);
                    default:
                        return throwError(error);
                }
            }
            return throwError(error);
        }));
    };
    HttpErrorInterceptor.prototype.handle403Error = function (err) {
        var error = err.error.errors[0];
        if (error && error.title.includes('UnconfirmedAccountError')) {
            this.toastrService.error('Tu cuenta no ha sido confirmada por nuestros agentes');
            this.logoutUser();
            return throwError(err);
        }
        this.toastrService.error('No tienes permiso para realizar esa acción');
        return throwError(err);
    };
    HttpErrorInterceptor.prototype.logoutUser = function () {
        this.authService.removeDataLogout();
        this.router.navigateByUrl(INITIAL_PAGE.login);
    };
    HttpErrorInterceptor.prototype.getHeadersDevice = function () {
        return {
            'X-Browser-Name': this.deviceService.browserName,
            'X-Browser-Version': this.deviceService.browserVersion,
            'X-Platform-Name': this.deviceService.os,
            'X-Platform-Version': this.deviceService.osVersion,
            'X-Device-Name': this.deviceService.deviceName,
            'X-App-Version': this.deviceService.appVersion
        };
    };
    return HttpErrorInterceptor;
}());
export { HttpErrorInterceptor };
