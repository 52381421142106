<div class="modal-body loader-modal">
  <svg
    width="78px"
    height="71px"
    viewBox="0 0 78 71"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      x1="50%"
      y1="24.8912197%"
      x2="85.917653%"
      y2="140.13446%"
      id="linearGradient"
    >
      <stop stop-color="#F56558" offset="0%"></stop>
      <stop stop-color="#D62C4B" offset="100%"></stop>
    </linearGradient>
    <g
      id="Isotipo_pulido"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <polyline
        class="logo-line-svg"
        id="Path-11"
        stroke="#E94F41"
        stroke-width="10"
        points="6.02136345 69.9915246 6.02136345 34.5493722 17.2800007 26.8832012 24.850578 32.1940124 24.850578 65.004297 44.835525 65.004297 44.835525 26.4283472 62.40589 14.5004645 71.8374037 21.1221919 71.8374037 69.9915246"
      ></polyline>
    </g>
  </svg>
</div>
