import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ComplaintsService {
  constructor(private http: HttpClient) {}

  createServiceFromComplaint(
    complaintId: number,
    params: any
  ): Observable<any> {
    const endpoint = `complaints/${complaintId}/services`;

    return this.http.post(endpoint, params);
  }

  createComplaint(serviceId: number, data: any): Observable<any> {
    const endpoint = `services/${serviceId}/complaints`;

    return this.http.post(endpoint, data);
  }

  getComplaint(complaintId: number): Observable<any> {
    const endpoint = `complaints/${complaintId}`;

    return this.http.get(endpoint);
  }

  deleteComplaint(complaintId: string): Observable<any> {
    const endpoint = `complaints/${complaintId}`;

    return this.http.delete(endpoint);
  }

  updateComplaint(complaintId: number, data: any): Observable<any> {
    const endpoint = `complaints/${complaintId}`;

    return this.http.put(endpoint, data);
  }

  getComplaints(filter: any, pagination: any): Observable<any> {
    const endpoint = 'complaints';

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }
}
