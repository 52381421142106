var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { finalize, map, tap } from 'rxjs/operators';
import { ComplaintsService } from '@app/core/services/complaints/complaints.service';
import { DataService } from '@app/core/services/data/data.service';
import { ProvidersService } from '@app/core/services/providers/providers.service';
import { ServicesService } from '@app/core/services/services/services.service';
import { SpacesService } from '@app/core/services/spaces/spaces.service';
import { UserService } from '@app/core/services/user/user.service';
import { ComplaintsState } from '../state/complaints.state';
// Extends Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { CurrentUserService } from '@app/core/services/current-user/current-user.service';
// Utils
import { Complaint } from '../models/complaint.model';
import * as i0 from "@angular/core";
import * as i1 from "../state/complaints.state";
import * as i2 from "../../../core/services/complaints/complaints.service";
import * as i3 from "../../../core/services/data/data.service";
import * as i4 from "../../../core/services/services/services.service";
import * as i5 from "../../../core/services/spaces/spaces.service";
import * as i6 from "../../../core/services/providers/providers.service";
import * as i7 from "../../../core/services/user/user.service";
import * as i8 from "../../../core/services/fmx-toastr/fmx-toastr.service";
import * as i9 from "../../../core/services/current-user/current-user.service";
var ComplaintsFacadeService = /** @class */ (function (_super) {
    __extends(ComplaintsFacadeService, _super);
    function ComplaintsFacadeService(state, complaintsService, dataService, servicesService, spacesService, providersService, userService, fmxToastr, currentUserService) {
        var _this = _super.call(this, currentUserService) || this;
        _this.state = state;
        _this.complaintsService = complaintsService;
        _this.dataService = dataService;
        _this.servicesService = servicesService;
        _this.spacesService = spacesService;
        _this.providersService = providersService;
        _this.userService = userService;
        _this.fmxToastr = fmxToastr;
        _this.currentUserService = currentUserService;
        return _this;
    }
    ComplaintsFacadeService.prototype.loadComplaints = function () {
        var _this = this;
        this.state.set('loadingComplaintsSub', true);
        var pagination = this.state.get('paginationComplaintsSub');
        var service = this.getEndpointComplaints().pipe(tap(function (response) { return _this.setPagination(pagination, response); }), map(function (response) {
            return response.data.map(function (complaint) { return new Complaint(complaint, response.included); });
        }));
        service
            .pipe(finalize(function () { return _this.state.set('loadingComplaintsSub', false); }))
            .subscribe(function (response) {
            return _this.state.set('complaintsSub', response);
        });
    };
    ComplaintsFacadeService.prototype.changePage = function (page) {
        var pagination = this.state.get('paginationComplaintsSub');
        this.state.set('paginationComplaintsSub', __assign({}, pagination, { page: page }));
        this.loadComplaints();
    };
    ComplaintsFacadeService.prototype.changeFilter = function (filter) {
        this.state.set('filterComplaintsSub', __assign({}, filter));
        this.loadComplaints();
    };
    ComplaintsFacadeService.prototype.resetFilter = function () {
        this.state.resetKey('filterComplaintsSub', 'filterComplaints');
        this.state.resetKey('paginationComplaintsSub', 'paginationComplaints');
        this.loadComplaints();
    };
    ComplaintsFacadeService.prototype.deleteComplaint = function (complaintId) {
        return this.complaintsService.deleteComplaint(complaintId);
    };
    ComplaintsFacadeService.prototype.createComplaint = function (serviceId, data) {
        return this.complaintsService.createComplaint(serviceId, data);
    };
    ComplaintsFacadeService.prototype.editComplaint = function (complaintId, data) {
        return this.complaintsService.updateComplaint(complaintId, data);
    };
    ComplaintsFacadeService.prototype.markAsCompleteComplaint = function (idProvider, idComplaint, data) {
        return this.providersService.markAsCompleteComplaint(idProvider, idComplaint, data);
    };
    ComplaintsFacadeService.prototype.getComplaintKinds = function () {
        return this.dataService.getComplaintKinds();
    };
    ComplaintsFacadeService.prototype.getSeveritiesComplaints = function () {
        return this.dataService.getSeveritiesComplaints();
    };
    ComplaintsFacadeService.prototype.getComplaintStatuses = function () {
        return this.dataService.getComplaintsStatuses();
    };
    ComplaintsFacadeService.prototype.getServiceSpace = function (serviceId) {
        return this.servicesService.getService(serviceId);
    };
    ComplaintsFacadeService.prototype.getSpaceServices = function (spaceId) {
        return this.spacesService.getSpaceServices(spaceId);
    };
    ComplaintsFacadeService.prototype.updateService = function (serviceId, data) {
        var _this = this;
        this.servicesService.updateService(serviceId, data).subscribe(function () {
            _this.fmxToastr.success('Servicio asociado correctamente');
            _this.loadComplaints();
        }, function () {
            _this.fmxToastr.error('Error al asociar servicio, por favor pruebe en unos minutos.');
        });
    };
    ComplaintsFacadeService.prototype.getComplaintProviderStatus = function () {
        return this.dataService.getComplaintProviderStatuses();
    };
    ComplaintsFacadeService.prototype.getEndpointComplaints = function () {
        var filter = this.state.get('filterComplaintsSub');
        var pagination = this.state.get('paginationComplaintsSub');
        if (this.isOperator()) {
            return this.complaintsService.getComplaints(filter, pagination);
        }
        if (this.isProvider()) {
            return this.providersService.getComplaintsProvider(filter, pagination, this.relationships().provider.data.id);
        }
        return this.userService.getComplaintsClient(filter, pagination, this.currentUser.id);
    };
    ComplaintsFacadeService.prototype.setPagination = function (pagination, response) {
        this.state.set('paginationComplaintsSub', __assign({}, pagination, { current_page: response.meta.current_page, last_page: response.meta.last_page }));
    };
    ComplaintsFacadeService.ngInjectableDef = i0.defineInjectable({ factory: function ComplaintsFacadeService_Factory() { return new ComplaintsFacadeService(i0.inject(i1.ComplaintsState), i0.inject(i2.ComplaintsService), i0.inject(i3.DataService), i0.inject(i4.ServicesService), i0.inject(i5.SpacesService), i0.inject(i6.ProvidersService), i0.inject(i7.UserService), i0.inject(i8.FmxToastr), i0.inject(i9.CurrentUserService)); }, token: ComplaintsFacadeService, providedIn: "root" });
    return ComplaintsFacadeService;
}(CurrentUserFacadeService));
export { ComplaintsFacadeService };
