var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { Billing } from '@app/fmx-modules/fmx-billing/interfaces/billing.model';
import { map } from 'rxjs/operators';
var InvoicesService = /** @class */ (function () {
    function InvoicesService(http) {
        this.http = http;
    }
    InvoicesService.prototype.getClientInvoices = function (filter, pagination) {
        var endpoint = "client_invoices";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    InvoicesService.prototype.uploadInvoiceToNavision = function (typeInvoice, idInvoice, data) {
        var endpoint = typeInvoice + "/" + idInvoice + "/navision_synchronizations";
        return this.http.post(endpoint, data);
    };
    InvoicesService.prototype.sendInvoice = function (typeInvoice, idInvoice, data) {
        var endpoint = typeInvoice + "/" + idInvoice + "/sendings";
        return this.http.post(endpoint, data);
    };
    InvoicesService.prototype.downloadInvoice = function (typeInvoice, idInvoice) {
        var endpoint = typeInvoice + "/" + idInvoice + ".pdf";
        return this.http.get(endpoint, { responseType: 'blob' });
    };
    InvoicesService.prototype.updateInvoice = function (typeInvoice, idInvoice, data) {
        var endpoint = typeInvoice + "/" + idInvoice;
        return this.http.put(endpoint, data);
    };
    InvoicesService.prototype.getInvoices = function (filter, pagination) {
        var endpoint = "invoices";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    InvoicesService.prototype.createInvoice = function (data) {
        var endpoint = "invoices";
        return this.http
            .post(endpoint, data)
            .pipe(map(function (response) {
            return response.data.map(function (item) { return new Billing(item); });
        }));
    };
    InvoicesService.prototype.amendmentInvoice = function (idInvoice) {
        var endpoint = "invoices/" + idInvoice + "/amendment_invoice";
        var data = { invoice: { dummy: 'true' } };
        return this.http.post(endpoint, data);
    };
    InvoicesService.prototype.getAmendmentInvoice = function (amendmentInvoiceId) {
        var endpoint = "invoices/" + amendmentInvoiceId + "/amendment_invoice";
        return this.http.get(endpoint);
    };
    InvoicesService.prototype.updateAmendmentInvoice = function (idInvoice, data) {
        var endpoint = "invoices/" + idInvoice + "/amendment_invoice";
        return this.http.put(endpoint, data);
    };
    InvoicesService.prototype.cancelInvoice = function (idInvoice) {
        var endpoint = "invoices/" + idInvoice;
        return this.http.delete(endpoint);
    };
    InvoicesService.prototype.downloadZipInvoices = function (params) {
        var endpoint = 'invoices.zip';
        return this.http.get(endpoint, { params: params, responseType: 'blob' });
    };
    InvoicesService.prototype.downloadXls = function (params) {
        return this.http.get('invoices.xlsx', {
            params: params,
            responseType: 'blob'
        });
    };
    InvoicesService.prototype.downloadAmendmentInvoice = function (idInvoice) {
        return this.http.get("invoices/" + idInvoice + "/amendment_invoice.pdf", {
            responseType: 'blob'
        });
    };
    return InvoicesService;
}());
export { InvoicesService };
