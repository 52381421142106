import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { ConfigurationService } from './../../services/configuration.service';

// Interfaces
import { IRow } from '../../interfaces/row';
import { IRowAction } from '../../interfaces/row-action';
import { ITableConfiguration } from '../../interfaces/table-configuration';
import { IColumn } from './../../interfaces/column';

@Component({
  selector: 'fmx-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
  _headers: IColumn[];
  @Input()
  set headers(value: IColumn[]) {
    if (value) {
      this._headers = value.map((h: IColumn) => {
        return this.configurationService.parseColumn(h);
      });
    }
  }
  @Input() loading = false;
  @Input() rows: IRow[];

  _configuration: ITableConfiguration;
  @Input()
  set configuration(conf: ITableConfiguration) {
    this._configuration = {
      ...this.defaultConf,
      ...conf
    };
  }

  @Output() rowAction = new EventEmitter<IRowAction>();
  @Output() columnAction = new EventEmitter<any>();

  defaultConf: ITableConfiguration = {
    theme: 'default',
    editable: false
  };

  constructor(private configurationService: ConfigurationService) {}

  trackByFn(item: IRow): string {
    return item.id;
  }

  trackByFnTh(item: any): string {
    return item.name;
  }

  onAction(rowActionPayload: IRowAction): void {
    this.rowAction.emit(rowActionPayload);
  }

  sortByColumn(index: number): void {
    const columns = JSON.parse(JSON.stringify(this._headers));
    const column = JSON.parse(JSON.stringify(this._headers[index]));
    if (column.configuration.sortable) {
      column.configuration.direction = this.configurationService.setColumnDirection(
        column
      );

      // Reset other columns order
      columns.forEach((c: IColumn) => {
        c.configuration.direction = null;
      });
      columns[index] = column;

      this.columnAction.emit({
        action: 'sort',
        sortBy: column.configuration.sortableKey,
        sortDirection: column.configuration.direction
      });

      this._headers = columns;
    }
  }
}
