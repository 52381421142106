import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  template: `
    <h1 mat-dialog-title>{{ data?.title }}</h1>
    <mat-dialog-content>
      <p [innerHtml]="data?.content"></p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="fmx-btn fmx-btn-clear fmx-btn-bold"
        [ngClass]="data?.btnCancelClass ? data?.btnCancelClass : 'fmx-btn-red'"
        (click)="cancel()"
      >
        {{ data?.btnCancel ? data?.btnCancel : 'Cancelar' }}
      </button>

      <button
        class="fmx-btn fmx-btn-bold"
        [ngClass]="
          data?.btnSuccessClass ? data?.btnSuccessClass : 'fmx-btn-green'
        "
        (click)="success()"
      >
        {{ data?.btnSuccess ? data?.btnSuccess : 'Confirmar' }}
      </button>
    </mat-dialog-actions>
  `
})
export class ConfirmDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  success() {
    this.emit(true);
  }
  cancel() {
    this.emit(false);
  }

  emit(result: boolean) {
    this.dialogRef.close(result);
  }
}
