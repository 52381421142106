import { DeviceDetectorService } from 'ngx-device-detector';
// tslint:disable-next-line: no-require-imports
var appVersion = require('../../../../../package.json').version;
var DeviceService = /** @class */ (function () {
    function DeviceService(deviceService) {
        this.deviceService = deviceService;
    }
    Object.defineProperty(DeviceService.prototype, "os", {
        get: function () {
            return this.getDeviceInfo().os;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceService.prototype, "appVersion", {
        get: function () {
            return appVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceService.prototype, "osVersion", {
        get: function () {
            var _this = this;
            var osVersion = this.getDeviceInfo().os_version;
            return osVersion === 'unknown'
                ? this.userAgent()
                    .split(';')
                    .find(function (item) { return item.includes(_this.os); })
                : osVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceService.prototype, "browserName", {
        get: function () {
            return this.getDeviceInfo().browser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceService.prototype, "browserVersion", {
        get: function () {
            return this.getDeviceInfo().browser_version;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceService.prototype, "deviceName", {
        get: function () {
            var deviceName = this.getDeviceInfo().device;
            return deviceName.toLocaleLowerCase() === 'unknown'
                ? 'Desktop'
                : deviceName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceService.prototype, "deviceType", {
        get: function () {
            var typeDevice = '';
            var deviceType = {
                Desktop: this.deviceService.isDesktop(),
                Tablet: this.deviceService.isTablet(),
                Mobile: this.deviceService.isMobile()
            };
            Object.keys(deviceType).forEach(function (device) {
                if (deviceType[device]) {
                    typeDevice = device;
                }
            });
            return typeDevice;
        },
        enumerable: true,
        configurable: true
    });
    DeviceService.prototype.userAgent = function () {
        return this.getDeviceInfo().userAgent;
    };
    DeviceService.prototype.getDeviceInfo = function () {
        return this.deviceService.getDeviceInfo();
    };
    return DeviceService;
}());
export { DeviceService };
