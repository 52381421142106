import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

@Injectable()
export class ImagesService {
  constructor(private http: HttpClient) {}

  getServiceVisitImages(serviceId: string, visitId: string) {
    const endpoint = `services/${serviceId}/visits/${visitId}/images`;

    return this.http
      .get(endpoint)
      .pipe(map((response: any) => response && response.data));
  }

  getServiceVisitFiles(serviceId: string, visitId: string) {
    const endpoint = `services/${serviceId}/visits/${visitId}/file_attachments`;

    return this.http
      .get(endpoint)
      .pipe(map((response: any) => response && response.data));
  }

  createServiceVisitImage(serviceId: string, visitId: string, body: any) {
    const endpoint = `services/${serviceId}/visits/${visitId}/images`;

    return this.http
      .post(endpoint, body)
      .pipe(map((response: any) => response));
  }

  createServiceVisitDocument(serviceId: string, visitId: string, body: any) {
    const endpoint = `services/${serviceId}/visits/${visitId}/file_attachments`;

    return this.http
      .post(endpoint, body)
      .pipe(map((response: any) => response));
  }

  deleteServiceVisitImage(serviceId: string, visitId: string, id: string) {
    const endpoint = `services/${serviceId}/visits/${visitId}/images/${id}`;

    return this.http.delete(endpoint).pipe(map((response: any) => response));
  }

  deleteServiceVisitFile(serviceId: string, visitId: string, id: string) {
    const endpoint = `services/${serviceId}/visits/${visitId}/file_attachments/${id}`;

    return this.http.delete(endpoint).pipe(map((response: any) => response));
  }

  donloadImage(url: string) {
    return this.http.get(url);
  }
}
