var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
// Const
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
// Utils
import { generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';
var CompanyAggrupationsService = /** @class */ (function () {
    function CompanyAggrupationsService(http) {
        this.http = http;
    }
    CompanyAggrupationsService.prototype.getSearch = function (idClient, filter, sort, pagination) {
        var endpoint = "company_aggrupations/" + idClient + "/search";
        var params = __assign({}, filter, sort, pagination);
        return this.http.get(endpoint, { params: params });
    };
    CompanyAggrupationsService.prototype.createClient = function (data) {
        var endpoint = 'accounts';
        return this.http.post(endpoint, data);
    };
    CompanyAggrupationsService.prototype.getCompanyAggrupations = function (filter, sort, pagination) {
        var endpoint = "company_aggrupations";
        var params = __assign({}, filter, sort, pagination);
        return this.http.get(endpoint, { params: params });
    };
    CompanyAggrupationsService.prototype.getCompanyAggrupation = function (idCompanyAggrupation) {
        var endpoint = "company_aggrupations/" + idCompanyAggrupation;
        return this.http.get(endpoint);
    };
    CompanyAggrupationsService.prototype.getCompanyAggrupationsByPage = function (page, search) {
        if (search === void 0) { search = ''; }
        var params = new HttpParams()
            .set('sort_by', 'name')
            .set('sort_dir', 'asc')
            .set('services', 'true')
            .set('minimum_api', 'true')
            .set('per_page', ELEMENTS_PER_PAGE)
            .set('page', page.toString());
        if (search) {
            params = params.append('name', search);
        }
        var endpoint = 'company_aggrupations';
        return this.http
            .get(endpoint, { params: params })
            .pipe(map(generateResponseDataPage));
    };
    CompanyAggrupationsService.prototype.updateCompanyAggrupation = function (idClient, params) {
        var endpoint = "company_aggrupations/" + idClient;
        return this.http.patch(endpoint, params);
    };
    CompanyAggrupationsService.prototype.getChildCompanyAggrupation = function (caId) {
        var endpoint = "company_aggrupations/" + caId + "/children";
        return this.http.get(endpoint);
    };
    CompanyAggrupationsService.prototype.createCompany = function (idCompanyAgg, data) {
        var endpoint = "company_aggrupations/" + idCompanyAgg + "/companies";
        return this.http.post(endpoint, data);
    };
    CompanyAggrupationsService.prototype.getCurrentBasePricelist = function (clientId) {
        return this.http.get("company_aggrupations/" + clientId + "/pricelists");
    };
    CompanyAggrupationsService.prototype.getUsersClient = function (idClient) {
        var endpoint = "company_aggrupations/" + idClient + "/users";
        return this.http.get(endpoint);
    };
    CompanyAggrupationsService.prototype.addUserClient = function (parent, idClient, data) {
        var endpoint = parent + "/" + idClient + "/users";
        return this.http.post(endpoint, data);
    };
    CompanyAggrupationsService.prototype.getFiscalInfoCompanyAggrupation = function (idClient) {
        var endpoint = "company_aggrupations/" + idClient + "/fiscal_info?include=state.country";
        return this.http.get(endpoint);
    };
    CompanyAggrupationsService.prototype.getPricelistPdf = function (idCompanyAgg, idPricelist) {
        var endpoint = "company_aggrupations/" + idCompanyAgg + "/pricelists/" + idPricelist + ".pdf";
        return this.http.get(endpoint, { responseType: 'blob' });
    };
    CompanyAggrupationsService.prototype.getServiceLogsClient = function (idClient, filter, pagination) {
        var endpoint = "company_aggrupations/" + idClient + "/service_logs";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    return CompanyAggrupationsService;
}());
export { CompanyAggrupationsService };
