import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { Store } from '@app/store';

// Guards
import { InitGuard } from './guards/init.guard';

// Services
import { CompaniesService } from './services/companies/companies.service';
import { CompanyAggrupationsService } from './services/company-aggrupations/company-aggrupations.service';
import { ComplaintsService } from './services/complaints/complaints.service';
import { CurrentUserFacadeService } from './services/current-user/current-user.facade.service';
import { CurrentUserService } from './services/current-user/current-user.service';
import { DataService } from './services/data/data.service';
import { DeviceService } from './services/device/device.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { FmxToastr } from './services/fmx-toastr/fmx-toastr.service';
import { GroupsService } from './services/groups/groups.service';
import { HomeServiceService } from './services/home-service/home-service.service';
import { ImagesService } from './services/images/images.service';
import { InvoicesService } from './services/invoices/invoices.service';
import { LoadingService } from './services/loading/loading.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { OperatorDataService } from './services/operator-data/operator-data.service';
import { OperatorsService } from './services/operators/operators.service';
import { PermitsService } from './services/permits/permits.service';
import { ProvidersService } from './services/providers/providers.service';
import { RateListService } from './services/ratelist/ratelist.service';
import { RouteRefererService } from './services/route-referer/route-referer.service';
import { ScriptLoaderService } from './services/script-loader/script-loader.service';
import { ServicesService } from './services/services/services.service';
import { SpacesService } from './services/spaces/spaces.service';
import { UserService } from './services/user/user.service';

// External Components
import { BaseCurrentUserComponent } from './extends/base-current-user.component';
import { AuthService } from './services/auth/auth.service';

// Interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

// Guards
import { AuthCheckGuard } from './guards/auth-check.guard';
import { AuthGuard } from './guards/auth.guard';
import { IsClientOrOperatorGuard } from './guards/is-client-or-operator.guard';
import { IsClientGuard } from './guards/is-client.guard';
import { IsDoerGuard } from './guards/is-doer.guard';
import { IsOperatorGuard } from './guards/is-operator.guard';
import { ZonesService } from './services/zones/zones.service';

const PROVIDERS = [
  AuthService,
  UserService,
  DataService,
  CompanyAggrupationsService,
  CompaniesService,
  GroupsService,
  SpacesService,
  DeviceService,
  FirebaseService,
  LoadingService,
  RateListService,
  ProvidersService,
  OperatorsService,
  OperatorDataService,
  FmxToastr,
  ImagesService,
  CurrentUserService,
  CurrentUserFacadeService,
  RouteRefererService,
  PermitsService,
  LocalStorageService,
  Store,
  InvoicesService,
  ServicesService,
  ComplaintsService,
  ScriptLoaderService,
  ZonesService,
  HomeServiceService
];

const GUARDS = [
  AuthCheckGuard,
  AuthGuard,
  InitGuard,
  IsClientOrOperatorGuard,
  IsClientGuard,
  IsDoerGuard,
  IsOperatorGuard
];
@NgModule({
  declarations: [BaseCurrentUserComponent],
  imports: [HttpClientModule],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ...PROVIDERS,
    ...GUARDS
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'Core Module is already loaded. Import only in AppModule'
      );
    }
  }
}
