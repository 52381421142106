var ServiceBreachItemComponent = /** @class */ (function () {
    function ServiceBreachItemComponent() {
        this.icon = {
            svgIcon: 'assets/svg/map.svg#semaphore_square'
        };
    }
    Object.defineProperty(ServiceBreachItemComponent.prototype, "attributes", {
        get: function () {
            return this.breach && this.breach.attributes;
        },
        enumerable: true,
        configurable: true
    });
    return ServiceBreachItemComponent;
}());
export { ServiceBreachItemComponent };
