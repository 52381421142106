export class Session {
  id: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  token: string;
  attributes?: Attributes;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(that: any) {
    if (that) {
      this.id = that.data.id;
      this.type = that.data.type;
      this.token = that.data.attributes.token;
      this.attributes = that.included[0].attributes;
      this.attributes.authentiable_type = that.included[1].type;
    }
  }
}

interface Attributes {
  authentiable_type: string;
  email: string;
  email_confirmed: boolean;
  approved: boolean;
  b2b2c_tos?: boolean;
  b2b2c_enabled?: boolean;
}
