import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';

import { Store } from '@app/store';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class OperatorDataService {
  constructor(private http: HttpClient, private store: Store) {}

  getProviders(status: string[] = null) {
    let params = new HttpParams()
      .set('sort_by', 'name')
      .set('minimum_api', 'true')
      .set('per_page', ELEMENTS_PER_PAGE);

    if (status) {
      status.forEach((key: string) => {
        params = params.append('status[]', key);
      });
    }

    const endpoint = 'providers';

    return this.http.get(endpoint, { params }).pipe(
      tap((response: any) => {
        this.saveStore(response, 'providers');
      }),
      map((response: any) => {
        return response.data.map((provider: any) => {
          return {
            value: provider.id,
            name: provider.attributes.name
          };
        });
      })
    );
  }

  getCompanyAggrupations() {
    const params = new HttpParams()
      .set('per_page', '600')
      .set('sort_by', 'name')
      .set('minimum_api', 'true');
    const endpoint = 'company_aggrupations';

    return this.http.get(endpoint, { params }).pipe(
      tap((response: any) => {
        this.saveStore(response, 'companyAggrupations');
      }),
      map((response: any) => {
        return response.data.map((companyAggrupation: any) => {
          return {
            value: companyAggrupation.id,
            name: companyAggrupation.attributes.name
          };
        });
      })
    );
  }

  private saveStore(response: any, nameStore: string) {
    const entities = response.data.reduce((ent: any, data: any) => {
      return {
        ...ent,
        [data.id]: data
      };
    }, {});

    const options = response.data.map((option: any) => {
      return {
        name: option.attributes.name,
        value: +option.id
      };
    });

    const newState = {
      ['globalData']: {
        [nameStore]: options,
        [`${nameStore}Entities`]: entities
      }
    };
    this.store.set(newState);
  }
}
