var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RechargesRepaysService = /** @class */ (function () {
    function RechargesRepaysService(http) {
        this.http = http;
    }
    RechargesRepaysService.prototype.getRechargesProviders = function (filter, pagination) {
        var endpoint = "recharges";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    RechargesRepaysService.prototype.getRepaysClients = function (filter, pagination) {
        var endpoint = "repays";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    RechargesRepaysService.prototype.updateRechargeProvider = function (serviceId, rechargeId, params) {
        if (params === void 0) { params = {}; }
        var endpoint = "services/" + serviceId + "/recharges/" + rechargeId;
        return this.http.put(endpoint, params);
    };
    RechargesRepaysService.prototype.updateRepayClient = function (serviceId, repayId, params) {
        if (params === void 0) { params = {}; }
        var endpoint = "services/" + serviceId + "/repays/" + repayId;
        return this.http.put(endpoint, params);
    };
    RechargesRepaysService.ngInjectableDef = i0.defineInjectable({ factory: function RechargesRepaysService_Factory() { return new RechargesRepaysService(i0.inject(i1.HttpClient)); }, token: RechargesRepaysService, providedIn: "root" });
    return RechargesRepaysService;
}());
export { RechargesRepaysService };
