import { Pipe, PipeTransform } from '@angular/core';

const BREACH_SEVERITY_TYPES = {
  trivial: '#18C98C',
  minor: '#FFC403',
  medium: '#FFBA00',
  serious: '#FF8900',
  very_serious: '#dc3545'
};

@Pipe({
  name: 'breachSeverityColor'
})
export class BreachSeverityColorPipe implements PipeTransform {
  transform(value: any): string {
    return BREACH_SEVERITY_TYPES[value] || value;
  }
}
