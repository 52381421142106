var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Location } from '@angular/common';
import { Router } from '@angular/router';
// Extends Components
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
// Service
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
var ClientHeaderComponent = /** @class */ (function (_super) {
    __extends(ClientHeaderComponent, _super);
    function ClientHeaderComponent(router, location, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.router = router;
        _this.location = location;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        return _this;
    }
    ClientHeaderComponent.prototype.back = function () {
        this.location.back();
    };
    ClientHeaderComponent.prototype.goToPreventives = function () {
        this.router.navigate([
            "/dashboard-fmx/client/" + this.idClient + "/preventives"
        ]);
    };
    ClientHeaderComponent.prototype.goToMainInfo = function () {
        this.router.navigate(["/dashboard-fmx/client/" + this.idClient]);
    };
    return ClientHeaderComponent;
}(BaseCurrentUserComponent));
export { ClientHeaderComponent };
