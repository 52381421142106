import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';

@Component({
  selector: 'fmx-download-excel-success-modal',
  templateUrl: './fmx-download-excel-success-modal.component.html'
})
export class FmxDownloadExcelSuccessModalComponent implements OnInit {
  emailProfile: string;

  constructor(
    private currentUserService: CurrentUserFacadeService,
    private dialogRef: MatDialogRef<FmxDownloadExcelSuccessModalComponent>
  ) {}

  ngOnInit(): void {
    this.emailProfile = this.currentUserService.attributes().email;
  }

  close(refresh: boolean = false): void {
    this.dialogRef.close(refresh);
  }
}
