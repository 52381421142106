import { MatDialog } from '@angular/material';
// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';
// Modals
import { FmxUploadExcelMassiveModalComponent } from '../fmx-upload-excel-massive-modal/fmx-upload-excel-massive-modal.component';
var FmxUploadExcelMassiveComponent = /** @class */ (function () {
    function FmxUploadExcelMassiveComponent(dialog) {
        this.dialog = dialog;
        this.groupId = null;
    }
    Object.defineProperty(FmxUploadExcelMassiveComponent.prototype, "getNameButton", {
        get: function () {
            return this.typeUpload === 'services'
                ? 'Importar servicios'
                : 'Importar espacios';
        },
        enumerable: true,
        configurable: true
    });
    FmxUploadExcelMassiveComponent.prototype.openUploadModal = function () {
        this.dialog.open(FmxUploadExcelMassiveModalComponent, {
            width: getResolutionModal(),
            data: {
                typeUpload: this.typeUpload,
                title: this.getNameButton,
                groupId: this.groupId
            }
        });
    };
    return FmxUploadExcelMassiveComponent;
}());
export { FmxUploadExcelMassiveComponent };
