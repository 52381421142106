// tslint:disable: no-empty
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var FmxSelectComponent = /** @class */ (function () {
    function FmxSelectComponent() {
        this.ALL_OPTION = { name: 'Tod@s', value: '' };
        this.includeAll = true;
        this.label = '';
        this.required = false;
        this.placeholder = '';
        this.multiple = false;
        this.changeSelectedOption = new EventEmitter();
        this.cmpControl = new FormControl();
        this.searchControl = new FormControl();
        this.filteredList$ = new ReplaySubject(1);
        this.unsubscribe = new Subject();
        // tslint:disable-next-line: no-unused
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(FmxSelectComponent.prototype, "list", {
        set: function (value) {
            this.initPlaceholder();
            // tslint:disable-next-line: prefer-conditional-expression
            if (this.includeAll) {
                this._list = [this.ALL_OPTION].concat(value);
            }
            else {
                this._list = value;
            }
            this.filteredList$.next(this._list);
            if (this.multiple && this.includeAll) {
                this.cmpControl.setValue(this._list.map(function (item) { return item.value; }).slice());
            }
        },
        enumerable: true,
        configurable: true
    });
    FmxSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.optionAllValue = this.includeAll;
        // set initial selection
        this.cmpControl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (value) {
            _this.onChange(value);
        });
        // listen for search field value changes
        this.searchControl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function () {
            _this.filterList();
        });
    };
    FmxSelectComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    FmxSelectComponent.prototype.filterList = function () {
        var _this = this;
        if (!this._list) {
            return;
        }
        // get the search keyword
        var search = this.searchControl.value;
        if (!search) {
            this.filteredList$.next(this._list);
            return;
        }
        search = this.normalizeStr(search);
        // Filter
        this.filteredList$.next(this._list.filter(function (item) {
            return _this.normalizeStr(item.name).indexOf(search) > -1 ||
                (item.element && item.element.codename
                    ? _this.normalizeStr(item.element.codename).indexOf(search) > -1
                    : '');
        }));
    };
    FmxSelectComponent.prototype.initPlaceholder = function () {
        this.ALL_OPTION.name = this.placeholder === '' ? 'Tod@s' : this.placeholder;
    };
    FmxSelectComponent.prototype.writeValue = function (value) {
        this.cmpControl.setValue(value);
    };
    FmxSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    FmxSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    FmxSelectComponent.prototype.setDisabledState = function (isDisabled) {
        if (isDisabled) {
            this.cmpControl.disable();
        }
        else {
            this.cmpControl.enable();
        }
    };
    FmxSelectComponent.prototype.changeAllOption = function () {
        this.optionAllValue = !this.optionAllValue;
        if (this.multiple) {
            if (this.optionAllValue) {
                this.cmpControl.setValue(this._list.map(function (item) { return item.value; }).slice());
            }
            else {
                this.cmpControl.setValue([]);
            }
        }
        this.emitChangeSelectOption(this.cmpControl.value);
    };
    FmxSelectComponent.prototype.changeOption = function () {
        this.optionAllValue = false;
        var valueSelect = this.multiple
            ? this.cmpControl.value.filter(function (item) { return item !== ''; })
            : this.cmpControl.value;
        this.cmpControl.setValue(valueSelect);
        this.emitChangeSelectOption(valueSelect);
    };
    FmxSelectComponent.prototype.emitChangeSelectOption = function (valueSelect) {
        this.changeSelectedOption.emit({ value: valueSelect });
    };
    FmxSelectComponent.prototype.normalizeStr = function (value) {
        return value
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
    };
    return FmxSelectComponent;
}());
export { FmxSelectComponent };
