import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

import { FmxImageModalComponent } from './../../components/fmx-image-modal/fmx-image-modal.component';

@Injectable()
export class ImagePopupService {
  constructor(private dialog: MatDialog) {}

  openImage(image: any) {
    this.dialog.open(FmxImageModalComponent, {
      width: getResolutionModal(),
      data: {
        image
      }
    });
  }
}
