var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { SimpleChanges, EventEmitter } from '@angular/core';
import * as moment from 'moment';
// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { DashboardFacadeService } from '@app/features/dashboard/services/dashboard-facade.service';
// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
var DEFAULT_CONFIG = {
    grid: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        all: 0
    },
    slide: 4,
    speed: 250,
    point: {
        visible: false
    },
    load: 4,
    touch: true,
    loop: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
};
export var ACTIONS_CARROUSEL_CARDS = {
    edit_user: 'openActionsUsers',
    open_space: 'openSpaceDetail'
};
var FmxCarrouselCardsComponent = /** @class */ (function (_super) {
    __extends(FmxCarrouselCardsComponent, _super);
    function FmxCarrouselCardsComponent(facade, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.facade = facade;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.doAction = new EventEmitter();
        _this.carouselConfig = DEFAULT_CONFIG;
        return _this;
    }
    Object.defineProperty(FmxCarrouselCardsComponent.prototype, "config", {
        set: function (value) {
            this.carouselConfig = __assign({}, this.carouselConfig, value);
        },
        enumerable: true,
        configurable: true
    });
    // tslint:disable-next-line:use-life-cycle-interface
    FmxCarrouselCardsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.items.currentValue &&
            changes.items.currentValue.length &&
            !changes.items.currentValue.some(function (item) { return item.name === undefined; })) {
            this.actionMove();
        }
    };
    FmxCarrouselCardsComponent.prototype.goToSpace = function (spaceId) {
        var data = {
            payload: ACTIONS_CARROUSEL_CARDS.open_space,
            data: { spaceId: spaceId }
        };
        this.doAction.emit(data);
    };
    FmxCarrouselCardsComponent.prototype.timeBetweenDates = function (startDate, slaExpiresDate) {
        var start = moment(startDate);
        var end = moment(slaExpiresDate);
        return end.diff(start, 'hours');
    };
    FmxCarrouselCardsComponent.prototype.actionMove = function (data) {
        if (data === void 0) { data = null; }
        switch (this.typeActionMove) {
            case 'loadSpaceInfo':
                this.getSpaceInfo(data);
                break;
            default:
        }
    };
    FmxCarrouselCardsComponent.prototype.sendActionUser = function (user) {
        var data = {
            payload: ACTIONS_CARROUSEL_CARDS.edit_user,
            data: { user: user }
        };
        this.doAction.emit(data);
    };
    FmxCarrouselCardsComponent.prototype.hasRelationNodeProperty = function (item) {
        return item.attributes.hasOwnProperty('relatedThisNode');
    };
    FmxCarrouselCardsComponent.prototype.getSpaceInfo = function (data) {
        var _this = this;
        var firstElement = 0;
        var lastElement = this.items.length <= 2 ? this.items.length - 1 : 2;
        if (data) {
            var itemsPerPage = data.items;
            var activePage = data.currentSlide / itemsPerPage;
            firstElement = activePage * itemsPerPage;
            lastElement = firstElement + (itemsPerPage - 1);
        }
        var _loop_1 = function (i) {
            this_1.facade.loadSpaceInfo(this_1.items[i].id).subscribe(function (response) {
                var humanAddress = {
                    human_address: response.data.attributes.human_address
                };
                var internalCode = {
                    internal_code: response.data.attributes.internal_code
                };
                _this.items[i] = __assign({}, _this.items[i], humanAddress, internalCode);
            });
        };
        var this_1 = this;
        // tslint:disable-next-line:no-increment-decrement
        for (var i = firstElement; i <= lastElement; i++) {
            _loop_1(i);
        }
    };
    return FmxCarrouselCardsComponent;
}(BaseCurrentUserComponent));
export { FmxCarrouselCardsComponent };
