/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./fmx-download-excel-success-modal.component";
import * as i5 from "../../../../../core/services/current-user/current-user.facade.service";
var styles_FmxDownloadExcelSuccessModalComponent = [];
var RenderType_FmxDownloadExcelSuccessModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FmxDownloadExcelSuccessModalComponent, data: {} });
export { RenderType_FmxDownloadExcelSuccessModalComponent as RenderType_FmxDownloadExcelSuccessModalComponent };
export function View_FmxDownloadExcelSuccessModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "dialog-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "dialog-header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Descargar excel"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "dialog-close-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["class", "fmx-btn fmx-btn-clear fmx-btn-icon fmx-btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(6, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], null, null), (_l()(), i0.ɵted(-1, 0, ["close"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "mat-dialog-content", [["class", "row mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "col-12 mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "font-weight-bold color-secondary font-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["La petici\u00F3n de descarga ha sido generada correctamente, en breves recibir\u00E1 en su email ", " el fichero para su descarga"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "mat-dialog-actions", [["class", "col-12 d-flex flex-h-space-between mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(14, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "fmx-btn fmx-btn-green m-l-5 m-r-5"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" ACEPTAR "]))], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.emailProfile; _ck(_v, 12, 0, currVal_1); }); }
export function View_FmxDownloadExcelSuccessModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fmx-download-excel-success-modal", [], null, null, null, View_FmxDownloadExcelSuccessModalComponent_0, RenderType_FmxDownloadExcelSuccessModalComponent)), i0.ɵdid(1, 114688, null, 0, i4.FmxDownloadExcelSuccessModalComponent, [i5.CurrentUserFacadeService, i3.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FmxDownloadExcelSuccessModalComponentNgFactory = i0.ɵccf("fmx-download-excel-success-modal", i4.FmxDownloadExcelSuccessModalComponent, View_FmxDownloadExcelSuccessModalComponent_Host_0, {}, {}, []);
export { FmxDownloadExcelSuccessModalComponentNgFactory as FmxDownloadExcelSuccessModalComponentNgFactory };
