var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
var ComplaintsService = /** @class */ (function () {
    function ComplaintsService(http) {
        this.http = http;
    }
    ComplaintsService.prototype.createServiceFromComplaint = function (complaintId, params) {
        var endpoint = "complaints/" + complaintId + "/services";
        return this.http.post(endpoint, params);
    };
    ComplaintsService.prototype.createComplaint = function (serviceId, data) {
        var endpoint = "services/" + serviceId + "/complaints";
        return this.http.post(endpoint, data);
    };
    ComplaintsService.prototype.getComplaint = function (complaintId) {
        var endpoint = "complaints/" + complaintId;
        return this.http.get(endpoint);
    };
    ComplaintsService.prototype.deleteComplaint = function (complaintId) {
        var endpoint = "complaints/" + complaintId;
        return this.http.delete(endpoint);
    };
    ComplaintsService.prototype.updateComplaint = function (complaintId, data) {
        var endpoint = "complaints/" + complaintId;
        return this.http.put(endpoint, data);
    };
    ComplaintsService.prototype.getComplaints = function (filter, pagination) {
        var endpoint = 'complaints';
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    return ComplaintsService;
}());
export { ComplaintsService };
