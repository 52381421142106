<header [ngClass]="(authService.isLoggedIn | async) ? 'fmx-header' : ''">
  <ng-container *ngIf="authService.isLoggedIn | async">
    <!-- Mobile & Tablet -->
    <div class="fmx-header__mobile-container">
      <!-- Hambuger icon -->
      <div class="fmx-header__mobile-icon">
        <mat-icon *ngIf="!showMobileMenu" (click)="toggleMobileMenu()">
          menu
        </mat-icon>
        <mat-icon *ngIf="showMobileMenu" (click)="toggleMobileMenu()">
          close
        </mat-icon>
      </div>

      <!-- Menu -->
      <div
        class="fmx-header__options-container"
        [ngClass]="{ show: showMobileMenu }"
      >
        <div class="fmx-header__menu-container">
          <div
            class="fmx-header__button-container-mobile"
            *ngIf="isOperatorOrClient()"
          >
            <button
              class="fmx-btn fmx-btn-red fmx-btn-block"
              (click)="goToNewService()"
            >
              Solicitar servicio
            </button>
          </div>

          <ng-container *ngIf="isOperator()">
            <ng-container *ngTemplateOutlet="operatormenu"></ng-container>
          </ng-container>

          <ng-container *ngIf="isProvider()">
            <ng-container *ngTemplateOutlet="doermenu"></ng-container>
          </ng-container>

          <ng-container *ngIf="isClient()">
            <ng-container *ngTemplateOutlet="usermenu"></ng-container>
          </ng-container>

          <span class="profile-item">
            <a [routerLink]="['/profile']"> Perfil </a>
          </span>
          <span *ngIf="isClient()" class="profile-item">
            <a [routerLink]="['/profile/notifications']"> Notificaciones </a>
          </span>
          <span class="profile-item">
            <a class="pointer" (click)="logout()"> Logout </a>
          </span>
        </div>
      </div>
    </div>

    <!-- Laptop -->
    <div class="fmx-header__container">
      <!-- Logo -->
      <div class="fmx-header__logo-container">
        <a alt="FAMAEX" routerLink="/">
          <span class="logo"></span>
        </a>
      </div>

      <div class="fmx-header__options-container">
        <!-- Menu -->
        <div class="fmx-header__menu-container">
          <ng-container *ngIf="isOperator()">
            <ng-container *ngTemplateOutlet="operatormenu"></ng-container>
          </ng-container>

          <ng-container *ngIf="isProvider()">
            <ng-container *ngTemplateOutlet="doermenu"></ng-container>
          </ng-container>

          <ng-container *ngIf="isClient()">
            <ng-container *ngTemplateOutlet="usermenu"></ng-container>
          </ng-container>
        </div>

        <!-- Request service button & profile icon -->
        <div class="fmx-header__right-options-container">
          <div
            class="fmx-header__button-container"
            *ngIf="isOperatorOrClient()"
          >
            <button
              class="fmx-btn fmx-btn-red fmx-btn-sm"
              (click)="goToNewService()"
            >
              Solicitar servicio
            </button>
          </div>

          <div class="fmx-header__user-menu-container">
            <a class="pointer" [matMenuTriggerFor]="userMenu">
              <mat-icon>person</mat-icon>
            </a>
            <mat-menu
              #userMenu="matMenu"
              yPosition="below"
              xPosition="before"
              [overlapTrigger]="false"
              [classList]="'fmx-panel-menu'"
            >
              <a mat-menu-item [routerLink]="['/profile']"> Perfil </a>
              <a
                mat-menu-item
                *ngIf="isClient()"
                [routerLink]="['/profile/notifications']"
              >
                Notificaciones
              </a>
              <a mat-menu-item (click)="logout()"> Logout </a>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <fmx-banner-environment
    *ngIf="showBannerEnvironmentTest()"
  ></fmx-banner-environment>
</header>

<ng-template #doermenu>
  <!-- Doer Menu -->
  <span>
    <a [routerLink]="['/provider-services/assigned']" routerLinkActive="active"
      >Servicios asignados</a
    >
  </span>
  <span>
    <a [routerLink]="['/provider-services/finished']" routerLinkActive="active"
      >Servicios finalizados</a
    >
  </span>
  <span>
    <a class="pointer" [matMenuTriggerFor]="doersMenu" routerLinkActive="active"
      >Facturas
    </a>
  </span>
  <mat-menu
    #doersMenu="matMenu"
    yPosition="below"
    xPosition="after"
    [overlapTrigger]="false"
    [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
  >
    <a mat-menu-item [routerLink]="['billing/receipts']"> Facturas </a>
    <a mat-menu-item [routerLink]="['billing/b2b2c-providers']">
      Facturas B2B2C</a
    >
  </mat-menu>

  <span>
    <a
      [routerLink]="['/dashboard-fmx/providers/documents-provider']"
      routerLinkActive="active"
      >Documentos</a
    >
  </span>

  <span>
    <a [routerLink]="['/complaints']" routerLinkActive="active"
      >Reclamaciones</a
    >
    <svg
      *ngIf="showPointPendingComplaints"
      width="10px"
      height="10px"
      style="position: relative; right: 12px; top: -12px"
      [ngStyle]="{
        fill:
          currentUser?.attributes?.complaints_status
          | statusNotificationComplaintsColor
      }"
    >
      <use href="assets/svg/map.svg#semaphore_square"></use>
    </svg>
  </span>
</ng-template>

<ng-template #usermenu>
  <!-- User Menu -->
  <span>
    <a [routerLink]="['/client-services/services']" routerLinkActive="active"
      >Servicios</a
    >
  </span>
  <span *ngIf="!isUserHomeService()">
    <a [routerLink]="['/dashboard-fmx/child-detail']" routerLinkActive="active"
      >Espacios</a
    >
  </span>
  <span *ngIf="!isClientRegular()">
    <a [routerLink]="['billing/invoices']" routerLinkActive="active"
      >Facturas</a
    >
  </span>
  <span *ngIf="!isUserHomeService()">
    <a [routerLink]="['/complaints']" routerLinkActive="active"
      >Reclamaciones</a
    >
  </span>
</ng-template>

<ng-template #operatormenu>
  <!-- Operator Menu -->
  <span>
    <a
      [routerLink]="['/operator-services/dashboard']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >Inicio</a
    >
  </span>
  <span>
    <a
      [routerLink]="['/operator-services/services/mass']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >Servicios
    </a>
  </span>
  <ng-container *ngIf="!showMobileMenu">
    <span>
      <a
        class="pointer"
        [matMenuTriggerFor]="clientsMenu"
        routerLinkActive="active"
        >Proveedores
        <span class="none" [routerLink]="['/dashboard-fmx/providers']"></span>
        <span class="none" [routerLink]="['/recharges']"></span>
      </a>
    </span>
    <mat-menu
      #clientsMenu="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['/dashboard-fmx/providers']">
        Proveedores
      </a>
      <a mat-menu-item [routerLink]="['/recharges']"> Recobros </a>
    </mat-menu>
  </ng-container>
  <ng-container *ngIf="showMobileMenu">
    <span>
      <a [routerLink]="['/dashboard-fmx/providers']" routerLinkActive="active"
        >Proveedores</a
      >
    </span>
    <span>
      <a [routerLink]="['/recharges']" routerLinkActive="active">Recobros</a>
    </span>
  </ng-container>
  <ng-container *ngIf="!showMobileMenu">
    <span>
      <a
        class="pointer"
        [matMenuTriggerFor]="clientsMenu"
        routerLinkActive="active"
        >Clientes
        <span class="none" [routerLink]="['/dashboard/clients']"></span>
        <span class="none" [routerLink]="['/repays']"></span>
      </a>
    </span>
    <mat-menu
      #clientsMenu="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['/dashboard-fmx/clients']"> Clientes </a>
      <a mat-menu-item [routerLink]="['/repays']"> Repagos </a>
    </mat-menu>
  </ng-container>
  <ng-container *ngIf="showMobileMenu">
    <span>
      <a [routerLink]="['/dashboard-fmx/clients']" routerLinkActive="active"
        >Clientes</a
      >
    </span>
    <span>
      <a [routerLink]="['/repays']" routerLinkActive="active">Repagos</a>
    </span>
  </ng-container>

  <ng-container>
    <span>
      <a
        class="pointer"
        [matMenuTriggerFor]="billingMenu"
        routerLinkActive="active"
        >Facturas
      </a>
    </span>
    <mat-menu
      #billingMenu="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [matMenuTriggerFor]="billingMenuClient">Cliente</a>
      <a mat-menu-item [matMenuTriggerFor]="billingMenuProvider">Proveedor</a>
    </mat-menu>

    <mat-menu
      #billingMenuProvider="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['billing/receipts']"> Facturas </a>
      <a mat-menu-item [routerLink]="['billing/amendment_receipts']">
        Facturas de abono
      </a>
      <a mat-menu-item [routerLink]="['billing/acquittances']">
        Cartas de pago
      </a>
      <a mat-menu-item [routerLink]="['billing/b2b2c-providers']">
        Facturas B2B2C
      </a>
      <a mat-menu-item [routerLink]="['billing/additional_works_acquittances']">
        Cartas de pago B2B2C
      </a>
    </mat-menu>

    <mat-menu
      #billingMenuClient="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [matMenuTriggerFor]="billingMenuInvoices">Facturas</a>
      <a mat-menu-item [matMenuTriggerFor]="billingMenuQuotaInvoices">Cuotas</a>
      <a mat-menu-item [routerLink]="['billing/b2b2c-clients']">
        Facturas B2B2C
      </a>
      <a mat-menu-item [routerLink]="['/invoices/create-invoice']">
        Crear Factura
      </a>
    </mat-menu>

    <mat-menu
      #billingMenuInvoices="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['billing/invoices']"> Facturas </a>
      <a mat-menu-item [routerLink]="['billing/amendment_invoices']">
        Facturas de abono
      </a>
    </mat-menu>

    <mat-menu
      #billingMenuQuotaInvoices="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['billing/quota-invoices']">
        Facturas de cuotas</a
      >
      <a mat-menu-item [routerLink]="['billing/amendment_quota_invoices']">
        Facturas abono de cuotas
      </a>
    </mat-menu>
  </ng-container>

  <ng-container>
    <span>
      <a
        class="pointer"
        [matMenuTriggerFor]="configurationMenu"
        routerLinkActive="active"
        >Configuración
      </a>
    </span>

    <mat-menu
      #configurationMenu="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['/configuration/list']"> Preciarios </a>
      <a mat-menu-item [routerLink]="['/configuration/reports']"> Informes </a>
      <a
        *ngIf="isSuperAdmin()"
        mat-menu-item
        [routerLink]="['/configuration/operators']"
      >
        Operadores
      </a>
      <a
        mat-menu-item
        [matMenuTriggerFor]="configurationZonesMenu"
        *ngIf="isOperator()"
        >Zonas</a
      >
    </mat-menu>

    <mat-menu
      #configurationZonesMenu="matMenu"
      yPosition="below"
      xPosition="after"
      [overlapTrigger]="false"
      [classList]="'fmx-panel-menu fmx-panel-menu-lg'"
    >
      <a mat-menu-item [routerLink]="['configuration/zones/list']">
        Listado de zonas
      </a>
      <!-- <a
        mat-menu-item
        [routerLink]="['configuration/zones/spaces-without-zones']"
      >
        Espacios sin zona
      </a> -->
    </mat-menu>
  </ng-container>
  <span>
    <a [routerLink]="['/complaints']" routerLinkActive="active"
      >Reclamaciones</a
    >
  </span>
</ng-template>
