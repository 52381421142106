import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStatus'
})
export class InvoiceStatusPipe implements PipeTransform {
  status = {
    draft: 'Borrador',
    sent: 'Enviada',
    charged: 'Cobrada'
  };

  transform(value: string): string {
    return this.status[value];
  }
}
