export enum typeUser {
  operators = 'operator',
  users = 'user',
  doers = 'doer'
}
export class CurrentUser {
  id: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  attributes: AttributesCurrentUser;
  relationships?: any;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(that: any) {
    if (that) {
      this.id = that.id;
      this.type = typeUser[that.type];
      this.attributes = that.attributes;
      this.relationships = that.relationships;
    }
  }

  isOperator(): boolean {
    return this.type === typeUser.operators;
  }

  isProvider(): boolean {
    return this.type === typeUser.doers;
  }

  isClient(): boolean {
    return this.type === typeUser.users;
  }

  hasLevelSuperAdmin(): boolean {
    return this.attributes.level === 'superadmin';
  }

  hasLevelAdmin(): boolean {
    return this.attributes.level === 'admin';
  }

  hasLevelFinance(): boolean {
    return this.attributes.level === 'finance';
  }

  hasLevelRegular(): boolean {
    return this.attributes.level === 'regular';
  }
}

export interface AttributesCurrentUser {
  email: string;
  first_name: string;
  last_name: string;
  level: string;
  avatar?: any;
  manager?: boolean;
  nif?: string;
  phone?: string;
  phone_alternative?: string;
  alerts?: string[];
  b2b2c_tos?: boolean;
  home_services?: boolean;
  budget_approval_limit?: number;
  budget_manual_approval_limit?: number;
  complaints_status?: string;
  observation?: string;
  authorizer?: boolean;
}
