import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { filter } from 'rxjs/operators';

// Service
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';

// Extends Class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';

// Components
import { AddEditComplaintModalComponent } from '../add-edit-complaint-modal/add-edit-complaint-modal.component';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

@Component({
  selector: 'app-service-complaints-list',
  templateUrl: './service-complaints-list.component.html'
})
export class ServiceComplaintsListComponent extends BaseCurrentUserComponent {
  @Input() service = null;
  @Input() complaints: any;

  @Output() refreshList = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  refreshComplaintList() {
    this.refreshList.emit();
  }

  addComplaint() {
    const dialogRef = this.dialog.open(AddEditComplaintModalComponent, {
      width: getResolutionModal(),
      data: {
        service: this.service,
        title: 'Añadir reclamación',
        submit: 'Crear nueva reclamación'
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter((refresh: any) => refresh))
      .subscribe(() => this.refreshComplaintList());
  }
}
