import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fmxCurrency'
})
export class FmxCurrencyPipe implements PipeTransform {
  transform(value: number, showEmpty: boolean = false): string {
    if (value) {
      const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR'
      });

      return formatter.format(value);
    }

    return showEmpty ? '' : '--';
  }
}
