import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@app/core/services/auth/auth.service';
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { LoadingService } from '@app/core/services/loading/loading.service';
import { PermitsService } from '@app/core/services/permits/permits.service';

// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';

// Const
import { INITIAL_PAGE } from '@app/core/const/initial-pages.const';

// Environment
import { environment } from 'environments/environment';

@Component({
  selector: 'fmx-header',
  templateUrl: './fmx-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmxHeaderComponent
  extends BaseCurrentUserComponent
  implements OnInit, OnDestroy {
  showMobileMenu = false;
  showPointPendingComplaints = false;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    public authService: AuthService,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  ngOnInit() {
    this.showPointPendingComplaints =
      this.isProvider() &&
      this.currentUser.attributes.complaints_status !== 'none';
  }

  ngOnDestroy(): void {
    this.showMobileMenu = false;
  }

  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  logout(): void {
    this.loadingService.showLoading();

    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl(INITIAL_PAGE.login);
      this.loadingService.hideLoading();
    });
  }

  goProfile() {
    const url = this.getProfileLink();
    this.router.navigate(url);
  }

  goToNewService(): void {
    const url = this.isUserHomeService()
      ? '/services/new-home-service'
      : '/services/new-service';

    this.router.navigate([url]);
  }

  getProfileLink(): any[] {
    if (this.isProvider()) {
      return [`/dashboard/profile-doer`, this.currentUser.id];
    }

    if (this.isClient()) {
      return [`/dashboard/profile`];
    }

    return null;
  }

  showBannerEnvironmentTest(): boolean {
    return !environment.production;
  }
}
