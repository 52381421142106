import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { filter, mergeMap } from 'rxjs/operators';

// Components
import { ConfirmDialogComponent } from '@app/fmx-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AddEditComplaintModalComponent } from '../add-edit-complaint-modal/add-edit-complaint-modal.component';
import { FmxSelectModalComponent } from '../fmx-select-modal/fmx-select-modal.component';
import { MarkAsCompleteComplaintModalComponent } from '../mark-as-complete-complaint-modal/mark-as-complete-complaint-modal.component';

// Services
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';

// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { SpacesService } from '@app/core/services/spaces/spaces.service';
import { ComplaintsFacadeService } from '@app/features/complaints/services/complaints-facade.service';

// Extends Class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

// Models
import { SelectOption } from '@app/core/models/select-option.model';
import { Complaint } from '@app/features/complaints/models/complaint.model';

@Component({
  selector: 'app-service-complaint-item',
  templateUrl: './service-complaint-item.component.html'
})
export class ServiceComplaintItemComponent extends BaseCurrentUserComponent {
  @Input() complaint: Complaint;
  @Input() inServiceDetail = false;
  @Output() refreshList = new EventEmitter();

  complaintsKind: any[];
  icon = {
    svgIcon: 'assets/svg/map.svg#semaphore_square'
  };
  configModal = {
    title: 'Relacionar servicio',
    list: [],
    confirmButton: 'CONFIRMAR',
    showTextarea: false,
    selectLabel: 'Servicio'
  };

  get attributes() {
    return this.complaint && this.complaint.attributes;
  }

  get hideBtnCreate(): boolean {
    return (
      this.complaint.attributes.from_issue ||
      !!this.complaint.generatedServiceId()
    );
  }

  get isPendingToProvider(): boolean {
    return this.complaint.isPendingToProvider();
  }

  get expiredComplaint(): boolean {
    return this.complaint.expiredComplaint();
  }

  get isPendingOrOnGoing(): boolean {
    return this.complaint.isPending() || this.complaint.isOnGoing();
  }

  get generatedServiceId(): string {
    return this.complaint.generatedServiceId();
  }

  constructor(
    private dialog: MatDialog,
    private facade: ComplaintsFacadeService,
    private spaceService: SpacesService,
    private fmxToastr: FmxToastr,
    private router: Router,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  refreshComplaintList() {
    this.refreshList.emit();
  }

  openConfirmDelete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: getResolutionModal(),
      data: {
        title: 'Confirmación',
        content: '¿Seguro que desea eliminar la reclamación seleccionada?'
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.deleteComplaint();
      }
    });
  }

  editComplaint() {
    const dialogRef = this.dialog.open(AddEditComplaintModalComponent, {
      width: getResolutionModal(),
      data: {
        complaint: this.complaint,
        title: 'Editar reclamación',
        submit: 'Actualizar reclamación'
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter((response: boolean) => response))
      .subscribe(() => this.refreshComplaintList());
  }

  markAsCompleteComplaint() {
    const dialogRef = this.dialog.open(MarkAsCompleteComplaintModalComponent, {
      width: getResolutionModal(),
      data: {
        complaint: this.complaint
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter((response: boolean) => response))
      .subscribe(() => this.refreshComplaintList());
  }

  goToService() {
    this.router.navigate([
      `/service-detail/${this.complaint.generatedServiceId()}`
    ]);
  }

  createIssue() {
    this.router.navigate([
      `/services/complaint/${this.complaint.id}/new-service`
    ]);
  }

  relatedService() {
    const dialogRef = this.dialog.open(FmxSelectModalComponent, {
      width: getResolutionModal(),
      data: this.configModal
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data && data.result) {
        const params = {
          service: {
            source_complaint_id: this.complaint.id
          }
        };
        const serviceId = data.data.list;
        this.facade.updateService(serviceId, params);
      }
    });
  }

  refresh() {
    this.facade.loadComplaints();
  }

  getServices() {
    const serviceId = this.complaint.relationships.service.data.id;
    this.facade
      .getServiceSpace(serviceId)
      .pipe(
        mergeMap((service: any) =>
          this.facade.getSpaceServices(service.data.attributes.space_id)
        )
      )
      .subscribe((response: any) => {
        const formatServices: SelectOption[] = response.data.map(
          (service: any) => {
            return {
              value: service.id,
              name: `${service.id} - ${service.attributes.description}`
            };
          }
        );

        this.configModal.list.push(...formatServices);

        if (
          response.meta.current_page === response.meta.last_page ||
          response.meta.last_page === 0
        ) {
          this.spaceService.expandObservable = false;
          this.relatedService();
        }
      });
  }

  private deleteComplaint() {
    this.facade.deleteComplaint(this.complaint.id).subscribe(
      () => {
        this.fmxToastr.success('Reclamación eliminada correctamente');
        this.refreshComplaintList();
      },
      () => {
        this.fmxToastr.error(
          'Error al eliminar reclamación, por favor pruebe en unos minutos.'
        );
      }
    );
  }
}
