var RouteRefererService = /** @class */ (function () {
    function RouteRefererService() {
    }
    Object.defineProperty(RouteRefererService.prototype, "routeReferer", {
        get: function () {
            return this._routeReferer;
        },
        set: function (value) {
            this._routeReferer = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouteRefererService.prototype, "hasRoute", {
        get: function () {
            return !!this._routeReferer;
        },
        enumerable: true,
        configurable: true
    });
    RouteRefererService.prototype.reset = function () {
        this._routeReferer = null;
    };
    return RouteRefererService;
}());
export { RouteRefererService };
