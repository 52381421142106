import {
  Directive,
  Self,
  OnInit,
  OnDestroy,
  Renderer2,
  ElementRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const CLASS_TOOLTIP = 'tooltip-error';
const CLASS_CONTROL = 'control-invalid';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlError]'
})
export class FormErrorsDirective implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  private parentNode: any;

  constructor(
    @Self() private control: NgControl,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.control.statusChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe))
      .subscribe((status: string) => {
        this.parentNode = this.renderer.parentNode(this.el.nativeElement);
        this.removeTooltipError();

        if (status === 'INVALID' && this.control.errors) {
          this.renderer.addClass(this.parentNode, CLASS_CONTROL);
          this.createTooltipError();
        } else {
          this.renderer.removeClass(this.parentNode, CLASS_CONTROL);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getError(): string {
    return this.control.errors.required
      ? 'Obligatorio'
      : this.control.errors.error;
  }

  private createTooltipError() {
    const tooltip = this.renderer.createElement('div');
    const textError = this.renderer.createText(this.getError());

    this.renderer.appendChild(tooltip, textError);
    this.renderer.addClass(tooltip, CLASS_TOOLTIP);
    this.renderer.appendChild(this.parentNode, tooltip);
  }

  private removeTooltipError() {
    const tooltip = this.parentNode.getElementsByClassName(CLASS_TOOLTIP);

    if (tooltip.length) {
      this.renderer.removeChild(this.parentNode, tooltip[0]);
    }
  }
}
