// tslint:disable: no-void-expression
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';

// Component
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';

// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { ComplaintsFacadeService } from '@app/features/complaints/services/complaints-facade.service';

// Models
import { SelectOption } from '@app/core/models/select-option.model';

// Utils
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';

@Component({
  selector: 'app-add-edit-complaint-modal',
  templateUrl: './add-edit-complaint-modal.component.html'
})
export class AddEditComplaintModalComponent
  extends BaseCurrentUserComponent
  implements OnInit {
  complaint: any;
  form: FormGroup;
  services: any;
  filteredServices: Observable<any>;
  serviceId: number;

  complaintProviderStatus: SelectOption[] = [];
  complaintsKind: SelectOption[] = [];
  severities: SelectOption[] = [];
  statuses: SelectOption[] = [];

  private service: any;

  get attributes() {
    return this.complaint && this.complaint.attributes;
  }

  get serviceIdComplaint() {
    return this.complaint && this.complaint.id;
  }

  get modalParams() {
    return this.data;
  }

  constructor(
    private dialogRef: MatDialogRef<AddEditComplaintModalComponent>,
    public formBuilder: FormBuilder,
    private facade: ComplaintsFacadeService,
    private fmxToastr: FmxToastr,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(currentUserFacade, permitsService);
  }

  ngOnInit(): void {
    this.complaint = this.data && this.data.complaint;
    this.service = this.data && this.data.service;

    this.serviceId = this.service ? this.service.id : this.serviceIdComplaint;

    if (!this.isClient()) {
      this.onInitOperator();
    } else {
      this.onInitClient();
    }
  }

  closeModal(refresh: boolean = false): void {
    this.dialogRef.close(refresh);
  }

  submitComplaint(): void {
    this.complaint ? this.editComplaint() : this.createComplaint();
  }

  createComplaint(): void {
    this.facade.createComplaint(this.serviceId, this.form.value).subscribe(
      () => {
        this.fmxToastr.success('Reclamación añadida correctamente');
        this.closeModal(true);
      },
      (error: any) => {
        const errors = error.error.errors;
        checkErrors(errors, this.form);
      }
    );
  }

  editComplaint(): void {
    this.facade.editComplaint(this.serviceId, this.form.value).subscribe(
      () => {
        this.fmxToastr.success('Reclamación editada correctamente');
        this.closeModal(true);
      },
      (error: any) => {
        const errors = error.error.errors;
        checkErrors(errors, this.form);
      }
    );
  }

  private onInitOperator(): void {
    this.getKinds();
    this.getComplaintProviderStatus();
    this.getSeverities();
    this.getStatuses();
    this.initForm();
  }

  private onInitClient(): void {
    this.initFormClient();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      description: [this.getValue('description')],
      comment_to_provider: [this.getValue('comment_to_provider')],
      comment_to_client: [this.getValue('comment_to_client')],
      provider_access: [this.getValueBoolean('provider_access')],
      client_access: [this.getValueBoolean('client_access')],
      provider_status: [this.getValue('provider_status')],
      kind: [this.getValue('kind')],
      severity: [this.getValue('severity')],
      title: [this.getValue('title')],
      status: [
        this.getValue('status') === '' ? 'pending' : this.getValue('status')
      ],
      contact_name: [this.getContactValue('contact_name')],
      contact_phone: [this.getContactValue('contact_phone')]
    });
  }

  private initFormClient(): void {
    this.form = this.formBuilder.group({
      description: [this.getValue('description')],
      kind: ['other'],
      severity: ['medium'],
      title: [this.getValue('title')],
      contact_name: [
        `${this.currentUser.attributes.first_name} ${this.currentUser.attributes.last_name}`
      ],
      contact_phone: [this.currentUser.attributes.phone]
    });
  }

  private getValue(formControlName: string): string {
    return (this.attributes && this.attributes[formControlName]) || '';
  }

  private getValueBoolean(formControlName: string): boolean {
    return (this.attributes && this.attributes[formControlName]) || false;
  }

  private getContactValue(formControlName: string): string {
    return this.service
      ? this.service.attributes[formControlName]
      : this.complaint.attributes[formControlName];
  }

  private getKinds(): void {
    this.facade
      .getComplaintKinds()
      .subscribe(
        (response: SelectOption[]) => (this.complaintsKind = response)
      );
  }

  private getSeverities(): void {
    this.facade
      .getSeveritiesComplaints()
      .subscribe((response: SelectOption[]) => (this.severities = response));
  }

  private getStatuses(): void {
    this.facade
      .getComplaintStatuses()
      .subscribe((response: SelectOption[]) => (this.statuses = response));
  }

  private getComplaintProviderStatus(): void {
    this.facade
      .getComplaintProviderStatus()
      .subscribe(
        (response: SelectOption[]) => (this.complaintProviderStatus = response)
      );
  }
}
