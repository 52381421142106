export enum BillingType {
  receipts = 'receipts',
  invoices = 'invoices',
  acquittances = 'acquittances',
  amendmentReceipts = 'amendment_receipts',
  amendmentInvoices = 'amendment_invoices',
  clientInvoices = 'client_invoices',
  providerInvoices = 'provider_invoices',
  additionalWorksAcquittances = 'additional_works_acquittances',
  quotaInvoices = 'quota_invoices',
  amendmentQuotaInvoices = 'amendment_quota_invoices'
}
