var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
// const
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
import { generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';
var ServicesService = /** @class */ (function () {
    function ServicesService(http) {
        this.http = http;
    }
    ServicesService.prototype.getServices = function (params) {
        var endpoint = "services";
        return this.http.get(endpoint, { params: params });
    };
    ServicesService.prototype.getService = function (serviceId) {
        var endpoint = "services/" + serviceId;
        return this.http.get(endpoint);
    };
    ServicesService.prototype.updateService = function (serviceId, data) {
        var endpoint = "services/" + serviceId;
        return this.http.put(endpoint, data);
    };
    ServicesService.prototype.uploadAttachmentService = function (serviceId, data) {
        var endpoint = "services/" + serviceId + "/file_attachments";
        return this.http.post(endpoint, data);
    };
    ServicesService.prototype.getBillableServices = function (filter, pagination) {
        var endpoint = "services";
        var params = __assign({ invoiceable: true }, filter, pagination);
        return this.http.get(endpoint, { params: params }).pipe(map(function (response) {
            // tslint:disable-next-line:underscore-consistent-invocation
            var orderData = _.groupBy(response.data, function (item) { return item.attributes.invoice_fiscal_name; });
            response.data = orderData;
            return response;
        }));
    };
    ServicesService.prototype.getRecommendedProviders = function (serviceId, page, search) {
        if (search === void 0) { search = ''; }
        var params = new HttpParams()
            .set('per_page', ELEMENTS_PER_PAGE)
            .set('page', page.toString());
        if (search) {
            params = params.append('name', search);
        }
        var endpoint = "services/" + serviceId + "/recommended_providers";
        return this.http
            .get(endpoint, { params: params })
            .pipe(map(generateResponseDataPage));
    };
    ServicesService.prototype.getServicesToXls = function (params) {
        var endpoint = 'services.xlsx';
        return this.http.get(endpoint, { params: params, responseType: 'blob' });
    };
    return ServicesService;
}());
export { ServicesService };
