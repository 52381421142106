import { AbstractControl, FormGroup } from '@angular/forms';
export function mapKeyFormToError(
  controls: { [key: string]: AbstractControl },
  keySearch: string
): string {
  return Object.keys(controls).find((key: string) => key.includes(keySearch));
}

export function checkErrors(errors: any, form: FormGroup) {
  if (errors) {
    Object.keys(errors).forEach((key: string) => {
      mapFormErrors(key, form, errors[key][0]);
    });
  }
}

function mapFormErrors(key: string, form: FormGroup, error: string) {
  const obj: { [k: string]: any } = {};

  if (form.controls[key]) {
    obj[key] = key;
  } else if (form.controls[`${key}_id`]) {
    obj[`${key}_id`] = `${key}_id`;
  } else if (key.split('.').length > 0) {
    const keyValue = key.split('.')[0];
    const keyValue2 = key.split('.')[1];

    if (form.controls[`${keyValue}_attributes`]) {
      if (form.controls[`${keyValue}_attributes`].get(keyValue2)) {
        obj[`${keyValue}_attributes`] = keyValue2;
      } else if (
        form.controls[`${keyValue}_attributes`].get(`${keyValue2}_id`)
      ) {
        obj[`${keyValue}_attributes`] = `${keyValue2}_id`;
      }
    }
  }

  setErrorsForm(obj, form, error);
}

function setErrorsForm(
  pipeKey: { [k: string]: any },
  form: FormGroup,
  error: string
) {
  if (JSON.stringify(pipeKey) !== '{}') {
    const key = Object.keys(pipeKey)[0];
    const value = Object.values(pipeKey)[0];

    if (form.controls[key].get(value)) {
      form.controls[key].get(value).setErrors({
        error
      });
    } else {
      form.controls[value].setErrors({
        error
      });
    }
  }
}
