import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InitialState } from './initial-state';
import { State } from './state';
import { StoreModel } from './store-model';

@Injectable()
export class Store {
  _store: State = InitialState;

  getModel<T>(key: string): StoreModel<T> {
    return this._store[key] as StoreModel<T>;
  }

  getValue<T>(name: string, key?: string): T {
    const model = key ? this.getModel<T>(key) : this.getModel<T>(name);

    if (key) {
      return model.getValue<T>(name);
    }

    return model.value;
  }

  select<T>(name: string, key?: string): Observable<T> {
    const model = key ? this.getModel<T>(key) : this.getModel<T>(name);

    if (key) {
      return model.select<T>(name);
    }

    return model.data$;
  }

  // tslint:disable-next-line:no-reserved-keywords
  set<T>(partialState: any): void {
    Object.keys(partialState).forEach((key: string) => {
      const model = this.getModel<T>(key);

      model.set(partialState[key]);
    });
  }

  snapshot(): any {
    const result: any = {};

    Object.keys(this._store).forEach((key: string) => {
      const model = this.getModel(key);
      result[key] = model.value;
    });

    return result;
  }
}
