var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ConfigurationService } from './../../../services/configuration.service';
var CellNumberComponent = /** @class */ (function () {
    function CellNumberComponent(configStyles) {
        this.configStyles = configStyles;
        this._cell = {};
        this.defaultConf = {
            withDot: false,
            bold: false,
            align: 'left',
            color: 'primary',
            width: 'medium'
        };
    }
    Object.defineProperty(CellNumberComponent.prototype, "cell", {
        set: function (item) {
            this._cell.value = item.value;
            this._cell.configuration = __assign({}, this.defaultConf, (item.configuration || {}));
            this.setStyles();
        },
        enumerable: true,
        configurable: true
    });
    CellNumberComponent.prototype.setStyles = function () {
        this.styles = this.configStyles.getStylesString(this._cell.configuration);
    };
    CellNumberComponent.prototype.routeCell = function () {
        if (this._cell.configuration.linkData.param) {
            return this._cell.configuration.linkData.routerLink + "/" + this._cell.configuration.linkData.param;
        }
        return this._cell.configuration.linkData.routerLink;
    };
    return CellNumberComponent;
}());
export { CellNumberComponent };
