// tslint:disable: no-empty
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms';

import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[fmx-select]',
  templateUrl: './fmx-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FmxSelectComponent),
      multi: true
    }
  ]
})
export class FmxSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  ALL_OPTION = { name: 'Tod@s', value: '' };
  optionAllValue: boolean;

  @Input() includeAll = true;
  @Input() label = '';
  @Input() required = false;
  @Input() placeholder = '';
  @Input() multiple = false;

  _list: any[];
  @Input()
  set list(value: any[]) {
    this.initPlaceholder();

    // tslint:disable-next-line: prefer-conditional-expression
    if (this.includeAll) {
      this._list = [this.ALL_OPTION, ...value];
    } else {
      this._list = value;
    }

    this.filteredList$.next(this._list);

    if (this.multiple && this.includeAll) {
      this.cmpControl.setValue([...this._list.map((item: any) => item.value)]);
    }
  }

  @Output() changeSelectedOption = new EventEmitter<any>();

  cmpControl = new FormControl();
  searchControl = new FormControl();

  filteredList$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private unsubscribe = new Subject();

  // tslint:disable-next-line: no-unused
  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnInit(): void {
    this.optionAllValue = this.includeAll;

    // set initial selection
    this.cmpControl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: any) => {
        this.onChange(value);
      });

    // listen for search field value changes
    this.searchControl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.filterList();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  filterList(): void {
    if (!this._list) {
      return;
    }
    // get the search keyword
    let search = this.searchControl.value;
    if (!search) {
      this.filteredList$.next(this._list);

      return;
    }

    search = this.normalizeStr(search);

    // Filter
    this.filteredList$.next(
      this._list.filter(
        (item: any) =>
          this.normalizeStr(item.name).indexOf(search) > -1 ||
          (item.element && item.element.codename
            ? this.normalizeStr(item.element.codename).indexOf(search) > -1
            : '')
      )
    );
  }

  initPlaceholder(): void {
    this.ALL_OPTION.name = this.placeholder === '' ? 'Tod@s' : this.placeholder;
  }

  writeValue(value: any): void {
    this.cmpControl.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.cmpControl.disable();
    } else {
      this.cmpControl.enable();
    }
  }

  changeAllOption(): void {
    this.optionAllValue = !this.optionAllValue;

    if (this.multiple) {
      if (this.optionAllValue) {
        this.cmpControl.setValue([
          ...this._list.map((item: any) => item.value)
        ]);
      } else {
        this.cmpControl.setValue([]);
      }
    }

    this.emitChangeSelectOption(this.cmpControl.value);
  }

  changeOption(): void {
    this.optionAllValue = false;

    const valueSelect = this.multiple
      ? this.cmpControl.value.filter((item: string) => item !== '')
      : this.cmpControl.value;

    this.cmpControl.setValue(valueSelect);
    this.emitChangeSelectOption(valueSelect);
  }

  private emitChangeSelectOption(valueSelect: any): void {
    this.changeSelectedOption.emit({ value: valueSelect });
  }

  private normalizeStr(value: string): string {
    return value
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
}
