var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { RechargesRepaysState } from '@app/fmx-shared/states/recharges-repays.state';
import { finalize } from 'rxjs/operators';
import { RechargesRepaysService } from './recharges-repays.service';
import * as i0 from "@angular/core";
import * as i1 from "../../states/recharges-repays.state";
import * as i2 from "./recharges-repays.service";
var RechargesRepaysFacadeService = /** @class */ (function () {
    function RechargesRepaysFacadeService(state, httpService) {
        this.state = state;
        this.httpService = httpService;
    }
    RechargesRepaysFacadeService.prototype.loadRechargesProviders = function () {
        var _this = this;
        var filter = this.state.get('filterRechargesRepaysSub');
        var pagination = this.state.get('paginationSub');
        this.state.set('loadingSub', true);
        this.httpService
            .getRechargesProviders(filter, pagination)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function (response) {
            _this.state.set('paginationSub', __assign({}, pagination, { current_page: response.meta.current_page, last_page: response.meta.last_page }));
            _this.state.set('rechargesRepaysListSub', response.data);
        });
    };
    RechargesRepaysFacadeService.prototype.loadRepaysClients = function () {
        var _this = this;
        var filter = this.state.get('filterRechargesRepaysSub');
        var pagination = this.state.get('paginationSub');
        this.state.set('loadingSub', true);
        this.httpService
            .getRepaysClients(filter, pagination)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function (response) {
            _this.state.set('paginationSub', __assign({}, pagination, { current_page: response.meta.current_page, last_page: response.meta.last_page }));
            _this.state.set('rechargesRepaysListSub', response.data);
        });
    };
    RechargesRepaysFacadeService.prototype.updateRechargeProvider = function (serviceId, rechargeId, params) {
        if (params === void 0) { params = {}; }
        return this.httpService.updateRechargeProvider(serviceId, rechargeId, params);
    };
    RechargesRepaysFacadeService.prototype.updateRepayClient = function (serviceId, repayId, params) {
        if (params === void 0) { params = {}; }
        return this.httpService.updateRepayClient(serviceId, repayId, params);
    };
    RechargesRepaysFacadeService.prototype.reset = function () {
        this.state.reset();
    };
    RechargesRepaysFacadeService.prototype.changePage = function (page) {
        var pagination = this.state.get('paginationSub');
        this.state.set('paginationSub', __assign({}, pagination, { page: page }));
    };
    RechargesRepaysFacadeService.prototype.changeFilter = function (filter) {
        this.state.set('filterRechargesRepaysSub', __assign({}, filter));
        var pagination = this.state.get('paginationSub');
        this.state.set('paginationSub', __assign({}, pagination));
    };
    RechargesRepaysFacadeService.ngInjectableDef = i0.defineInjectable({ factory: function RechargesRepaysFacadeService_Factory() { return new RechargesRepaysFacadeService(i0.inject(i1.RechargesRepaysState), i0.inject(i2.RechargesRepaysService)); }, token: RechargesRepaysFacadeService, providedIn: "root" });
    return RechargesRepaysFacadeService;
}());
export { RechargesRepaysFacadeService };
