var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { filterResponseByActive, responseToSelectOptions, toSelectOptions } from './../../../fmx-shared/utils/data-utils';
export var SERVICE_LOG_STATUS = {};
var DataService = /** @class */ (function () {
    function DataService(http) {
        this.http = http;
    }
    DataService.prototype.getSpecialties = function (paramToFilter) {
        var _this = this;
        var endpoint = 'data/specialties';
        var params = new HttpParams();
        if (paramToFilter) {
            params = params.set("" + paramToFilter, 'true');
        }
        return this.http.get(endpoint, { params: params }).pipe(map(toSelectOptions), tap(function (response) { return (_this.specialtiesAvailable = response); }));
    };
    DataService.prototype.getServicesTypes = function (specialtyId) {
        var endpoint = 'data/service_types';
        var params = new HttpParams().set('specialty', "" + specialtyId);
        return this.http.get(endpoint, { params: params }).pipe(map(toSelectOptions));
    };
    DataService.prototype.getImageKinds = function () {
        var endpoint = "data/image_kinds";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getStatuses = function (userRole) {
        var _this = this;
        var endPoint = 'data/statuses';
        var paramsEndPoint = new HttpParams();
        if (userRole) {
            paramsEndPoint = paramsEndPoint = paramsEndPoint.set('for', userRole);
        }
        return this.http.get(endPoint, { params: paramsEndPoint }).pipe(map(function (response) {
            var statuses = responseToSelectOptions(response.data, 'name', 'codename');
            _this.statusesByUser = statuses;
            return statuses;
        }));
    };
    DataService.prototype.getReasons = function () {
        var endpoint = 'data/reasons';
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getStreetTypes = function () {
        var endpoint = 'data/street_types';
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response.data, 'name', 'codename');
        }));
    };
    DataService.prototype.getDuePeriods = function () {
        var endpoint = 'data/due_periods';
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getCountries = function () {
        var endpoint = 'data/countries';
        var params = new HttpParams().set('sort_by', 'name');
        return this.http.get(endpoint, { params: params }).pipe(map(toSelectOptions));
    };
    DataService.prototype.getStates = function (countryId) {
        var endpoint = 'data/states';
        var params = new HttpParams()
            .set('sort_by', 'name')
            .set('country', countryId);
        return this.http.get(endpoint, { params: params }).pipe(map(toSelectOptions));
    };
    DataService.prototype.getAllStates = function () {
        var _this = this;
        return this.getCountries().pipe(mergeMap(function (response) {
            var observables = [];
            response.forEach(function (item) {
                return observables.push(_this.getStates("" + item.value));
            });
            return forkJoin(observables);
        }));
    };
    DataService.prototype.getOperatorLevels = function () {
        var endpoint = "data/operator_levels";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getUserLevels = function () {
        var endpoint = "data/levels";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getBatches = function () {
        var _this = this;
        var endpoint = 'data/batches';
        return this.http.get(endpoint).pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }), tap(function (response) { return (_this.batchesAvailable = response); }));
    };
    DataService.prototype.getServiceLogActions = function () {
        var endpoint = 'data/service_log_actions';
        return this.http.get(endpoint).pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }), tap(function (response) {
            response.map(function (item) {
                SERVICE_LOG_STATUS = __assign({}, SERVICE_LOG_STATUS, (_a = {}, _a["" + item.value] = item.name, _a));
                var _a;
            });
        }));
    };
    DataService.prototype.getReasonsServiceRejection = function (target) {
        var endpoint = "data/service_rejection_reasons?target=" + target;
        return this.http.get(endpoint).pipe(map(function (response) { return filterResponseByActive(response.data); }), map(function (response) { return responseToSelectOptions(response, 'name', 'id'); }));
    };
    DataService.prototype.getReasonsBudgetRejection = function () {
        var endpoint = 'data/budget_rejection_reasons';
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getDocumentKinds = function () {
        var endpoint = "data/document_kinds";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getActivityTypes = function () {
        var endpoint = "data/activity_types";
        return this.http.get(endpoint).pipe(map(toSelectOptions));
    };
    DataService.prototype.getApplianceTypes = function () {
        var endpoint = "data/appliance_types";
        return this.http.get(endpoint).pipe(map(toSelectOptions));
    };
    DataService.prototype.getZones = function () {
        var endpoint = "data/zones";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response.data, 'name', 'id');
        }));
    };
    DataService.prototype.getSizes = function () {
        var endpoint = "data/company_aggrupation_sizes";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response, 'name', 'codename');
        }));
    };
    DataService.prototype.getDeletionCauses = function () {
        var endpoint = "data/deletion_causes";
        return this.http
            .get(endpoint)
            .pipe(map(function (response) {
            return responseToSelectOptions(response.data, 'name', 'codename');
        }));
    };
    DataService.prototype.getComplaintKinds = function () {
        var endpoint = 'data/complaint_kinds';
        return this.http
            .get(endpoint)
            .pipe(map(function (data) { return responseToSelectOptions(data, 'name', 'codename'); }));
    };
    DataService.prototype.getSeveritiesComplaints = function () {
        var endpoint = 'data/complaint_severities';
        return this.http
            .get(endpoint)
            .pipe(map(function (data) { return responseToSelectOptions(data, 'name', 'codename'); }));
    };
    DataService.prototype.getComplaintsStatuses = function () {
        var endPoint = 'data/complaint_statuses';
        return this.http
            .get(endPoint)
            .pipe(map(function (data) { return responseToSelectOptions(data, 'name', 'codename'); }));
    };
    DataService.prototype.getComplaintProviderStatuses = function () {
        var endPoint = 'data/complaint_provider_statuses';
        return (this.http
            .get(endPoint)
            .pipe(map(function (data) {
            return responseToSelectOptions(data, 'name', 'codename');
        })));
    };
    return DataService;
}());
export { DataService };
