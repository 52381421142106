import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

// Interfaces
import { IColumn } from './../../interfaces/column';
import { ConfigurationService } from './../../services/configuration.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[fmx-table-column]',
  templateUrl: './column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnComponent {
  styles: string;
  order: string; // asc | desc

  _column: any = {};
  @Input()
  set column(item: IColumn) {
    this._column = item;
    this.setStyles();
  }

  constructor(private configStyles: ConfigurationService) {}

  setStyles() {
    this.styles = this.configStyles.getColumnStylesString(
      this._column.configuration
    );
  }
}
