import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ZonesService {
  constructor(private http: HttpClient) {}

  createZone(data: any): Observable<any> {
    const endpoint = 'zones';

    return this.http.post(endpoint, data);
  }

  updateZone(idZone: string, data: any): Observable<any> {
    const endpoint = `zones/${idZone}`;

    return this.http.patch(endpoint, data);
  }

  deleteZone(idZone: string): Observable<any> {
    const endpoint = `zones/${idZone}`;

    return this.http.delete(endpoint);
  }

  getZones(sort: any, pagination: any): Observable<any> {
    const endpoint = `zones`;

    const params = {
      ...sort,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getSpacesWithoutZones(sort: any, pagination: any): Observable<any> {
    const endpoint = `zones`;

    const params = {
      ...sort,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }
}
