// tslint:disable: no-void-expression
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
// tslint:disable-next-line:max-line-length
import { ServiceDetailFacadeService } from '@app/features/service-detail/shared-service-detail/services/service-detail-facade/service-detail-facade.service';
import { ConfirmDialogComponent } from '@app/fmx-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { NewRepayRechargeModalComponent } from '../new-repay-recharge-modal/new-repay-recharge-modal.component';

// Extends Class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
import { PermitsService } from '@app/core/services/permits/permits.service';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

@Component({
  selector: 'fmx-service-repay-recharge-item',
  templateUrl: './service-repay-recharge-item.component.html'
})
export class ServiceRepayRechargeItemComponent extends BaseCurrentUserComponent {
  @Input() title: string;
  @Input() object: any;
  @Input() isRepay: boolean;

  // Modals
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private facade: ServiceDetailFacadeService,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  updateObject() {
    const dialogRef = this.dialog.open(NewRepayRechargeModalComponent, {
      width: getResolutionModal(),
      data: {
        title: this.title,
        isRepay: this.isRepay,
        objectEdit: this.object
      }
    });

    dialogRef.afterClosed().subscribe((modalData: any) => {
      if (!modalData.isDirty) {
        this.facade.loadRepays();
        this.facade.loadRecharges();
      }
    });
  }

  openConfirmDelete() {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: getResolutionModal(),
      data: {
        title: 'Confirmación',
        content: `¿Seguro que desea eliminar el ${this.title} seleccionado?`
      }
    });

    this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.object.type === 'repays'
          ? this.facade.deleteRepay(this.object.id)
          : this.facade.deleteRecharge(this.object.id);
      }
    });
  }
}
