var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Operator } from '@app/features/configuration/containers/operators/models/operator.model';
// Utils
import { generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';
import { clone } from 'underscore';
var OperatorsService = /** @class */ (function () {
    function OperatorsService(http) {
        this.http = http;
    }
    OperatorsService.prototype.createOperator = function (data) {
        var endpoint = 'operators';
        return this.http.post(endpoint, data);
    };
    OperatorsService.prototype.updateOperator = function (idOperator, data) {
        var endpoint = "operators/" + idOperator;
        return this.http.patch(endpoint, data);
    };
    OperatorsService.prototype.getOperatorsByPage = function (page, search, operatorIdToInsert) {
        var _this = this;
        if (search === void 0) { search = ''; }
        var params = new HttpParams()
            .set('sort_by', 'first_name')
            .set('sort_dir', 'asc')
            .set('page', page.toString());
        if (search) {
            params = params.append('name', search);
        }
        var endpoint = 'operators';
        return this.http.get(endpoint, { params: params }).pipe(map(generateResponseDataPage), tap(function (response) { return (_this.listOperatorsFull = clone(response)); }), map(function () {
            var dataResponse = clone(_this.listOperatorsFull);
            dataResponse.data = _this.listOperatorsFull.data.filter(function (operator) { return operator.element.attributes.visible; });
            if (operatorIdToInsert) {
                operatorIdToInsert.forEach(function (id) {
                    if (!dataResponse.data.some(function (item) { return item.element.id === id.toString(); })) {
                        var findOperatorToInsert = _this.listOperatorsFull.data.find(function (item) { return item.element.id === id.toString(); });
                        if (findOperatorToInsert) {
                            dataResponse.data.push(findOperatorToInsert);
                        }
                    }
                });
            }
            return dataResponse;
        }));
    };
    OperatorsService.prototype.getOperatorsByPages = function (filter, sort, pagination) {
        var endpoint = "operators";
        var params = __assign({}, filter, sort, pagination);
        return this.http.get(endpoint, { params: params });
    };
    OperatorsService.prototype.getOperator = function (operatorId) {
        var endpoint = "operators/" + operatorId;
        return this.http
            .get(endpoint)
            .pipe(map(function (item) { return new Operator(item.data); }));
    };
    return OperatorsService;
}());
export { OperatorsService };
