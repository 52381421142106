/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/loader-modal/loader-modal.component.ngfactory";
import * as i3 from "./shared/components/loader-modal/loader-modal.component";
import * as i4 from "./fmx-shared/components/fmx-header/fmx-header.component.ngfactory";
import * as i5 from "./fmx-shared/components/fmx-header/fmx-header.component";
import * as i6 from "@angular/router";
import * as i7 from "./core/services/loading/loading.service";
import * as i8 from "./core/services/auth/auth.service";
import * as i9 from "./core/services/current-user/current-user.facade.service";
import * as i10 from "./core/services/permits/permits.service";
import * as i11 from "./fmx-shared/components/fmx-footer/fmx-footer.component.ngfactory";
import * as i12 from "./fmx-shared/components/fmx-footer/fmx-footer.component";
import * as i13 from "./core/services/device/device.service";
import * as i14 from "@angular/common";
import * as i15 from "./app.component";
import * as i16 from "@ngx-translate/core";
import * as i17 from "./core/services/firebase/firebase.service";
import * as i18 from "./core/services/script-loader/script-loader.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader-modal", [], null, null, null, i2.View_LoaderModalComponent_0, i2.RenderType_LoaderModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoaderModalComponent, [], null, null)], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fmx-header", [], null, null, null, i4.View_FmxHeaderComponent_0, i4.RenderType_FmxHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i5.FmxHeaderComponent, [i6.Router, i7.LoadingService, i8.AuthService, i9.CurrentUserFacadeService, i10.PermitsService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "fmx-footer", [], null, null, null, i11.View_FmxFooterComponent_0, i11.RenderType_FmxFooterComponent)), i1.ɵdid(6, 114688, null, 0, i12.FmxFooterComponent, [i13.DeviceService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); var currVal_0 = _co.isLoading; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i15.AppComponent, [i8.AuthService, i16.TranslateService, i7.LoadingService, i17.FirebaseService, i6.Router, i18.ScriptLoaderService, i9.CurrentUserFacadeService, i10.PermitsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
