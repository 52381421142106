import { State } from './state';
import { StoreModel } from './store-model';

// Featues
import { ConfigurationInitialState } from './features/configuration/configuration.state';
import { GlobalInitialState } from './features/global/global.state';
import {
  ProviderServicesInitialState,
  AssignedServicesInitialState,
  CommonServicesTableInitialState,
  CommonServicesPageInitialState,
  CommonServicesDataInitialState
} from './features/provider-services/provider-services.state';

export const InitialState: State = {
  loading: new StoreModel<boolean>(false),
  configurationData: new StoreModel(ConfigurationInitialState),
  globalData: new StoreModel(GlobalInitialState),
  providerServicesData: new StoreModel(ProviderServicesInitialState),
  // Assigned Services
  assignedServicesState: new StoreModel(AssignedServicesInitialState),
  // Corrective
  correctiveServicesTable: new StoreModel(CommonServicesTableInitialState),
  correctiveServicesPage: new StoreModel(CommonServicesPageInitialState),
  correctiveServicesData: new StoreModel(CommonServicesDataInitialState),
  // Preventive
  preventiveServicesTable: new StoreModel(CommonServicesTableInitialState),
  preventiveServicesPage: new StoreModel(CommonServicesPageInitialState),
  preventiveServicesData: new StoreModel(CommonServicesDataInitialState),
  // BudgetToAccept
  budgetToAcceptServicesTable: new StoreModel(CommonServicesTableInitialState),
  budgetToAcceptServicesPage: new StoreModel(CommonServicesPageInitialState),
  budgetToAcceptServicesData: new StoreModel(CommonServicesDataInitialState),
  // Frozen
  frozenServicesTable: new StoreModel(CommonServicesTableInitialState),
  frozenServicesPage: new StoreModel(CommonServicesPageInitialState),
  frozenServicesData: new StoreModel(CommonServicesDataInitialState),
  // Finished Services
  finishedServicesTable: new StoreModel(CommonServicesTableInitialState),
  finishedServicesPage: new StoreModel(CommonServicesPageInitialState),
  finishedServicesData: new StoreModel(CommonServicesDataInitialState),
  // Issues Services
  issuesServicesTable: new StoreModel(CommonServicesTableInitialState),
  issuesServicesPage: new StoreModel(CommonServicesPageInitialState),
  issuesServicesData: new StoreModel(CommonServicesDataInitialState),
  // Operator Services
  operatorServicesState: new StoreModel(AssignedServicesInitialState),
  // - Mass Services
  operatorMassServicesTable: new StoreModel(CommonServicesTableInitialState),
  operatorMassServicesPage: new StoreModel(CommonServicesPageInitialState),
  operatorMassServicesData: new StoreModel(CommonServicesDataInitialState),
  // - PendingBudget
  operatorPendingBudgetServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  operatorPendingBudgetServicesPage: new StoreModel(
    CommonServicesPageInitialState
  ),
  operatorPendingBudgetServicesData: new StoreModel(
    CommonServicesDataInitialState
  ),
  // - Preventive
  operatorPreventiveServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  operatorPreventiveServicesPage: new StoreModel(
    CommonServicesPageInitialState
  ),
  operatorPreventiveServicesData: new StoreModel(
    CommonServicesDataInitialState
  ),
  // - Home Services
  operatorHomeServicesTable: new StoreModel(CommonServicesTableInitialState),
  operatorHomeServicesPage: new StoreModel(CommonServicesPageInitialState),
  operatorHomeServicesData: new StoreModel(CommonServicesDataInitialState),
  // - Frozen
  operatorFrozenServicesTable: new StoreModel(CommonServicesTableInitialState),
  operatorFrozenServicesPage: new StoreModel(CommonServicesPageInitialState),
  operatorFrozenServicesData: new StoreModel(CommonServicesDataInitialState),
  // - Issues
  operatorIssuesServicesTable: new StoreModel(CommonServicesTableInitialState),
  operatorIssuesServicesPage: new StoreModel(CommonServicesPageInitialState),
  operatorIssuesServicesData: new StoreModel(CommonServicesDataInitialState),
  // -Finished Services
  operatorFinishedServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  operatorFinishedServicesPage: new StoreModel(CommonServicesPageInitialState),
  operatorFinishedServicesData: new StoreModel(CommonServicesDataInitialState),
  // - Authorization Pending
  operatorAuthorizationPendingServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  operatorAuthorizationPendingServicesPage: new StoreModel(
    CommonServicesPageInitialState
  ),
  operatorAuthorizationPendingServicesData: new StoreModel(
    CommonServicesDataInitialState
  ),
  // - Operator dashboard tasks
  operatorTasksServicesTable: new StoreModel(CommonServicesTableInitialState),
  operatorTasksServicesPage: new StoreModel(CommonServicesPageInitialState),
  operatorTasksServicesData: new StoreModel(CommonServicesDataInitialState),

  // - Operator dashboard tasks
  operatorIssuesTasksTable: new StoreModel(CommonServicesTableInitialState),
  operatorIssuesTasksPage: new StoreModel(CommonServicesPageInitialState),
  operatorIssuesTasksData: new StoreModel(CommonServicesDataInitialState),

  // Client Services
  clientServicesState: new StoreModel(AssignedServicesInitialState),
  // - Corrective
  clientCorrectiveServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  clientCorrectiveServicesPage: new StoreModel(CommonServicesPageInitialState),
  clientCorrectiveServicesData: new StoreModel(CommonServicesDataInitialState),
  // - Preventive
  clientPreventiveServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  clientPreventiveServicesPage: new StoreModel(CommonServicesPageInitialState),
  clientPreventiveServicesData: new StoreModel(CommonServicesDataInitialState),
  // - PendingBudget
  clientPendingBudgetServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  clientPendingBudgetServicesPage: new StoreModel(
    CommonServicesPageInitialState
  ),
  clientPendingBudgetServicesData: new StoreModel(
    CommonServicesDataInitialState
  ),
  // - AuthorizationPending
  clientAuthorizationPendingServicesTable: new StoreModel(
    CommonServicesTableInitialState
  ),
  clientAuthorizationPendingServicesPage: new StoreModel(
    CommonServicesPageInitialState
  ),
  clientAuthorizationPendingServicesData: new StoreModel(
    CommonServicesDataInitialState
  ),
  // - finalized
  clientFinalizedServicesTable: new StoreModel(CommonServicesTableInitialState),
  clientFinalizedServicesPage: new StoreModel(CommonServicesPageInitialState),
  clientFinalizedServicesData: new StoreModel(CommonServicesDataInitialState)
};
