import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { expand, map, takeWhile } from 'rxjs/operators';

import { SelectOption } from '@app/core/models/select-option.model';
import { toSelectOptions } from '@app/fmx-shared/utils/data-utils';

@Injectable()
export class SpacesService {
  expandObservable = true;

  constructor(private http: HttpClient) {}

  getSpace(idSpace: string): Observable<any> {
    const endpoint = `spaces/${idSpace}?include=zone`;

    return this.http.get(endpoint);
  }

  updateSpace(idSpace: string, data: any): Observable<any> {
    const endpoint = `spaces/${idSpace}`;

    return this.http.put(endpoint, data);
  }

  getFiscalInfoSpace(idSpace: string): Observable<any> {
    const endpoint = `spaces/${idSpace}/fiscal_info?include=state.country`;

    return this.http.get(endpoint);
  }

  getUsersSpace(idSpace: string): Observable<SelectOption[]> {
    const endpoint = `spaces/${idSpace}/users`;

    return this.http.get(endpoint).pipe(map(toSelectOptions));
  }

  getUsersOfSpace(idSpace: string): Observable<any> {
    const endpoint = `spaces/${idSpace}/users`;

    return this.http.get(endpoint);
  }

  getVinculationsUsers(idUser: string): Observable<any> {
    const endpoint = `users/${idUser}/user_vinculations`;

    return this.http.get(endpoint);
  }

  addUserSpace(idSpace: string, data: any): Observable<any> {
    const endpoint = `spaces/${idSpace}/users`;

    return this.http.post(endpoint, data);
  }

  getAppliancesSpace(idSpace: string): Observable<any> {
    const endpoint = `spaces/${idSpace}/appliances`;

    return this.http.get(endpoint);
  }

  getAddressSpace(idSpace: string): Observable<any> {
    const endpoint = `spaces/${idSpace}/address?include=state.country`;

    return this.http.get(endpoint);
  }

  getSpaceServices(
    idSpace: string,
    onlyFirstPage: boolean = false
  ): Observable<any[]> {
    const request$ = this._getRequest(idSpace);

    if (onlyFirstPage) {
      return request$;
    }

    return request$.pipe(
      expand((response: any) => {
        const currentPage =
          response.meta.last_page === 1
            ? 1
            : +response.meta.current_page + 1 || 1;

        return this._getRequest(idSpace, currentPage);
      }),
      takeWhile(() => this.expandObservable),
      map((response: any) => response)
    );
  }

  createService(idSpace: string, params: any): Observable<any> {
    const endpoint = `spaces/${idSpace}/services`;

    return this.http.post(endpoint, params);
  }

  // tslint:disable-next-line: function-name
  private _getRequest(
    idSpace: string,
    currentPage: number = 1
  ): Observable<any> {
    const status = [
      'authorization_pending',
      'provider_assignment_pending',
      'user_contact_pending',
      'first_visit_pending',
      'budget_pending',
      'budget_review_pending',
      'acceptance_budget_pending',
      'finalizing_pending',
      'documentation_pending',
      'review_pending',
      'completed',
      'rejected',
      'on_hold'
    ];
    let params = new HttpParams();

    status.forEach((key: string) => {
      params = params.append('status[]', key);
    });
    params = params.append('page', currentPage.toString());

    const endpoint = `spaces/${idSpace}/services`;

    return this.http.get(endpoint, { params });
  }
}
