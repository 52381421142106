var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
// tslint:disable: no-void-expression
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
// tslint:disable-next-line:max-line-length
import { ServiceDetailFacadeService } from '@app/features/service-detail/shared-service-detail/services/service-detail-facade/service-detail-facade.service';
// Utils
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';
var NewRepayRechargeModalComponent = /** @class */ (function () {
    function NewRepayRechargeModalComponent(dialogRef, data, fb, facade, fmxToastr) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.facade = facade;
        this.fmxToastr = fmxToastr;
    }
    NewRepayRechargeModalComponent.prototype.ngOnInit = function () {
        this.title = (this.data && this.data.title) || null;
        this.isRepay = this.data.isRepay;
        this.objectEdit = (this.data && this.data.objectEdit) || null;
        this.initForm();
    };
    NewRepayRechargeModalComponent.prototype.close = function (isDirty) {
        if (isDirty === void 0) { isDirty = true; }
        this.dialogRef.close({ isDirty: isDirty });
    };
    NewRepayRechargeModalComponent.prototype.createOrUpdateObject = function () {
        this.objectEdit
            ? this.updateRepayOrRecharge()
            : this.createRepayOrRecharge();
    };
    NewRepayRechargeModalComponent.prototype.createRepayOrRecharge = function () {
        var _this = this;
        if (this.isRepay) {
            var params = { repay: __assign({}, this.form.value) };
            this.facade.createRepay(params).subscribe(function () {
                _this.showSuccessToast('Repago realizado');
                _this.close(false);
            }, function (error) {
                var errors = error.error.errors;
                checkErrors(errors, _this.form);
            });
        }
        else {
            var params = { recharge: __assign({}, this.form.value) };
            this.facade.createRecharges(params).subscribe(function () {
                _this.showSuccessToast('Recobro realizado');
                _this.close(false);
            }, function (error) {
                var errors = error.error.errors;
                checkErrors(errors, _this.form);
            });
        }
    };
    NewRepayRechargeModalComponent.prototype.updateRepayOrRecharge = function () {
        var _this = this;
        if (this.isRepay) {
            var params = { repay: __assign({}, this.form.value) };
            this.facade.updateRepay(this.objectEdit.id, params).subscribe(function () {
                _this.showSuccessToast('Repago actualizado');
                _this.close(false);
            }, function (error) {
                var errors = error.error.errors;
                checkErrors(errors, _this.form);
            });
        }
        else {
            var params = { recharge: __assign({}, this.form.value) };
            this.facade.updateRecharge(this.objectEdit.id, params).subscribe(function () {
                _this.showSuccessToast('Recobro actualizado');
                _this.close(false);
            }, function (error) {
                var errors = error.error.errors;
                checkErrors(errors, _this.form);
            });
        }
    };
    NewRepayRechargeModalComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            amount: [null],
            description: [null]
        });
        if (this.objectEdit) {
            this.fillForm();
        }
    };
    NewRepayRechargeModalComponent.prototype.fillForm = function () {
        this.form.get('amount').setValue(this.objectEdit.attributes.amount);
        this.form
            .get('description')
            .setValue(this.objectEdit.attributes.description);
    };
    NewRepayRechargeModalComponent.prototype.showSuccessToast = function (msg) {
        this.fmxToastr.success(msg);
    };
    return NewRepayRechargeModalComponent;
}());
export { NewRepayRechargeModalComponent };
