import { Pipe, PipeTransform } from '@angular/core';

import { SERVICE_STATUSSES } from './../../constants/service-statusses.const';

@Pipe({
  name: 'serviceStatus'
})
export class ServiceStatusPipe implements PipeTransform {
  transform(value: string): string {
    return SERVICE_STATUSSES[value] || 'Estado del servicio no conocido';
  }
}
