var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ConfigurationService } from './../../../services/configuration.service';
var CellPriceComponent = /** @class */ (function () {
    function CellPriceComponent(configStyles) {
        this.configStyles = configStyles;
        this._cell = {};
        this.defaultConf = {
            bold: true,
            align: 'left',
            color: 'primary',
            width: 'medium'
        };
    }
    Object.defineProperty(CellPriceComponent.prototype, "cell", {
        set: function (item) {
            this._cell.value = item.value;
            this._cell.configuration = __assign({}, this.defaultConf, (item.configuration || {}));
            this.setStyles();
        },
        enumerable: true,
        configurable: true
    });
    CellPriceComponent.prototype.setStyles = function () {
        this.styles = this.configStyles.getStylesString(this._cell.configuration);
    };
    return CellPriceComponent;
}());
export { CellPriceComponent };
