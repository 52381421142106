var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
export function prepareBudget(response) {
    var data = response.data ? response.data : response;
    var items = response.budget_items
        ? response.budget_items
        : response.included;
    return {
        assessment: __assign({}, data.attributes, { id: data.id }),
        items: items
    };
}
export function getBudgetFormat(attributes, assessmentItems) {
    return {
        data: {
            id: attributes.id,
            attributes: __assign({}, attributes)
        },
        included: assessmentItems.slice()
    };
}
