var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MatDialog } from '@angular/material';
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
// tslint:disable-next-line:max-line-length
import { ServiceDetailFacadeService } from '@app/features/service-detail/shared-service-detail/services/service-detail-facade/service-detail-facade.service';
import { ConfirmDialogComponent } from '@app/fmx-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { NewRepayRechargeModalComponent } from '../new-repay-recharge-modal/new-repay-recharge-modal.component';
// Extends Class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
import { PermitsService } from '@app/core/services/permits/permits.service';
// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';
var ServiceRepayRechargeItemComponent = /** @class */ (function (_super) {
    __extends(ServiceRepayRechargeItemComponent, _super);
    function ServiceRepayRechargeItemComponent(dialog, facade, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.dialog = dialog;
        _this.facade = facade;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        return _this;
    }
    ServiceRepayRechargeItemComponent.prototype.updateObject = function () {
        var _this = this;
        var dialogRef = this.dialog.open(NewRepayRechargeModalComponent, {
            width: getResolutionModal(),
            data: {
                title: this.title,
                isRepay: this.isRepay,
                objectEdit: this.object
            }
        });
        dialogRef.afterClosed().subscribe(function (modalData) {
            if (!modalData.isDirty) {
                _this.facade.loadRepays();
                _this.facade.loadRecharges();
            }
        });
    };
    ServiceRepayRechargeItemComponent.prototype.openConfirmDelete = function () {
        var _this = this;
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: getResolutionModal(),
            data: {
                title: 'Confirmación',
                content: "\u00BFSeguro que desea eliminar el " + this.title + " seleccionado?"
            }
        });
        this.confirmDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.object.type === 'repays'
                    ? _this.facade.deleteRepay(_this.object.id)
                    : _this.facade.deleteRecharge(_this.object.id);
            }
        });
    };
    return ServiceRepayRechargeItemComponent;
}(BaseCurrentUserComponent));
export { ServiceRepayRechargeItemComponent };
