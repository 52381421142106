import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

// Utils
import { getResolutionModal } from '@app/fmx-shared/utils/resolution-modals';

// Modals
import { FmxUploadExcelMassiveModalComponent } from '../fmx-upload-excel-massive-modal/fmx-upload-excel-massive-modal.component';

@Component({
  selector: 'fmx-upload-excel-massive',
  templateUrl: './fmx-upload-excel-massive.component.html'
})
export class FmxUploadExcelMassiveComponent {
  @Input() typeUpload: string;
  @Input() groupId = null;

  get getNameButton(): string {
    return this.typeUpload === 'services'
      ? 'Importar servicios'
      : 'Importar espacios';
  }

  constructor(private dialog: MatDialog) {}

  openUploadModal() {
    this.dialog.open(FmxUploadExcelMassiveModalComponent, {
      width: getResolutionModal(),
      data: {
        typeUpload: this.typeUpload,
        title: this.getNameButton,
        groupId: this.groupId
      }
    });
  }
}
