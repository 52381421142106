import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { MatSelectModule } from '@angular/material';

// Components
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FmxSelectInfiniteComponent } from './components/fmx-select-infinite/fmx-select-infinite.component';

const MODULES = [
  MatSelectModule,
  FormsModule,
  ReactiveFormsModule,
  NgxMatSelectSearchModule
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  declarations: [FmxSelectInfiniteComponent],
  exports: [FmxSelectInfiniteComponent]
})
export class FmxSelectInfiniteModule {}
