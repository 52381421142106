import { SelectOption } from '@app/core/models/select-option.model';

export const listSelectToggle: SelectOption[] = [
  {
    value: 'true',
    name: 'Si'
  },
  {
    value: 'false',
    name: 'No'
  }
];
