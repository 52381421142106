var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { environment } from '@env/environment';
import { UserService } from '../user/user.service';
// tslint:disable:no-var-requires
// tslint:disable:no-require-imports
var firebase = require('firebase/app');
require('firebase/auth');
require('firebase/analytics');
var FirebaseService = /** @class */ (function () {
    function FirebaseService(userService) {
        this.userService = userService;
    }
    FirebaseService.prototype.initApp = function () {
        firebase.initializeApp(environment.firebase);
    };
    FirebaseService.prototype.logEvent = function (action, metaData) {
        if (this.isProduction()) {
            var params = __assign({}, this.getMetadataUser(), metaData);
            firebase.analytics().logEvent(action, params);
        }
    };
    FirebaseService.prototype.setUser = function (uid, metaData) {
        if (this.isProduction()) {
            firebase.analytics().setUserId(uid);
            firebase.analytics().setUserProperties(metaData);
        }
    };
    FirebaseService.prototype.getMetadataUser = function () {
        var user = this.userService.getUserStore();
        return user ? { user_id: user.id, user_role: user.type } : '';
    };
    FirebaseService.prototype.isProduction = function () {
        return environment.production;
    };
    return FirebaseService;
}());
export { FirebaseService };
