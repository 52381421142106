import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ICell } from '../../interfaces/cell';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[fmx-table-cell]',
  templateUrl: './cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellComponent {
  @Input() cell: ICell;
  @Input() editMode: boolean;
  @Input() control: any;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClickCell = new EventEmitter();

  clickCell() {
    if (this.cell.configuration && this.cell.configuration.linkable) {
      this.onClickCell.emit(this.cell.configuration.linkData || null);
    }
  }

  changeToggle(valueToggle: any) {
    const params = {
      ...this.cell.configuration.linkData,
      checked: valueToggle
    };

    this.onClickCell.emit(params);
  }
}
