import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingStatus'
})
export class BillingStatusPipe implements PipeTransform {
  transform(value: string): string {
    return value ? 'Formalizada' : 'Borrador';
  }
}
