/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader-modal.component";
var styles_LoaderModalComponent = [i0.styles];
var RenderType_LoaderModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderModalComponent, data: {} });
export { RenderType_LoaderModalComponent as RenderType_LoaderModalComponent };
export function View_LoaderModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-body loader-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "71px"], ["version", "1.1"], ["viewBox", "0 0 78 71"], ["width", "78px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, ":svg:linearGradient", [["id", "linearGradient"], ["x1", "50%"], ["x2", "85.917653%"], ["y1", "24.8912197%"], ["y2", "140.13446%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:stop", [["offset", "0%"], ["stop-color", "#F56558"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:stop", [["offset", "100%"], ["stop-color", "#D62C4B"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"], ["id", "Isotipo_pulido"], ["stroke", "none"], ["stroke-width", "1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:polyline", [["class", "logo-line-svg"], ["id", "Path-11"], ["points", "6.02136345 69.9915246 6.02136345 34.5493722 17.2800007 26.8832012 24.850578 32.1940124 24.850578 65.004297 44.835525 65.004297 44.835525 26.4283472 62.40589 14.5004645 71.8374037 21.1221919 71.8374037 69.9915246"], ["stroke", "#E94F41"], ["stroke-width", "10"]], null, null, null, null, null))], null, null); }
export function View_LoaderModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader-modal", [], null, null, null, View_LoaderModalComponent_0, RenderType_LoaderModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoaderModalComponent, [], null, null)], null, null); }
var LoaderModalComponentNgFactory = i1.ɵccf("app-loader-modal", i2.LoaderModalComponent, View_LoaderModalComponent_Host_0, {}, {}, []);
export { LoaderModalComponentNgFactory as LoaderModalComponentNgFactory };
