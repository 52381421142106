var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
import { Store } from '@app/store';
import { map, tap } from 'rxjs/operators';
var OperatorDataService = /** @class */ (function () {
    function OperatorDataService(http, store) {
        this.http = http;
        this.store = store;
    }
    OperatorDataService.prototype.getProviders = function (status) {
        var _this = this;
        if (status === void 0) { status = null; }
        var params = new HttpParams()
            .set('sort_by', 'name')
            .set('minimum_api', 'true')
            .set('per_page', ELEMENTS_PER_PAGE);
        if (status) {
            status.forEach(function (key) {
                params = params.append('status[]', key);
            });
        }
        var endpoint = 'providers';
        return this.http.get(endpoint, { params: params }).pipe(tap(function (response) {
            _this.saveStore(response, 'providers');
        }), map(function (response) {
            return response.data.map(function (provider) {
                return {
                    value: provider.id,
                    name: provider.attributes.name
                };
            });
        }));
    };
    OperatorDataService.prototype.getCompanyAggrupations = function () {
        var _this = this;
        var params = new HttpParams()
            .set('per_page', '600')
            .set('sort_by', 'name')
            .set('minimum_api', 'true');
        var endpoint = 'company_aggrupations';
        return this.http.get(endpoint, { params: params }).pipe(tap(function (response) {
            _this.saveStore(response, 'companyAggrupations');
        }), map(function (response) {
            return response.data.map(function (companyAggrupation) {
                return {
                    value: companyAggrupation.id,
                    name: companyAggrupation.attributes.name
                };
            });
        }));
    };
    OperatorDataService.prototype.saveStore = function (response, nameStore) {
        var entities = response.data.reduce(function (ent, data) {
            return __assign({}, ent, (_a = {}, _a[data.id] = data, _a));
            var _a;
        }, {});
        var options = response.data.map(function (option) {
            return {
                name: option.attributes.name,
                value: +option.id
            };
        });
        var newState = (_a = {},
            _a['globalData'] = (_b = {},
                _b[nameStore] = options,
                _b[nameStore + "Entities"] = entities,
                _b),
            _a);
        this.store.set(newState);
        var _a, _b;
    };
    return OperatorDataService;
}());
export { OperatorDataService };
