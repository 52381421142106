import {
  Component,
  Input,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import * as moment from 'moment';

// NguaCarousel
import { NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';

// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { DashboardFacadeService } from '@app/features/dashboard/services/dashboard-facade.service';

// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';

const DEFAULT_CONFIG: NguCarouselConfig = {
  grid: {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 4,
    all: 0
  },
  slide: 4,
  speed: 250,
  point: {
    visible: false
  },
  load: 4,
  touch: true,
  loop: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)'
};

export const ACTIONS_CARROUSEL_CARDS = {
  edit_user: 'openActionsUsers',
  open_space: 'openSpaceDetail'
};

@Component({
  selector: 'fmx-carrousel-cards',
  templateUrl: './fmx-carrousel-cards.component.html'
})
export class FmxCarrouselCardsComponent extends BaseCurrentUserComponent {
  @Output() doAction = new EventEmitter<any>();

  @Input() items: any;
  @Input() typeCards: string;
  @Input() typeActionMove: string;
  @Input()
  set config(value: Partial<NguCarouselConfig>) {
    this.carouselConfig = {
      ...this.carouselConfig,
      ...value
    };
  }
  streetTypes: any;

  carouselConfig: NguCarouselConfig = DEFAULT_CONFIG;

  constructor(
    private facade: DashboardFacadeService,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.items.currentValue &&
      changes.items.currentValue.length &&
      !changes.items.currentValue.some((item: any) => item.name === undefined)
    ) {
      this.actionMove();
    }
  }

  goToSpace(spaceId: number) {
    const data = {
      payload: ACTIONS_CARROUSEL_CARDS.open_space,
      data: { spaceId }
    };
    this.doAction.emit(data);
  }

  timeBetweenDates(startDate: any, slaExpiresDate: any): number {
    const start = moment(startDate);
    const end = moment(slaExpiresDate);

    return end.diff(start, 'hours');
  }

  actionMove(data: NguCarouselStore = null) {
    switch (this.typeActionMove) {
      case 'loadSpaceInfo':
        this.getSpaceInfo(data);
        break;
      default:
    }
  }

  sendActionUser(user: any) {
    const data = {
      payload: ACTIONS_CARROUSEL_CARDS.edit_user,
      data: { user }
    };
    this.doAction.emit(data);
  }

  hasRelationNodeProperty(item: any) {
    return item.attributes.hasOwnProperty('relatedThisNode');
  }

  private getSpaceInfo(data: NguCarouselStore) {
    let firstElement = 0;
    let lastElement = this.items.length <= 2 ? this.items.length - 1 : 2;

    if (data) {
      const itemsPerPage = data.items;
      const activePage = data.currentSlide / itemsPerPage;
      firstElement = activePage * itemsPerPage;
      lastElement = firstElement + (itemsPerPage - 1);
    }

    // tslint:disable-next-line:no-increment-decrement
    for (let i = firstElement; i <= lastElement; i++) {
      this.facade.loadSpaceInfo(this.items[i].id).subscribe((response: any) => {
        const humanAddress = {
          human_address: response.data.attributes.human_address
        };
        const internalCode = {
          internal_code: response.data.attributes.internal_code
        };
        this.items[i] = {
          ...this.items[i],
          ...humanAddress,
          ...internalCode
        };
      });
    }
  }
}
