var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
// tslint:disable: no-void-expression
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { RechargesRepaysFacadeService } from '@app/fmx-shared/services/recharges-repays/recharges-repays-facade.service';
// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';
var FmxEditRechargesRepaysModalComponent = /** @class */ (function (_super) {
    __extends(FmxEditRechargesRepaysModalComponent, _super);
    function FmxEditRechargesRepaysModalComponent(dialogRef, fb, facade, toastr, currentUserFacade, permitsService, data) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.dialogRef = dialogRef;
        _this.fb = fb;
        _this.facade = facade;
        _this.toastr = toastr;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.data = data;
        return _this;
    }
    FmxEditRechargesRepaysModalComponent.prototype.ngOnInit = function () {
        this.object = (this.data && this.data.object) || null;
        this.title = (this.data && this.data.title) || null;
        this.isRecharge = (this.data && this.data.isRecharge) || null;
        this.createForm();
    };
    FmxEditRechargesRepaysModalComponent.prototype.success = function () {
        this.emit(true);
    };
    FmxEditRechargesRepaysModalComponent.prototype.cancel = function () {
        this.emit(false);
    };
    FmxEditRechargesRepaysModalComponent.prototype.emit = function (result) {
        this.dialogRef.close(result);
    };
    Object.defineProperty(FmxEditRechargesRepaysModalComponent.prototype, "attributes", {
        get: function () {
            return this.object.attributes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FmxEditRechargesRepaysModalComponent.prototype, "copyButton", {
        get: function () {
            return "Actualizar " + (this.isRecharge ? 'recargo' : 'repago');
        },
        enumerable: true,
        configurable: true
    });
    FmxEditRechargesRepaysModalComponent.prototype.updateRechargeOrRepay = function () {
        this.isRecharge ? this.updateRechargeProvider() : this.updateRepayClients();
    };
    FmxEditRechargesRepaysModalComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            service_id: [{ value: this.attributes.service_id, disabled: true }],
            amount: [Number(this.attributes.amount).toFixed(2)],
            description: [this.attributes.description]
        });
    };
    FmxEditRechargesRepaysModalComponent.prototype.updateRechargeProvider = function () {
        var _this = this;
        var params = { recharge: __assign({}, this.form.value) };
        this.facade
            .updateRechargeProvider(this.attributes.service_id, this.object.id, params)
            .subscribe(function () {
            _this.toastSuccess('Recargo');
        }, function (error) {
            var errors = error.error.errors;
            checkErrors(errors, _this.form);
        });
    };
    FmxEditRechargesRepaysModalComponent.prototype.updateRepayClients = function () {
        var _this = this;
        var params = { repay: __assign({}, this.form.value) };
        this.facade
            .updateRepayClient(this.attributes.service_id, this.object.id, params)
            .subscribe(function () {
            _this.toastSuccess('Repago');
        }, function (error) {
            var errors = error.error.errors;
            checkErrors(errors, _this.form);
        });
    };
    FmxEditRechargesRepaysModalComponent.prototype.toastSuccess = function (msg) {
        this.toastr.success(msg + " editado");
        this.success();
    };
    return FmxEditRechargesRepaysModalComponent;
}(BaseCurrentUserComponent));
export { FmxEditRechargesRepaysModalComponent };
