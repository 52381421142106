import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// const
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';

// Models
import { DataPage } from '@app/fmx-shared/interfaces/data-page.interface';
import { generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';

@Injectable()
export class ServicesService {
  constructor(private http: HttpClient) {}

  getServices(params?: any): Observable<any> {
    const endpoint = `services`;

    return this.http.get(endpoint, { params });
  }

  getService(serviceId: string): Observable<any> {
    const endpoint = `services/${serviceId}`;

    return this.http.get(endpoint);
  }

  updateService(serviceId: number, data: any): Observable<any> {
    const endpoint = `services/${serviceId}`;

    return this.http.put(endpoint, data);
  }

  uploadAttachmentService(serviceId: string, data: any): Observable<any> {
    const endpoint = `services/${serviceId}/file_attachments`;

    return this.http.post(endpoint, data);
  }

  getBillableServices(filter: any, pagination: any): Observable<any> {
    const endpoint = `services`;

    const params = {
      invoiceable: true,
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params }).pipe(
      map((response: any) => {
        // tslint:disable-next-line:underscore-consistent-invocation
        const orderData = _.groupBy(
          response.data,
          (item: any) => item.attributes.invoice_fiscal_name
        );

        response.data = orderData;

        return response;
      })
    );
  }

  getRecommendedProviders(
    serviceId: string,
    page: number,
    search: string = ''
  ): Observable<DataPage> {
    let params = new HttpParams()
      .set('per_page', ELEMENTS_PER_PAGE)
      .set('page', page.toString());

    if (search) {
      params = params.append('name', search);
    }

    const endpoint = `services/${serviceId}/recommended_providers`;

    return this.http
      .get(endpoint, { params })
      .pipe(map(generateResponseDataPage));
  }

  getServicesToXls(params: any): Observable<any> {
    const endpoint = 'services.xlsx';

    return this.http.get(endpoint, { params, responseType: 'blob' });
  }
}
