import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guard
import { InitGuard } from './core/guards/init.guard';

// Component
import { InitComponent } from './init.component';

const routes: Routes = [
  {
    path: 'init',
    component: InitComponent,
    canActivate: [InitGuard]
  },
  {
    path: 'dashboard/providersInvoices',
    redirectTo: 'billing/receipts'
  },
  {
    path: 'users',
    loadChildren: 'app/users/users.module#UsersModule'
  },
  {
    path: 'configuration',
    loadChildren:
      'app/features/configuration/configuration.module#ConfigurationModule'
  },
  {
    path: 'dashboard-fmx',
    loadChildren: 'app/features/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'provider-services',
    loadChildren:
      'app/features/provider-services/provider-services.module#ProviderServicesModule'
  },
  {
    path: 'operator-services',
    loadChildren:
      'app/features/operator-services/operator-services.module#OperatorServicesModule'
  },
  {
    path: 'client-services',
    loadChildren:
      'app/features/client-services/client-services.module#ClientServicesModule'
  },
  {
    path: 'services',
    loadChildren: 'app/features/services/services.module#ServicesModule'
  },
  {
    path: 'service-detail',
    loadChildren:
      'app/features/service-detail/service-detail.module#ServiceDetailModule'
  },
  {
    path: 'complaints',
    loadChildren: 'app/features/complaints/complaints.module#ComplaintsModule'
  },
  {
    path: 'profile',
    loadChildren: 'app/features/profile/profile.module#ProfileModule'
  },
  {
    path: 'invoices',
    loadChildren: 'app/features/invoices/invoices.module#InvoicesModule'
  },
  {
    path: 'billing',
    loadChildren:
      'app/fmx-modules/fmx-billing/fmx-billing.module#FmxBillingModule'
  },
  {
    path: 'recharges',
    loadChildren: 'app/features/recharges/recharges.module#RechargesModule'
  },
  {
    path: 'repays',
    loadChildren: 'app/features/repays/repays.module#RepaysModule'
  },
  {
    path: 'email_confirmation/:confirmToken',
    loadChildren: 'app/confirm-email/confirm-email.module#ConfirmEmailModule'
  },
  {
    path: '',
    redirectTo: 'init',
    pathMatch: 'full'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '404'
  },
  {
    path: '404',
    pathMatch: 'full',
    loadChildren: 'app/not-found/not-found.module#NotFoundModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
