import { Pipe, PipeTransform } from '@angular/core';

import { BUDGET_STATUSSES } from './../../constants/budget-statusses.const';

@Pipe({
  name: 'budgetStatus'
})
export class BudgetStatusPipe implements PipeTransform {
  transform(value: string): string {
    return BUDGET_STATUSSES[value];
  }
}
