<h1 mat-dialog-title>{{ data.title }}</h1>
<form [formGroup]="form" *ngIf="form" class="fmx-form">
  <mat-dialog-content>
    <div class="row">
      <div
        *ngIf="data.list"
        fmx-select
        class="col-12 p-b-20"
        [list]="data.list"
        label="{{ data.selectLabel }}"
        (changeSelectedOption)="markInputCommentsAsRequired($event)"
        formControlName="list"
      ></div>

      <div *ngIf="data.showTextarea" class="col-12 fmx-form-group">
        <label
          >Comentario
          <span *ngIf="isRequired" class="required-asterisk">*</span></label
        >
        <textarea
          matInput
          formControlName="comments"
          placeholder="COMENTARIO"
        ></textarea>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="fmx-btn fmx-btn-clear fmx-btn-primary font-weight-bold"
      (click)="close()"
    >
      CANCELAR
    </button>

    <button
      class="fmx-btn fmx-btn-red font-weight-bold"
      (click)="success()"
      [disabled]="!form.valid"
    >
      {{ data.confirmButton }}
    </button>
  </mat-dialog-actions>
</form>
