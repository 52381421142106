import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
var FmxInputEmailAddListComponent = /** @class */ (function () {
    function FmxInputEmailAddListComponent(fb) {
        this.fb = fb;
        this.addEmailEmit = new EventEmitter();
        this.required = true;
        this.listEmails = [];
        this.showIconEnter = false;
    }
    FmxInputEmailAddListComponent.prototype.ngOnChanges = function (changes) {
        if (changes &&
            changes.addEmailRecommended &&
            changes.addEmailRecommended.currentValue) {
            this.pushOnListEmail(changes.addEmailRecommended.currentValue);
            this.emitChanges();
        }
    };
    FmxInputEmailAddListComponent.prototype.ngOnInit = function () {
        this.initForm();
        if (this.emailsByDefault) {
            (_a = this.listEmails).push.apply(_a, this.emailsByDefault);
            this.emitChanges();
        }
        var _a;
    };
    FmxInputEmailAddListComponent.prototype.addEmail = function () {
        if (!this.emailHasError()) {
            this.pushOnListEmail(this.form.controls.email.value);
            this.form.reset();
            this.emitChanges();
        }
    };
    FmxInputEmailAddListComponent.prototype.deleteEmail = function (email) {
        _.remove(this.listEmails, function (item) { return item === email; });
        this.emitChanges();
    };
    FmxInputEmailAddListComponent.prototype.checkValidateEmail = function () {
        this.showIconEnter = this.emailHasError() ? false : true;
    };
    FmxInputEmailAddListComponent.prototype.pushOnListEmail = function (email) {
        if (!this.listEmails.includes(email)) {
            this.listEmails.push(email);
        }
    };
    FmxInputEmailAddListComponent.prototype.emitChanges = function () {
        this.addEmailEmit.emit(this.listEmails);
    };
    FmxInputEmailAddListComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email]]
        });
    };
    FmxInputEmailAddListComponent.prototype.emailHasError = function () {
        return (this.form.controls.email.hasError('required') ||
            this.form.controls.email.hasError('email'));
    };
    return FmxInputEmailAddListComponent;
}());
export { FmxInputEmailAddListComponent };
