var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { AuthService } from '@app/core/services/auth/auth.service';
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { LoadingService } from '@app/core/services/loading/loading.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
// Const
import { INITIAL_PAGE } from '@app/core/const/initial-pages.const';
// Environment
import { environment } from 'environments/environment';
var FmxHeaderComponent = /** @class */ (function (_super) {
    __extends(FmxHeaderComponent, _super);
    function FmxHeaderComponent(router, loadingService, authService, currentUserFacade, permitsService) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.router = router;
        _this.loadingService = loadingService;
        _this.authService = authService;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.showMobileMenu = false;
        _this.showPointPendingComplaints = false;
        return _this;
    }
    FmxHeaderComponent.prototype.ngOnInit = function () {
        this.showPointPendingComplaints =
            this.isProvider() &&
                this.currentUser.attributes.complaints_status !== 'none';
    };
    FmxHeaderComponent.prototype.ngOnDestroy = function () {
        this.showMobileMenu = false;
    };
    FmxHeaderComponent.prototype.toggleMobileMenu = function () {
        this.showMobileMenu = !this.showMobileMenu;
    };
    FmxHeaderComponent.prototype.logout = function () {
        var _this = this;
        this.loadingService.showLoading();
        this.authService.logout().subscribe(function () {
            _this.router.navigateByUrl(INITIAL_PAGE.login);
            _this.loadingService.hideLoading();
        });
    };
    FmxHeaderComponent.prototype.goProfile = function () {
        var url = this.getProfileLink();
        this.router.navigate(url);
    };
    FmxHeaderComponent.prototype.goToNewService = function () {
        var url = this.isUserHomeService()
            ? '/services/new-home-service'
            : '/services/new-service';
        this.router.navigate([url]);
    };
    FmxHeaderComponent.prototype.getProfileLink = function () {
        if (this.isProvider()) {
            return ["/dashboard/profile-doer", this.currentUser.id];
        }
        if (this.isClient()) {
            return ["/dashboard/profile"];
        }
        return null;
    };
    FmxHeaderComponent.prototype.showBannerEnvironmentTest = function () {
        return !environment.production;
    };
    return FmxHeaderComponent;
}(BaseCurrentUserComponent));
export { FmxHeaderComponent };
