import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from '../user/user.service';
// tslint:disable:no-var-requires
// tslint:disable:no-require-imports
const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/analytics');

@Injectable()
export class FirebaseService {
  constructor(private userService: UserService) {}

  initApp() {
    firebase.initializeApp(environment.firebase);
  }

  logEvent(action: string, metaData: any) {
    if (this.isProduction()) {
      const params = { ...this.getMetadataUser(), ...metaData };
      firebase.analytics().logEvent(action, params);
    }
  }

  setUser(uid: string, metaData: any) {
    if (this.isProduction()) {
      firebase.analytics().setUserId(uid);
      firebase.analytics().setUserProperties(metaData);
    }
  }

  private getMetadataUser() {
    const user = this.userService.getUserStore();

    return user ? { user_id: user.id, user_role: user.type } : '';
  }

  private isProduction(): boolean {
    return environment.production;
  }
}
