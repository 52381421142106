import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Components
import { CellBooleanComponent } from './components/cell-types/boolean/cell-boolean.component';
import { CellDateComponent } from './components/cell-types/date/cell-date.component';
import { CellDoneComponent } from './components/cell-types/done/cell-done.component';
import { CellIconComponent } from './components/cell-types/icon/cell-icon.component';
import { CellNumberComponent } from './components/cell-types/number/cell-number.component';
import { CellPriceComponent } from './components/cell-types/price/cell-price.component';
import { CellTextComponent } from './components/cell-types/text/cell-text.component';
import { CellToggleComponent } from './components/cell-types/toggle/cell-toggle.component';
import { CellComponent } from './components/cell/cell.component';
import { ColumnComponent } from './components/column/column.component';
import { EditableCellCheckboxComponent } from './components/editable-cell-types/editable-cell-checkbox';
import { EditableCellNumberComponent } from './components/editable-cell-types/editable-cell-number';
import { EditableCellPriceComponent } from './components/editable-cell-types/editable-cell-price';
import { EditableCellSelectComponent } from './components/editable-cell-types/editable-cell-select';
import { EditableCellTextComponent } from './components/editable-cell-types/editable-cell-text';
import { EditableCellTextareaComponent } from './components/editable-cell-types/editable-cell-textarea';
import { GhostLoaderComponent } from './components/ghost-loader/ghost-loader.component';
import { RowComponent } from './components/row/row.component';
import { TableComponent } from './components/table/table.component';

// Services
import { ConfigurationService } from './services/configuration.service';

// Pipes
import { LocalDatePipe } from './pipes/local-date.pipe';

// 3rd party libraries
import { MatSlideToggleModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

// Dialogs
import { ConfirmDialogComponent } from './components/dialogs/confirm/confirm-dialog.component';

const COMPONENTS = [
  TableComponent,
  ColumnComponent,
  RowComponent,
  CellComponent,
  CellNumberComponent,
  CellIconComponent,
  CellTextComponent,
  CellDoneComponent,
  CellBooleanComponent,
  EditableCellTextComponent,
  EditableCellTextareaComponent,
  EditableCellSelectComponent,
  EditableCellNumberComponent,
  EditableCellPriceComponent,
  EditableCellCheckboxComponent,
  GhostLoaderComponent,
  CellPriceComponent,
  CellDateComponent,
  CellToggleComponent,
  ConfirmDialogComponent
];

const MODULES = [
  VirtualScrollerModule,
  ContentLoaderModule,
  MatIconModule,
  CommonModule,
  ReactiveFormsModule,
  RouterModule,
  MatSelectModule,
  MatInputModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSlideToggleModule
];

const PIPES = [LocalDatePipe];

const DIALOGS = [ConfirmDialogComponent];

const SERVICES = [ConfigurationService];
@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  imports: [...MODULES],
  exports: [TableComponent, ...PIPES],
  providers: [...SERVICES],
  entryComponents: [...DIALOGS]
})
export class FmxTableModule {}
