export interface ProviderServicesState {
  serviceList: any[];
  serviceListPaginationMeta: any;
  tableShow: boolean;
  tableLoading: boolean;
  filtersState: any;
  filterLoading: boolean;
}

export const ProviderServicesInitialState: ProviderServicesState = {
  serviceList: null,
  serviceListPaginationMeta: null,
  tableShow: true,
  tableLoading: true,
  filtersState: null,
  filterLoading: false
};

export interface AssignedServicesState {
  filterState: any;
}

export const AssignedServicesInitialState: AssignedServicesState = {
  filterState: null
};
export interface CommonServicesTableState {
  filterState: any;
  paginationState: any;
  sortState: any;
}
export const CommonServicesTableInitialState: CommonServicesTableState = {
  filterState: null,
  paginationState: null,
  sortState: null
};

export interface CommonServicesPageState {
  loading: boolean;
  loaded: boolean;
  paginationMeta: any;
}

export const CommonServicesPageInitialState: CommonServicesPageState = {
  loading: false,
  loaded: false,
  paginationMeta: null
};

export interface CommonServicesDataState {
  data: any[];
}

export const CommonServicesDataInitialState: CommonServicesDataState = {
  data: []
};
