import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ICell } from './../../../interfaces/cell';
import { ICellConfiguration } from './../../../interfaces/cell-configuration';

import { ConfigurationService } from './../../../services/configuration.service';

@Component({
  selector: 'fmx-cell-number',
  templateUrl: './cell-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellNumberComponent {
  styles: string;
  _cell: any = {};

  @Input()
  set cell(item: ICell) {
    this._cell.value = item.value;
    this._cell.configuration = {
      ...this.defaultConf,
      ...(item.configuration || {})
    };
    this.setStyles();
  }

  defaultConf: ICellConfiguration = {
    withDot: false,
    bold: false,
    align: 'left',
    color: 'primary',
    width: 'medium'
  };

  constructor(private configStyles: ConfigurationService) {}

  setStyles() {
    this.styles = this.configStyles.getStylesString(this._cell.configuration);
  }

  routeCell(): string {
    if (this._cell.configuration.linkData.param) {
      return `${this._cell.configuration.linkData.routerLink}/${this._cell.configuration.linkData.param}`;
    }

    return this._cell.configuration.linkData.routerLink;
  }
}
