<mat-dialog-content>
  <div class="flex-width flex-h-center m-b-20 no-padding">
    <span class="color-secondary font-weight-bold">{{ title }}</span>
  </div>
  <form [formGroup]="form" *ngIf="form" class="fmx-form">
    <div class="row p-l-5">
      <div class="col-6 fmx-form-group">
        <label>Referencia</label>
        <input
          type="number"
          matInput
          formControlError
          formControlName="service_id"
        />
      </div>
      <div class="col-6 fmx-form-group">
        <label>Importe</label>
        <input
          type="number"
          matInput
          formControlError
          formControlName="amount"
        />
      </div>
      <div class="col-12 fmx-form-group">
        <label>Descripción</label>
        <textarea
          matInput
          formControlError
          formControlName="description"
          placeholder="DETALLES DEL NUEVO PAGO"
        ></textarea>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-width flex-h-space-between m-t-20 no-padding">
    <button
      class="fmx-btn fmx-btn-clear fmx-btn-secondary font-weight-bold"
      (click)="cancel()"
    >
      CANCELAR
    </button>
    <button
      class="fmx-btn fmx-btn-red font-weight-bold text-uppercase"
      *ngIf="isOperatorAdminOrFinance()"
      (click)="updateRechargeOrRepay()"
    >
      {{ copyButton }}
    </button>
  </div>
</mat-dialog-actions>
