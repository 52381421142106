import * as moment from 'moment';

export function formattedDate(
  date: string,
  format: string = 'DD/MM/YYYY'
): string {
  const momentDate = moment(date);

  return momentDate.isValid() ? momentDate.format(format) : '';
}
