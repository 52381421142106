import { Injectable } from '@angular/core';

import { ICellConfiguration } from '../interfaces/cell-configuration';
import { IColumn } from '../interfaces/column';
import { IColumnConfiguration } from '../interfaces/column-configuration';

@Injectable()
export class ConfigurationService {
  align = {
    left: 'left-align',
    right: 'right-align',
    center: 'center-align'
  };

  width = {
    small: 'small-width',
    medium: 'medium-width',
    large: 'large-width'
  };

  color = {
    success: 'success-color',
    danger: 'danger-color',
    warning: 'warning-color',
    primary: 'primary-color',
    secondary: 'secondary-color'
  };

  text = {
    nowrap: 'no-wrap'
  };

  LINKABLE_KLASS = 'linkable';
  WITH_DOT = 'with-dot';

  COLUMN_DEFAULT_CONF: IColumnConfiguration = {
    align: 'left',
    color: 'secondary',
    sortable: false,
    sortableKey: null,
    direction: null
  };

  // Columns Methods

  parseColumn(column: IColumn): IColumn {
    const conf = {
      ...this.COLUMN_DEFAULT_CONF,
      ...(column.configuration || {})
    };

    return {
      name: column.name,
      configuration: conf
    };
  }

  setColumnDirection(column: IColumn) {
    const direction = column.configuration.direction;
    if (direction && direction === 'asc') {
      return 'desc';
    }

    return 'asc';
  }

  // Other Methods

  getStylesString(configuration: ICellConfiguration, isLinkModal?: boolean) {
    const styles = [];

    if (configuration.bold) {
      styles.push('bold');
    }
    styles.push(this.align[configuration.align]);
    styles.push(this.width[configuration.width]);
    styles.push(this.color[configuration.color]);
    styles.push(this.text[configuration.text]);

    if (configuration.linkable || isLinkModal) {
      styles.push(this.LINKABLE_KLASS);
    }

    if (configuration.withDot) {
      styles.push(this.WITH_DOT);
    }

    return styles.join(' ');
  }

  getColumnStylesString(configuration: IColumnConfiguration) {
    const styles = [];

    if (configuration.sortable) {
      styles.push('sortable');
    }

    styles.push(this.align[configuration.align]);
    styles.push(this.color[configuration.color]);

    return styles.join(' ');
  }
}
