<mat-dialog-content>
  <h3 class="text-center font-18 font-weight-bold p-y-20">
    Resolver Reclamación
  </h3>

  <form [formGroup]="form" class="fmx-form">
    <div class="row">
      <div class="col-12">
        <div
          fmx-select
          formControlError
          [list]="complaintProviderStatus"
          label="Estado"
          formControlName="provider_status"
          [required]="true"
        ></div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 fmx-form-group">
        <label>Comentario</label>
        <textarea
          matInput
          formControlError
          formControlName="provider_comment"
          placeholder="Comentario"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <button
          class="fmx-btn fmx-btn-clear text-uppercase fmx-btn-primary fmx-btn-bold font-14"
          (click)="closeModal()"
        >
          Cancelar
        </button>
      </div>
      <div class="col-12 col-sm-6">
        <button
          class="fmx-btn fmx-btn-green fmx-btn-block text-uppercase fmx-btn-bold font-14"
          (click)="markAsCompleteComplaint()"
        >
          Marcar como resuelta
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
