import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentAdditionalWorkColorStatus'
})
export class PaymentAdditionalWorkColorStatusPipe implements PipeTransform {
  status = {
    paid: 'budget-selected',
    unpaid: 'budget-created',
    created: 'budget-created',
    completed: 'budget-selected',
    canceled: 'budget-rejected',
    expired: 'budget-rejected',
    error: 'budget-rejected'
  };

  // tslint:disable-next-line: no-unused
  transform(value: string, args?: any): string {
    return this.status[value] as string;
  }
}
