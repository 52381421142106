import { Injectable } from '@angular/core';
import { RechargesRepaysState } from '@app/fmx-shared/states/recharges-repays.state';
import { finalize } from 'rxjs/operators';
import { RechargesRepaysService } from './recharges-repays.service';

@Injectable({
  providedIn: 'root'
})
export class RechargesRepaysFacadeService {
  constructor(
    private state: RechargesRepaysState,
    private httpService: RechargesRepaysService
  ) {}

  loadRechargesProviders() {
    const filter = this.state.get('filterRechargesRepaysSub');
    const pagination = this.state.get('paginationSub');

    this.state.set('loadingSub', true);

    this.httpService
      .getRechargesProviders(filter, pagination)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe((response: any) => {
        this.state.set('paginationSub', {
          ...pagination,
          current_page: response.meta.current_page,
          last_page: response.meta.last_page
        });

        this.state.set('rechargesRepaysListSub', response.data);
      });
  }

  loadRepaysClients() {
    const filter = this.state.get('filterRechargesRepaysSub');
    const pagination = this.state.get('paginationSub');

    this.state.set('loadingSub', true);

    this.httpService
      .getRepaysClients(filter, pagination)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe((response: any) => {
        this.state.set('paginationSub', {
          ...pagination,
          current_page: response.meta.current_page,
          last_page: response.meta.last_page
        });

        this.state.set('rechargesRepaysListSub', response.data);
      });
  }

  updateRechargeProvider(
    serviceId: number,
    rechargeId: number,
    params: any = {}
  ) {
    return this.httpService.updateRechargeProvider(
      serviceId,
      rechargeId,
      params
    );
  }

  updateRepayClient(serviceId: number, repayId: number, params: any = {}) {
    return this.httpService.updateRepayClient(serviceId, repayId, params);
  }

  reset() {
    this.state.reset();
  }

  changePage(page: number) {
    const pagination = this.state.get('paginationSub');
    this.state.set('paginationSub', {
      ...pagination,
      page
    });
  }

  changeFilter(filter: any) {
    this.state.set('filterRechargesRepaysSub', { ...filter });

    const pagination = this.state.get('paginationSub');
    this.state.set('paginationSub', { ...pagination });
  }
}
