import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class FmxToastr {
  defaultOptions = {
    toastClass: 'toast fmx-toast',
    positionClass: 'toast-top-center'
  };

  constructor(private toastr: ToastrService) {}

  success(message: string, title: string = '') {
    this.toastr.success(title, message, this.defaultOptions);
  }

  error(message: string, title: string = '') {
    this.toastr.error(title, message, this.defaultOptions);
  }
}
