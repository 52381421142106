const ScriptType = {
  text: 'text/javascript',
  module: 'module'
};

export interface DynamicScript {
  src: {
    value: string;
    // tslint:disable-next-line: no-reserved-keywords
    scriptType: string;
    idTag: string;
  };
  parentTag: {
    value: string;
  };
}

export const dynamicScriptList: { [id: string]: DynamicScript } = {
  Hotjar: {
    src: {
      value: `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3089628,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `,
      scriptType: ScriptType.text,
      idTag: 'hotjar'
    },
    parentTag: {
      value: 'head'
    }
  }
};
