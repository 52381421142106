import { Pipe, PipeTransform } from '@angular/core';
import { SERVICE_LOG_STATUS } from '@app/core/services/data/data.service';

@Pipe({
  name: 'serviceLogStatus'
})
export class ServiceLogStatusPipe implements PipeTransform {
  transform(value: string): string {
    return SERVICE_LOG_STATUS[value] || 'Estado no conocido';
  }
}
