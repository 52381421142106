import { BillingType } from '../const/billing-type.const';

export class Billing {
  id: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  attributes: AttributesBilling;
  relationships?: {
    services?: Datum[];
    receipt?: Datum;
    invoice?: Datum;
    provider?: Datum;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(that: any) {
    this.id = that.id;
    this.type = that.type;
    this.attributes = that.attributes;
    this.relationships = that.relationships;
  }

  getId(): string {
    return this.id;
  }

  getIdToUpload(): string {
    if (this.isAmendmentInvoice()) {
      return this.relationships.invoice.data.id;
    }

    if (this.isAmendmentReceipt()) {
      return this.relationships.receipt.data.id;
    }

    return this.id;
  }

  hasAmendment(): boolean {
    if (this.isReceipt()) {
      return (
        !!this.attributes.amendment_receipt_id &&
        typeof this.attributes.amendment_receipt_id !== undefined
      );
    }

    if (this.isInvoice()) {
      return (
        !!this.attributes.amendment_invoice_id &&
        typeof this.attributes.amendment_invoice_id !== undefined
      );
    }

    if (this.isQuotaInvoice()) {
      return (
        !!this.attributes.amendment_quota_invoice_id &&
        typeof this.attributes.amendment_quota_invoice_id !== undefined
      );
    }

    return false;
  }

  code(): string {
    return this.attributes.reference;
  }

  isAmendment(): boolean {
    return (
      this.isAmendmentReceipt() ||
      this.isAmendmentInvoice() ||
      this.isAmendmentQuotaInvoice()
    );
  }

  isAcquittance(): boolean {
    return this.type === BillingType.acquittances;
  }

  isAmendmentReceipt(): boolean {
    return this.type === BillingType.amendmentReceipts;
  }

  isReceipt(): boolean {
    return this.type === BillingType.receipts;
  }

  isInvoice(): boolean {
    return this.type === BillingType.invoices;
  }

  isAmendmentInvoice(): boolean {
    return this.type === BillingType.amendmentInvoices;
  }

  isQuotaInvoice(): boolean {
    return this.type === BillingType.quotaInvoices;
  }

  isAmendmentQuotaInvoice(): boolean {
    return this.type === BillingType.amendmentQuotaInvoices;
  }

  isStatusDraft(): boolean {
    return !this.attributes.navision_id;
  }

  getReferenceBilling(): string {
    return (
      this.attributes.amendment_invoice_reference ||
      this.attributes.amendment_receipt_reference ||
      this.attributes.amendment_quota_invoice_reference ||
      this.attributes.invoice_reference ||
      this.attributes.receipt_reference ||
      this.attributes.quota_invoice_reference
    );
  }
}

export interface AttributesBilling {
  amendment_receipt_id?: null;
  amendment_invoice_id?: null;
  amendment_quota_invoice_id?: null;
  amendment_invoice_reference?: null;
  amendment_receipt_reference: null;
  amendment_quota_invoice_reference?: null;
  invoice_reference: null;
  receipt_reference: null;
  quota_invoice_reference: null;
  comment: string;
  complete_fiscal_data: boolean;
  custom_reference: string;
  custom_total_price: string;
  due_at: string;
  from_date?: string;
  issued_at: string;
  issuer_city: string;
  issuer_name: string;
  issuer_nif: string;
  issuer_state: string;
  issuer_country: string;
  issuer_street_address: string;
  issuer_zip_code: string;
  navision_id: string;
  original_recharges_amount?: string;
  original_reference?: string;
  original_subtotal_price: string;
  original_tax_amount: string;
  original_tax_base: string;
  original_total_price: string;
  original_withholding_amount: string;
  original_zero_base: string;
  receiver_city: string;
  receiver_name: string;
  receiver_nif: string;
  receiver_state: string;
  receiver_country: string;
  receiver_street_address: string;
  receiver_zip_code: string;
  recommended_recipients: string[];
  recharges_amount: string;
  reference: string;
  sending_confirmed?: boolean;
  manually_sent_to: string[];
  subtotal_price: string;
  tax_amount: string;
  tax_name?: string;
  tax_percentage?: string;
  tax_base: string;
  to: string[];
  to_date?: string;
  total_price: string;
  withholding_amount: string;
  zero_base: string;
  withholding_percentage?: string;
}

interface Datum {
  data: {
    id: string;
    // tslint:disable-next-line: no-reserved-keywords
    type: string;
  };
}
