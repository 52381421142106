import { Injectable } from '@angular/core';
import { CurrentUser } from '@app/core/models/current-user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CurrentUserService {
  _currentUser$ = new BehaviorSubject<CurrentUser>(null);

  set currentUser(value: CurrentUser) {
    this._currentUser$.next(value);
  }

  get currentUser(): CurrentUser {
    return this._currentUser$.getValue();
  }
}
