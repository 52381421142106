import { NgModule } from '@angular/core';

import {
  MatInputModule,
  MatMenuModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatExpansionModule,
  MatChipsModule,
  MatTabsModule,
  MatCardModule,
  MatTooltipModule,
  MatListModule,
  MatProgressBarModule,
  MatTreeModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatSelectionList,
  MatAutocompleteModule
} from '@angular/material';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    NgbDropdownModule,
    MatExpansionModule,
    MatChipsModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatTreeModule,
    MatMenuModule,
    MatAutocompleteModule
  ],
  exports: [
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatExpansionModule,
    NgbDropdownModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    MatProgressBarModule,
    MatMenuModule,
    MatAutocompleteModule
  ],
  providers: [MatSelectionList]
})
export class MaterialModule {}
