import { IRow } from '@app/fmx-modules/fmx-table/interfaces/row';

export function getTableHeaders() {
  return [
    {
      name: 'Ref.',
      configuration: {
        color: 'primary'
      }
    },
    {
      name: 'Servicio',
      configuration: {
        color: 'primary'
      }
    },
    {
      name: 'Cliente',
      configuration: {
        color: 'primary'
      }
    },
    {
      name: 'Creación',
      configuration: {
        color: 'primary'
      }
    },
    {
      name: 'Valor',
      configuration: {
        color: 'primary'
      }
    },
    {
      name: 'Pendiente',
      configuration: {
        color: 'primary'
      }
    }
  ];
}

export function getTableRows(collection: any[]): IRow[] {
  const rows: IRow[] = [];

  if (collection) {
    collection.forEach((item: any) => {
      rows.push({
        id: item.id,
        editMode: false,
        cells: parseRow(item),
        showActions: {
          edit: false,
          delete: false
        }
      });
    });
  }

  return rows;
}

function parseRow(item: any) {
  // tslint:disable-next-line:no-unnecessary-local-variable
  const cells = [
    {
      value: item.attributes.service_id,
      type: 'number',
      configuration: {
        color: 'secondary',
        titleCell: 'Ref.',
        linkable: true,
        linkData: {
          param: item.attributes.service_id,
          routerLink: '/service-detail/'
        }
      }
    },
    {
      value: item.attributes.description,
      type: 'text',
      configuration: {
        color: 'secondary',
        titleCell: 'Servicio',
        linkable: true,
        linkData: {
          object: item,
          openModal: true
        }
      }
    },
    {
      value: item.attributes.service_provider || item.attributes.service_client,
      type: 'text',
      configuration: {
        bold: true,
        color: 'primary',
        titleCell: 'Cliente',
        linkable: true,
        linkData: {
          object: item,
          openModal: true
        }
      }
    },
    {
      value: item.attributes.created_at,
      type: 'date',
      configuration: {
        color: 'secondary',
        titleCell: 'Creación',
        fullDate: true,
        linkable: true,
        linkData: {
          object: item,
          openModal: true
        }
      }
    },
    {
      value: item.attributes.amount,
      type: 'price',
      configuration: {
        color: 'secondary',
        titleCell: 'Valor',
        linkable: true,
        linkData: {
          object: item,
          openModal: true
        }
      }
    },
    {
      value: item.attributes.pending,
      type: 'boolean',
      configuration: {
        color: 'secondary',
        titleCell: 'Pendiente',
        linkable: true,
        linkData: {
          object: item,
          openModal: true
        }
      }
    }
  ];

  // tslint:disable-next-line:no-var-before-return
  return cells;
}
