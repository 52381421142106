import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Service
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { ImportFileMassiveService } from './import-file-massive.service';

@Injectable({ providedIn: 'root' })
export class ImportFileMassiveFacadeService {
  constructor(
    private httpService: ImportFileMassiveService,
    private toastr: FmxToastr
  ) {}

  sendFileMassive(typeUpload: string, params: any = {}, groupId: any = null) {
    this.getRouteSendFile(typeUpload, params, groupId).subscribe(
      () =>
        this.toastr.success(
          'Importación en curso, será notificado por email al finalizar la importación'
        ),
      () =>
        this.toastr.error(
          'Error en la importación, por favor pruebe en unos minutos.'
        )
    );
  }

  private getRouteSendFile(
    typeUpload: string,
    params: any = {},
    groupId: any = null
  ): Observable<any> {
    if (typeUpload === 'services') {
      return this.httpService.sendFileServiceMassive(params);
    }

    return this.httpService.sendFileSpacesMassive(groupId, params);
  }
}
