import { BehaviorSubject } from 'rxjs';
var CurrentUserService = /** @class */ (function () {
    function CurrentUserService() {
        this._currentUser$ = new BehaviorSubject(null);
    }
    Object.defineProperty(CurrentUserService.prototype, "currentUser", {
        get: function () {
            return this._currentUser$.getValue();
        },
        set: function (value) {
            this._currentUser$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    return CurrentUserService;
}());
export { CurrentUserService };
