import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportFileMassiveService {
  constructor(private http: HttpClient) {}

  sendFileServiceMassive(params: any = {}): Observable<any> {
    const endpoint = 'services/batches';

    return this.http.post(endpoint, params);
  }

  sendFileSpacesMassive(groupId: number, params: any = {}): Observable<any> {
    const endpoint = `groups/${groupId}/spaces/batches`;

    return this.http.post(endpoint, params);
  }
}
