import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

// Components
import { BaseCurrentUserComponent } from './core/extends/base-current-user.component';
import { AuthService } from './core/services/auth/auth.service';

// Services
import { CurrentUserFacadeService } from './core/services/current-user/current-user.facade.service';
import { FirebaseService } from './core/services/firebase/firebase.service';
import { LoadingService } from './core/services/loading/loading.service';
import { PermitsService } from './core/services/permits/permits.service';
import { ScriptLoaderService } from './core/services/script-loader/script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseCurrentUserComponent implements OnInit {
  title = 'Famaex';

  isLoading = false;
  currentSelectNode: any;
  curruntValueText: any;

  currentUrl: any;

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private firebaseService: FirebaseService,
    private router: Router,
    private scriptLoaderService: ScriptLoaderService,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  ngOnInit() {
    this.translate.setDefaultLang('es');
    this.translate.addLangs(['es', 'en']);
    this.handleLoading();

    if (environment.production) {
      this.firebaseService.initApp();
    }

    if (this.isOperator()) {
      this.scriptLoaderService.load('Hotjar');
    }

    this.checkCurrentUrl();
  }

  checkCurrentUrl() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  private handleLoading(): void {
    this.loadingService.loading.subscribe((data: boolean) => {
      setTimeout(() => {
        this.isLoading = data;
      });
    });
  }
}
