import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// Extends Components
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';

// Service
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { PermitsService } from '@app/core/services/permits/permits.service';

@Component({
  selector: 'fmx-client-header',
  templateUrl: './client-header.component.html'
})
export class ClientHeaderComponent extends BaseCurrentUserComponent {
  @Input() idClient: string;

  constructor(
    private router: Router,
    private location: Location,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {
    super(currentUserFacade, permitsService);
  }

  back(): void {
    this.location.back();
  }

  goToPreventives(): void {
    this.router.navigate([
      `/dashboard-fmx/client/${this.idClient}/preventives`
    ]);
  }

  goToMainInfo(): void {
    this.router.navigate([`/dashboard-fmx/client/${this.idClient}`]);
  }
}
