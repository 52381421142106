import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INITIAL_PAGE } from '../const/initial-pages.const';
import { AuthService } from '../services/auth/auth.service';
import { DeviceService } from '../services/device/device.service';
import { LoadingService } from '../services/loading/loading.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  isRefreshingToken = false;

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private deviceService: DeviceService
  ) {}

  addHeaders(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: token,
        Accept: 'application/json',
        ...this.getHeadersDevice()
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next
      .handle(this.addHeaders(req, this.authService.getAuthToken()))
      .pipe(
        catchError((error: any) => {
          this.loadingService.hideLoading();

          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                this.logoutUser();

                return throwError(error);
              case 403:
                return this.handle403Error(error);
              default:
                return throwError(error);
            }
          }

          return throwError(error);
        })
      );
  }

  handle403Error(err: HttpErrorResponse): Observable<any> {
    const error = err.error.errors[0];

    if (error && error.title.includes('UnconfirmedAccountError')) {
      this.toastrService.error(
        'Tu cuenta no ha sido confirmada por nuestros agentes'
      );

      this.logoutUser();

      return throwError(err);
    }

    this.toastrService.error('No tienes permiso para realizar esa acción');

    return throwError(err);
  }

  private logoutUser(): void {
    this.authService.removeDataLogout();
    this.router.navigateByUrl(INITIAL_PAGE.login);
  }

  private getHeadersDevice() {
    return {
      'X-Browser-Name': this.deviceService.browserName,
      'X-Browser-Version': this.deviceService.browserVersion,
      'X-Platform-Name': this.deviceService.os,
      'X-Platform-Version': this.deviceService.osVersion,
      'X-Device-Name': this.deviceService.deviceName,
      'X-App-Version': this.deviceService.appVersion
    };
  }
}
