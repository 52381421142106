import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'complaintStatus'
})
export class ComplaintStatusPipe implements PipeTransform {
  status = {
    pending: 'Pendiente',
    on_going: 'En curso',
    rejected: 'Rechazada',
    solved: 'Solucionada'
  };

  transform(value: string): string {
    return this.status[value];
  }
}
