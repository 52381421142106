// tslint:disable: no-void-expression
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Extends class
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
import { SelectOption } from '@app/core/models/select-option.model';

// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { ComplaintsFacadeService } from '@app/features/complaints/services/complaints-facade.service';

// Utils
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';

@Component({
  selector: 'app-mark-as-complete-complaint-modal',
  templateUrl: './mark-as-complete-complaint-modal.component.html'
})
export class MarkAsCompleteComplaintModalComponent
  extends BaseCurrentUserComponent
  implements OnInit {
  complaint: any;
  form: FormGroup;
  serviceId: number;
  complaintProviderStatus: SelectOption[] = [];

  constructor(
    private dialogRef: MatDialogRef<MarkAsCompleteComplaintModalComponent>,
    public formBuilder: FormBuilder,
    private facade: ComplaintsFacadeService,
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService,
    private fmxToastr: FmxToastr,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(currentUserFacade, permitsService);
  }

  ngOnInit() {
    this.complaint = this.data && this.data.complaint;
    this.serviceId = this.data.serviceId;
    this.getComplaintProviderStatus();
    this.initForm();
  }

  closeModal(refresh: boolean = false) {
    this.dialogRef.close(refresh);
  }

  markAsCompleteComplaint() {
    const idProvider = this.relationships().provider.data.id;

    this.facade
      .markAsCompleteComplaint(idProvider, this.complaint.id, this.form.value)
      .subscribe(
        () => {
          this.fmxToastr.success('Reclamación resuelta correctamente');
          this.closeModal(true);
        },
        (error: any) => {
          const errors = error.error.errors;
          checkErrors(errors, this.form);
        }
      );
  }

  private initForm() {
    this.form = this.formBuilder.group({
      provider_status: [''],
      provider_comment: [null]
    });
  }

  private getComplaintProviderStatus(): void {
    this.facade
      .getComplaintProviderStatus()
      .subscribe(
        (response: SelectOption[]) => (this.complaintProviderStatus = response)
      );
  }
}
