<mat-form-field class="full-width">
  <div class="fmx-form-group fmx-select fmx-form-group-no-padding">
    <label *ngIf="label"
      >{{ label
      }}<span class="required-asterisk" *ngIf="required">*</span></label
    >
    <mat-select [formControl]="cmpControl" [multiple]="multiple">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="searchControl"
          placeholderLabel="Buscar"
          noEntriesFoundLabel="no encontrado"
        >
        </ngx-mat-select-search>
      </mat-option>

      <ng-container *ngFor="let item of filteredList$ | async">
        <mat-option
          *ngIf="item.name === ALL_OPTION.name"
          [value]="item.value"
          (click)="changeAllOption()"
        >
          {{ item.name }}
        </mat-option>

        <mat-option
          *ngIf="item.name !== ALL_OPTION.name"
          [value]="item.value"
          (click)="changeOption()"
        >
          {{ item.name }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>
</mat-form-field>
