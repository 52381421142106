// tslint:disable: no-reserved-keywords
import * as moment from 'moment';

export class Complaint {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;

  constructor(that: any, thatIncluded: any[]) {
    if (that) {
      this.id = that.id;
      this.type = that.type;
      this.attributes = that.attributes;
      this.relationships = that.relationships;

      if (hasAuthorComplaint(that)) {
        const idAuthor = this.relationships.author.data.id;
        const author = thatIncluded.find(
          // tslint:disable-next-line: no-shadowed-variable
          (author: any) => author.id === idAuthor
        );

        if (author) {
          this.attributes.author = `${author.attributes.first_name} ${author.attributes.last_name}`;
        }
      }
    }
  }

  providerHasAccess(): boolean {
    return this.attributes.provider_access;
  }

  isPendingToProvider(): boolean {
    return this.attributes.provider_status === 'pending';
  }

  expiredComplaint(): boolean {
    return (
      this.isPending &&
      moment(this.attributes.sla_expires_at).isBefore(new Date())
    );
  }

  isPending(): boolean {
    return this.attributes.status === 'pending';
  }

  isOnGoing(): boolean {
    return this.attributes.status === 'on_going';
  }

  generatedServiceId(): string {
    return (
      this.relationships &&
      this.relationships.generated_service &&
      this.relationships.generated_service.data &&
      this.relationships.generated_service.data.id
    );
  }
}

interface Attributes {
  title: string;
  description: string;
  status: string;
  provider_status: string;
  kind: string;
  severity: string;
  provider_comment: string;
  comment_to_provider: string;
  comment_to_client: string;
  sla_expires_at: string;
  created_at: string;
  updated_at: string;
  solved_at: string;
  from_issue: boolean;
  provider_access: boolean;
  client_access: boolean;
  author?: string;
}

interface Relationships {
  service: {
    data: Data;
  };
  generated_service: {
    data: Data;
  };
  author: {
    data: Data;
  };
}

interface Data {
  id: string;
  type: string;
}

function hasAuthorComplaint(complaint: any): boolean {
  return (
    complaint.relationships &&
    complaint.relationships.author &&
    complaint.relationships.author.data
  );
}
