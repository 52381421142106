export enum statusProvider {
  active = 'active',
  inactive = 'inactive',
  leave = 'leave'
}

export const STATUS_PROVIDER_VALUES = [
  { value: statusProvider.active, name: 'Activo' },
  { value: statusProvider.inactive, name: 'Inactivo' },
  { value: statusProvider.leave, name: 'Baja' }
];

export const STATUS_PROVIDER_ACTIVES_VALUES = [
  { value: statusProvider.active, name: 'Activo' },
  { value: statusProvider.inactive, name: 'Inactivo' }
];

export const STATUS_PROVIDERS_FILTER = {
  all: [statusProvider.active, statusProvider.inactive, statusProvider.leave],
  actives: [statusProvider.active],
  all_actives: [statusProvider.active, statusProvider.inactive]
};
