<mat-dialog-content>
  <h3 class="text-center font-18 font-weight-bold p-y-20">
    {{ modalParams.title }}
  </h3>

  <form [formGroup]="form" class="fmx-form">
    <div class="row">
      <div class="col-12 fmx-form-group">
        <label>Título</label>
        <input
          matInput
          formControlError
          type="text"
          formControlName="title"
          required
        />
      </div>
    </div>
    <div class="row" *ngIf="isOperator()">
      <div class="col-12 col-sm-6 p-l-0 p-b-15 fmx-form-group">
        <div
          *ngIf="complaintsKind"
          fmx-select
          formControlError
          [list]="complaintsKind"
          label="Tipo"
          placeholder="Seleccionar tipo"
          formControlName="kind"
          [required]="true"
        ></div>
      </div>
      <div class="col-12 col-sm-6 p-l-0 p-b-15 fmx-form-group">
        <div
          *ngIf="severities"
          fmx-select
          formControlError
          [list]="severities"
          label="Gravedad"
          placeholder="Seleccionar gravedad"
          formControlName="severity"
          [required]="true"
        ></div>
      </div>
    </div>
    <div class="row" *ngIf="isOperator()">
      <div class="col-12 col-sm-6 p-l-0 p-b-15 fmx-form-group">
        <div
          *ngIf="statuses"
          fmx-select
          formControlError
          [list]="statuses"
          label="Estado del cliente"
          placeholder="Seleccionar estado"
          formControlName="status"
          [required]="true"
        ></div>
      </div>
      <div class="col-12 col-sm-6 p-l-0 p-b-15 fmx-form-group">
        <div
          *ngIf="complaintProviderStatus"
          fmx-select
          formControlError
          [list]="complaintProviderStatus"
          label="Estado del proveedor"
          placeholder="Seleccionar estado"
          formControlName="provider_status"
          [required]="true"
        ></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 fmx-form-group">
        <label>Descripcion</label>
        <textarea
          matInput
          formControlError
          formControlName="description"
          placeholder="Descripcion"
          required
        ></textarea>
      </div>
      <ng-container *ngIf="isOperator()">
        <div class="col-12 col-md-8 fmx-form-group">
          <label>Comentario al proveedor</label>
          <textarea
            matInput
            formControlError
            formControlName="comment_to_provider"
            placeholder="Comentario al proveedor"
          ></textarea>
        </div>
        <div
          class="col-12 col-md-4 fmx-form-group d-flex justify-content-center"
        >
          <mat-checkbox formControlName="provider_access" labelPosition="after"
            >Enviar a proveedor</mat-checkbox
          >
        </div>
        <div class="col-12 col-md-8 fmx-form-group">
          <label>Comentario al cliente</label>
          <textarea
            matInput
            formControlError
            formControlName="comment_to_client"
            placeholder="Comentario al cliente"
          ></textarea>
        </div>
        <div
          class="col-12 col-md-4 fmx-form-group d-flex justify-content-center"
        >
          <mat-checkbox formControlName="client_access" labelPosition="after"
            >Mostrar al cliente</mat-checkbox
          >
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="col-12 fmx-form-group">
        <label>Contacto para la reclamación</label>
      </div>
      <div class="col-6 fmx-form-group">
        <label>Nombre</label>
        <input
          matInput
          formControlError
          type="text"
          formControlName="contact_name"
          required
        />
      </div>
      <div class="col-6 fmx-form-group">
        <label>Teléfono</label>
        <input
          matInput
          formControlError
          type="text"
          formControlName="contact_phone"
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <button
          class="fmx-btn fmx-btn-clear text-uppercase fmx-btn-primary fmx-btn-bold font-14"
          (click)="closeModal()"
        >
          Cancelar
        </button>
      </div>
      <div class="col-12 col-sm-6">
        <button
          class="fmx-btn fmx-btn-green fmx-btn-block text-uppercase fmx-btn-bold font-14"
          (click)="submitComplaint()"
        >
          {{ modalParams.submit }}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
