import { SelectOption } from '../models/select-option.model';

export function getTableConfiguration(allowEdiTable: boolean = false) {
  return {
    theme: 'default',
    editable: allowEdiTable
  };
}

export function getStatusName(
  codeName: string,
  serviceStatuses: SelectOption[]
) {
  const status: SelectOption = serviceStatuses.find(
    (item: SelectOption) => item.value === codeName
  );

  if (status) {
    return status.name;
  }

  return '';
}

export function getStatusColorIconTable(item: any) {
  const colors = {
    green: 'success',
    red: 'danger',
    yellow: 'warning'
  };

  return colors[item.attributes.sla_color];
}

export function changeStatusToArray(params: any): any {
  if (params.status instanceof Array) {
    params['status[]'] = params.status;
    delete params.status;
  }

  return params;
}
