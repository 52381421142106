// tslint:disable: no-var-requires
import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
// tslint:disable-next-line: no-require-imports
const { version: appVersion } = require('../../../../../package.json');

@Injectable()
export class DeviceService {
  constructor(private deviceService: DeviceDetectorService) {}

  get os(): string {
    return this.getDeviceInfo().os;
  }

  get appVersion(): string {
    return appVersion;
  }

  get osVersion(): string {
    const osVersion = this.getDeviceInfo().os_version;

    return osVersion === 'unknown'
      ? this.userAgent()
          .split(';')
          .find((item: any) => item.includes(this.os))
      : osVersion;
  }

  get browserName(): string {
    return this.getDeviceInfo().browser;
  }

  get browserVersion(): string {
    return this.getDeviceInfo().browser_version;
  }

  get deviceName(): string {
    const deviceName = this.getDeviceInfo().device;

    return deviceName.toLocaleLowerCase() === 'unknown'
      ? 'Desktop'
      : deviceName;
  }

  get deviceType(): string {
    let typeDevice = '';
    const deviceType = {
      Desktop: this.deviceService.isDesktop(),
      Tablet: this.deviceService.isTablet(),
      Mobile: this.deviceService.isMobile()
    };

    Object.keys(deviceType).forEach((device: any) => {
      if (deviceType[device]) {
        typeDevice = device;
      }
    });

    return typeDevice;
  }

  private userAgent(): string {
    return this.getDeviceInfo().userAgent;
  }

  private getDeviceInfo(): DeviceInfo {
    return this.deviceService.getDeviceInfo();
  }
}
