// tslint:disable:only-arrow-functions
// tslint:disable:underscore-consistent-invocation
// tslint:disable:no-function-expression
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, expand, map, reduce, takeWhile } from 'rxjs/operators';

import { SelectOption } from '@app/core/models/select-option.model';
import { DataPage } from '@app/fmx-shared/interfaces/data-page.interface';

// Utils & Const
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
import {
  generateResponseDataPage,
  toSelectOptions
} from '@app/fmx-shared/utils/data-utils';

import * as _ from 'lodash';

@Injectable()
export class ProvidersService {
  constructor(private http: HttpClient) {}

  getProviders(
    status: string[] = null,
    currentPage: number = 1
  ): Observable<SelectOption[]> {
    let params = new HttpParams()
      .set('sort_by', 'name')
      .set('minimum_api', 'true')
      .set('page', currentPage.toString());

    if (status) {
      status.forEach((key: string) => {
        params = params.append('status[]', key);
      });
    }

    const endpoint = 'providers';

    return this.http.get<any>(endpoint, { params });
  }

  getProvidersByPage(
    page: number,
    search: string = '',
    status: string[] = null
  ): Observable<DataPage> {
    let params = new HttpParams()
      .set('sort_by', 'name')
      .set('sort_dir', 'asc')
      .set('services', 'true')
      .set('minimum_api', 'true')
      .set('per_page', ELEMENTS_PER_PAGE)
      .set('page', page.toString());

    if (status) {
      status.forEach((key: string) => {
        params = params.append('status[]', key);
      });
    }

    if (search) {
      params = params.append('name', search);
    }

    const endpoint = 'providers';

    return this.http
      .get(endpoint, { params })
      .pipe(map(generateResponseDataPage));
  }

  createProvider(params: any): Observable<any> {
    const endpoint = `providers`;

    return this.http.post(endpoint, params);
  }

  getProvider(providerId: string): Observable<any> {
    const endpoint = `providers/${providerId}`;

    return this.http.get(endpoint);
  }

  updateProvider(providerId: string, params: any): Observable<any> {
    const endpoint = `providers/${providerId}`;

    return this.http.patch(endpoint, params);
  }

  leaveProvider(providerId: string, params: any): Observable<any> {
    const endpoint = `providers/${providerId}`;

    return this.http.put(endpoint, params);
  }

  downloadProvidersList(filter: any): Observable<Blob> {
    const endpoint = `providers.xlsx`;
    const params = { ...filter };

    return this.http.get(endpoint, { params, responseType: 'blob' });
  }

  getProviderFiscalData(providerId: string): Observable<any> {
    const endpoint = `providers/${providerId}/fiscal_info?include=state,country`;

    return this.http.get(endpoint);
  }

  updateProviderFiscalData(providerId: string, params: any): Observable<any> {
    const endpoint = `providers/${providerId}/fiscal_info`;

    return this.http.patch(endpoint, params);
  }

  getCoverages(filter: any, sort: any, pagination: any): Observable<any> {
    const endpoint = 'provider_coverages';

    const params = {
      ...filter,
      ...sort,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getProviderAllCoverages(providerId: string): Observable<any> {
    return this.getAllCoverages(providerId).pipe(
      expand((response: any) =>
        this.getAllCoverages(providerId, +response.meta.current_page + 1)
      ),
      takeWhile(
        (response: any) => response.meta.current_page <= response.meta.last_page
      ),
      concatMap((response: any) => response.data),
      reduce((acc: any, val: any) => {
        acc.push(val);

        return acc;
      }, [])
    );
  }

  getAllCoverages(
    providerId: string,
    currentPage: number = 1
  ): Observable<any> {
    const params = new HttpParams().set('page', currentPage.toString());
    const endpoint = `providers/${providerId}/provider_coverages`;

    return this.http.get(endpoint, { params });
  }

  addCoverage(providerId: string, data: any): Observable<any> {
    const endpoint = `providers/${providerId}/provider_coverages`;

    return this.http.post(endpoint, data);
  }

  removeCoverage(providerId: string, coverageId: any): Observable<any> {
    const endpoint = `providers/${providerId}/provider_coverages/${coverageId}`;

    return this.http.delete(endpoint);
  }

  updateCoverage(
    providerId: string,
    coverageId: any,
    params: any
  ): Observable<any> {
    const endpoint = `providers/${providerId}/provider_coverages/${coverageId}`;

    return this.http.put(endpoint, params);
  }

  getProviderDoers(providerId: string): Observable<any> {
    const endpoint = `providers/${providerId}/doers`;

    return this.http.get(endpoint);
  }

  addDoerToProvider(providerId: string, params: any): Observable<any> {
    const endpoint = `providers/${providerId}/doers`;

    return this.http.post(endpoint, params);
  }

  updateDoer(providerId: string, doerId: any, params: any): Observable<any> {
    const endpoint = `providers/${providerId}/doers/${doerId}`;

    return this.http.put(endpoint, params);
  }

  deleteDoer(providerId: string, doerId: any): Observable<any> {
    const endpoint = `providers/${providerId}/doers/${doerId}`;

    return this.http.delete(endpoint);
  }

  getProviderReceipts(providerId: string, params?: any): Observable<any> {
    const endpoint = `providers/${providerId}/receipts`;

    return this.http.get(endpoint, { params });
  }

  getProviderIssues(providerId: string): Observable<any> {
    const endpoint = `providers/${providerId}/issues`;

    return this.http.get(endpoint);
  }

  getProviderDocuments(providerId: string): Observable<any> {
    const endpoint = `providers/${providerId}/documents`;

    return this.http.get(endpoint);
  }

  createProviderDocuments(providerId: string, data: any = {}): Observable<any> {
    const endpoint = `providers/${providerId}/documents`;

    return this.http.post(endpoint, data);
  }

  deleteProviderDocument(
    providerId: string,
    documentId: string
  ): Observable<any> {
    const endpoint = `providers/${providerId}/documents/${documentId}`;

    return this.http.delete(endpoint);
  }

  downloadProviderDocument(
    providerId: string,
    documentId: string
  ): Observable<Blob> {
    const endpoint = `providers/${providerId}/documents/${documentId}`;

    return this.http.get(endpoint, { responseType: 'blob' });
  }

  getProviderServices(providerId: string, params: any): Observable<any> {
    const endpoint = `providers/${providerId}/services`;

    return this.http.get(endpoint, { params }).pipe(
      map((value: any) => {
        value.data = _.sortBy(value.data, [
          function (item: any) {
            return !item.attributes.unseen_changes;
          }
        ]);

        return value;
      })
    );
  }

  getProviderInvoicesByProvider(
    providerId: string,
    filter: any,
    pagination: any
  ): Observable<any> {
    const endpoint = `providers/${providerId}/provider_invoices`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getCompanyAggrupationsByProvider(
    providerId: string
  ): Observable<SelectOption[]> {
    const endPoint = `providers/${providerId}/company_aggrupations`;

    return this.http.get(endPoint).pipe(map(toSelectOptions));
  }

  getProviderAcquittancesByProvider(
    providerId: any,
    filter: any,
    pagination: any
  ): Observable<any> {
    const endpoint = `providers/${providerId}/additional_works_acquittances`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getProviderInvoices(filter: any, pagination: any): Observable<any> {
    const endpoint = `provider_invoices`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getProviderAcquittances(filter: any, pagination: any): Observable<any> {
    const endpoint = `additional_works_acquittances`;
    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getComplaintsProvider(
    filter: any,
    pagination: any,
    idProvider: string
  ): Observable<any> {
    const endpoint = `providers/${idProvider}/complaints`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  markAsCompleteComplaint(
    idProvider: string,
    idComplaint: string,
    data: any
  ): Observable<any> {
    const endpoint = `providers/${idProvider}/complaints/${idComplaint}/complaint_resolution`;

    return this.http.post(endpoint, data);
  }
}
