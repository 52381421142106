import { Component } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { CurrentUser } from '../models/current-user.model';

// Services
import { CurrentUserFacadeService } from '../services/current-user/current-user.facade.service';
import { PermitsService } from '../services/permits/permits.service';

@Component({
  selector: 'fmx-base-current-user',
  template: ''
})
export class BaseCurrentUserComponent {
  get currentUser(): CurrentUser {
    return this.currentUserFacade.currentUser;
  }

  get currentServiceIsMine(): boolean {
    return this.currentUserFacade.currentServiceIsMine;
  }

  set currentServiceIsMine(value: boolean) {
    this.currentUserFacade.currentServiceIsMine = value;
  }

  set currentServiceIsMineAsNetworkManager(value: boolean) {
    this.currentUserFacade.currentServiceIsMineAsNetworkManager = value;
  }

  get currentServiceIsMineAsNetworkManager(): boolean {
    return this.currentUserFacade.currentServiceIsMineAsNetworkManager;
  }

  constructor(
    protected currentUserFacade: CurrentUserFacadeService,
    protected permitsService: PermitsService
  ) {}

  currentUserLoaded(): Observable<CurrentUser> {
    return this.currentUserFacade.currentUserLoaded();
  }

  isOperator(): boolean {
    return this.currentUserFacade.isOperator();
  }

  isOperatorAdminOrFinance(): boolean {
    return this.isOperator() && this.isSuperAdminOrFinance();
  }

  isOperatorRegularWithServiceAssigned(): boolean {
    return (
      this.isOperator() && this.isUserRegular() && this.currentServiceIsMine
    );
  }

  isProvider(): boolean {
    return this.currentUserFacade.isProvider();
  }

  isClient(): boolean {
    return this.currentUserFacade.isClient();
  }

  isOperatorOrClient(): boolean {
    return this.isOperator() || this.isClient();
  }

  isOperatorOrProvider(): boolean {
    return this.isOperator() || this.isProvider();
  }

  isProviderOrClient(): boolean {
    return this.isProvider() || this.isClient();
  }

  isSuperAdminOrFinance(): boolean {
    return this.isSuperAdmin() || this.isUserFinance();
  }

  isClientAdmin(): boolean {
    return this.isClient() && (this.isUserAdmin() || this.isSuperAdmin());
  }

  isClientAdminAndIsAuthorizer(): boolean {
    return this.isClientAdmin() && this.currentUser.attributes.authorizer;
  }

  isOperatorAdmin(): boolean {
    return this.isOperator() && (this.isUserAdmin() || this.isSuperAdmin());
  }

  isOperatorFinance(): boolean {
    return this.isOperator() && this.isUserFinance();
  }

  isOperatorRegular(): boolean {
    return this.isOperator() && this.isUserRegular();
  }

  isClientRegular(): boolean {
    return this.isClient() && this.isUserRegular();
  }

  isUserRegular(): boolean {
    return this.currentUserFacade.hasLevelRegular();
  }

  isUserAdmin(): boolean {
    return this.currentUserFacade.hasLevelAdmin();
  }

  isUserFinance(): boolean {
    return this.currentUserFacade.hasLevelFinance();
  }

  isSuperAdmin(): boolean {
    return this.currentUserFacade.hasLevelSuperAdmin();
  }

  isManager(): boolean {
    return this.currentUserFacade.isDoerManager();
  }

  isUserHomeService(): boolean {
    return this.currentUser.attributes.home_services;
  }

  relationships(): any {
    return this.currentUser.relationships;
  }

  canEditService(): boolean {
    return this.permitsService.canEditService();
  }

  canClientEditComplaint(): boolean {
    return this.permitsService.canClientEditComplaint();
  }
}
