import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// Service
import { AuthService } from '@app/core/services/auth/auth.service';
import { CurrentUserService } from '../current-user/current-user.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

// Models
import { CurrentUser } from '@app/core/models/current-user.model';

@Injectable()
export class AppInitService {
  constructor(
    private httpClient: HttpClient,
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  loadConfig(): Promise<any> {
    // tslint:disable-next-line: typedef
    return new Promise((resolve, rejectFunction) => {
      if (this.localStorageService.getItem('Authorization')) {
        this.getUserProfile().subscribe(resolve, rejectFunction);
      } else {
        this.currentUserService.currentUser = null;
        this.authService.loggedIn.next(false);
        resolve(null);
      }
    });
  }

  getUserProfile(): Observable<any> {
    const endpoint = 'profile';

    return this.httpClient
      .get(endpoint)
      .pipe(tap((response: any) => this.setCurrentUser(response)));
  }

  private setCurrentUser(profile: any): void {
    this.currentUserService.currentUser = new CurrentUser(profile.data);
    this.authService.loggedIn.next(true);
  }
}
