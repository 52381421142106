import { Injectable } from '@angular/core';
import {
  AttributesCurrentUser,
  CurrentUser
} from '@app/core/models/current-user.model';
import { Observable } from 'rxjs';
import { CurrentUserService } from './current-user.service';

@Injectable()
export class CurrentUserFacadeService {
  private _currentServiceIsMine = false;
  private _currentServiceIsMineAsNetworkManager = false;

  constructor(protected currentUserService: CurrentUserService) {}

  get currentUser(): CurrentUser {
    return this.currentUserService.currentUser;
  }

  get currentServiceIsMine(): boolean {
    return this._currentServiceIsMine;
  }

  set currentServiceIsMine(value: boolean) {
    this._currentServiceIsMine = value;
  }

  get currentServiceIsMineAsNetworkManager(): boolean {
    return this._currentServiceIsMineAsNetworkManager;
  }

  set currentServiceIsMineAsNetworkManager(value: boolean) {
    this._currentServiceIsMineAsNetworkManager = value;
  }

  currentUserLoaded(): Observable<CurrentUser> {
    return this.currentUserService._currentUser$;
  }

  isOperator(): boolean {
    return (
      this.currentUserService.currentUser &&
      this.currentUserService.currentUser.isOperator()
    );
  }

  isClient(): boolean {
    return (
      this.currentUserService.currentUser &&
      this.currentUserService.currentUser.isClient()
    );
  }

  isProvider(): boolean {
    return (
      this.currentUserService.currentUser &&
      this.currentUserService.currentUser.isProvider()
    );
  }

  isDoerManager(): boolean {
    return this.currentUser.attributes && this.currentUser.attributes.manager;
  }

  hasLevelSuperAdmin(): boolean {
    return this.currentUserService.currentUser.hasLevelSuperAdmin();
  }

  hasLevelAdmin(): boolean {
    return this.currentUserService.currentUser.hasLevelAdmin();
  }

  hasLevelFinance(): boolean {
    return this.currentUserService.currentUser.hasLevelFinance();
  }

  hasLevelRegular(): boolean {
    return this.currentUserService.currentUser.hasLevelRegular();
  }

  relationships(): any {
    return this.currentUser.relationships;
  }

  attributes(): AttributesCurrentUser {
    return this.currentUser.attributes;
  }

  hasRelationWithCA(idCA: number): boolean {
    return (
      this.isOperator() ||
      this.currentUser.relationships.company_aggrupations.data.some(
        (item: any) => +item.id === idCA
      )
    );
  }

  hasRelationWithCompany(idCompany: number): boolean {
    return (
      this.isOperator() ||
      this.currentUser.relationships.companies.data.some(
        (item: any) => +item.id === idCompany
      )
    );
  }

  hasRelationWithGroup(idGroup: number): boolean {
    return (
      this.isOperator() ||
      this.currentUser.relationships.groups.data.some(
        (item: any) => +item.id === idGroup
      )
    );
  }
}
