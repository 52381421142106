import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { INITIAL_PAGE } from '../const/initial-pages.const';
import { AuthService } from '../services/auth/auth.service';
import { RouteRefererService } from '../services/route-referer/route-referer.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private routeRefererService: RouteRefererService
  ) {}

  canActivate(
    // tslint:disable-next-line: no-unused
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.routeRefererService.routeReferer = state.url;
          this.router.navigate([INITIAL_PAGE.login]);

          return false;
        }

        return true;
      })
    );
  }
}
