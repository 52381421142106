// tslint:disable: no-void-expression
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { CompanyAggrupationsService } from '@app/core/services/company-aggrupations/company-aggrupations.service';
import { ProvidersService } from '@app/core/services/providers/providers.service';

import { DataPage } from '@app/fmx-shared/interfaces/data-page.interface';
import { RechargesRepaysState } from '@app/fmx-shared/states/recharges-repays.state';

// Const
import { INITIAL_PAGE_SELECT } from '@app/fmx-shared/constants/initial-page-select.const';

// Utils
import { formattedDate } from '@app/core/utils/date-format-datepicker.utils';

// Models
import { SelectOption } from '@app/core/models/select-option.model';

// Const
import { STATUS_PROVIDERS_FILTER } from '@app/core/const/status-providers.const';
import { listSelectToggle } from '@app/fmx-shared/constants/list-select-toggle.const';

@Component({
  selector: 'fmx-recharges-repays-filter',
  templateUrl: './fmx-recharges-repays-filter.component.html'
})
export class FmxRechargesRepaysFilterComponent implements OnInit {
  @Output() changeFilter = new EventEmitter<any>();

  @Input() title: string;
  @Input() enableListProviders: boolean;

  formFilter: FormGroup;
  initFilter: any;

  companyAggrupations: DataPage;
  listProviders: DataPage;
  listToggle: SelectOption[] = listSelectToggle;

  showButtonsFilters = false;

  unsubscribe = new Subject();

  TYPE_SELECT = {
    selectProv: 'providers',
    selectComp: 'company_aggrupation'
  };

  constructor(
    private fb: FormBuilder,
    private state: RechargesRepaysState,
    private companyAggrupationsService: CompanyAggrupationsService,
    private providersService: ProvidersService
  ) {}

  ngOnInit() {
    this.initFilter = this.state.get('filterRechargesRepaysSub');
    this.initFilterForm();
    this.enableListProviders
      ? this.getProviders(INITIAL_PAGE_SELECT)
      : this.getCompanyAggrupations(INITIAL_PAGE_SELECT);
    this.setParamsFilter();
  }

  setParamsFilter() {
    this.changeFilter.emit(this.formRawValue());
    this.showButtonsFilters = true;
  }

  resetFilter() {
    this.formFilter.patchValue(this.initFilter);
  }

  handleSearchSelect(search: string, selectMode: string): void {
    switch (selectMode) {
      case this.TYPE_SELECT.selectProv:
        this.getProviders(1, search);
        break;
      default:
        this.getCompanyAggrupations(1, search);
    }
  }

  handleScrollBottom(page: number = 1, selectMode: string): void {
    switch (selectMode) {
      case this.TYPE_SELECT.selectProv:
        this.getProviders(page);
        break;
      default:
        this.getCompanyAggrupations(page);
    }
  }

  private formRawValue(): any {
    this.enableListProviders
      ? this.formFilter.removeControl('company_aggrupation')
      : this.formFilter.removeControl('provider');

    return {
      ...this.formFilter.value,
      created_at_from: formattedDate(this.getControl('created_at_from')),
      created_at_to: formattedDate(this.getControl('created_at_to'))
    };
  }

  private initFilterForm() {
    this.formFilter = this.fb.group({
      company_aggrupation: [this.initFilter.company_aggrupation],
      provider: [this.initFilter.provider],
      service: [this.initFilter.service],
      created_at_from: [this.initFilter.created_at_from],
      created_at_to: [this.initFilter.created_at_to],
      pending: [this.initFilter.pending]
    });
  }

  private getControl(nameControl: string): any {
    return this.formFilter.get(nameControl).value;
  }

  private getCompanyAggrupations(page: number, search: string = '') {
    this.companyAggrupationsService
      .getCompanyAggrupationsByPage(page, search)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: DataPage) => (this.companyAggrupations = data));
  }

  private getProviders(page: number, search: string = ''): void {
    this.providersService
      .getProvidersByPage(page, search, STATUS_PROVIDERS_FILTER.all)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: DataPage) => (this.listProviders = data));
  }
}
