import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'fmx-select-modal',
  templateUrl: './fmx-select-modal.component.html'
})
export class FmxSelectModalComponent implements OnInit {
  form: FormGroup;
  isRequired = false;

  unsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FmxSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initForm();
  }

  success() {
    this.emit(true);
  }

  close() {
    this.emit(false);
  }

  emit(result: boolean) {
    const data = this.form.value;
    this.dialogRef.close({ result, data });
  }

  markInputCommentsAsRequired(event: any) {
    event.value === 'reject_with_comments'
      ? (this.isRequired = true)
      : (this.isRequired = false);
  }

  private initForm() {
    this.form = this.fb.group({
      list: ['', Validators.required],
      comments: [null]
    });
  }
}
