/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./fmx-image-modal.component";
var styles_FmxImageModalComponent = [];
var RenderType_FmxImageModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FmxImageModalComponent, data: {} });
export { RenderType_FmxImageModalComponent as RenderType_FmxImageModalComponent };
export function View_FmxImageModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { downloadLink: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "dialog-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "dialog-header-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "dialog-close-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["class", "fmx-btn fmx-btn-clear fmx-btn-icon fmx-btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(6, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], null, null), (_l()(), i0.ɵted(-1, 0, ["close"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "m-b-15 modal_image_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "dialog-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "fmx-btn fmx-btn-sm fmx-btn-green m-l-5 m-r-5"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Descargar "])), (_l()(), i0.ɵeld(15, 0, [[1, 0], ["downloadLink", 1]], null, 0, "a", [["class", "hidden"], ["download", ""], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null))], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.url; _ck(_v, 11, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 15, 0, currVal_2); }); }
export function View_FmxImageModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fmx-image-modal", [], null, null, null, View_FmxImageModalComponent_0, RenderType_FmxImageModalComponent)), i0.ɵdid(1, 114688, null, 0, i4.FmxImageModalComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FmxImageModalComponentNgFactory = i0.ɵccf("fmx-image-modal", i4.FmxImageModalComponent, View_FmxImageModalComponent_Host_0, {}, {}, []);
export { FmxImageModalComponentNgFactory as FmxImageModalComponentNgFactory };
