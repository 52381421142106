import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentAdditionalWorkStatus'
})
export class PaymentAdditionalWorkStatusPipe implements PipeTransform {
  status = {
    paid: 'Valoración pagada',
    unpaid: 'Pendiente de pago',
    created: 'Pendiente de pago',
    completed: 'Valoración pagada',
    canceled: 'Cobro cancelado',
    expired: 'Cobro expirado',
    error: 'Error en el pago'
  };

  // tslint:disable-next-line: no-unused
  transform(value: string, args?: any): string {
    return this.status[value] as string;
  }
}
