<script src="node_modules/chart.js/src/chart.js"></script>

<fmx-header></fmx-header>

<div class="full-height">
  <router-outlet></router-outlet>
</div>
<fmx-footer></fmx-footer>

<app-loader-modal *ngIf="isLoading"></app-loader-modal>
