import { Pipe, PipeTransform } from '@angular/core';

const REASON_STATUSES = {
  unavailability: 'Indisponible',
  reschedule: 'Reagendado',
  illness: 'Enfermedad',
  specific_manufacturing: 'Preparando trabajo',
  material_reception_pending: 'Esperando material',
  document_management: 'Gestión documental',
  client_instructions_pending: 'Cliente pide esperar'
};

@Pipe({
  name: 'reasonStatus'
})
export class ReasonStatusPipe implements PipeTransform {
  transform(value: string): string {
    return REASON_STATUSES[value] || 'estado no definido';
  }
}
