import { Pipe, PipeTransform } from '@angular/core';

const ISSUE_SEVERITY_TYPES = {
  trivial: 'fmx-btn-fill-green',
  medium: 'fmx-btn-fill-orange-light',
  serious: 'fmx-btn-fill-red'
};

@Pipe({
  name: 'severityClassButton'
})
export class SeverityClassButtonPipe implements PipeTransform {
  transform(value: any): any {
    return ISSUE_SEVERITY_TYPES[value] || value;
  }
}
