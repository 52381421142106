import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GroupsService {
  constructor(private http: HttpClient) {}

  getGroup(idGroup: number): Observable<any> {
    const endpoint = `groups/${idGroup}`;

    return this.http.get(endpoint);
  }

  updateGroup(idGroup: number, data: any): Observable<any> {
    const endpoint = `groups/${idGroup}`;

    return this.http.put(endpoint, data);
  }

  getFiscalInfoGroup(idGroup: number): Observable<any> {
    const endpoint = `groups/${idGroup}/fiscal_info?include=state.country`;

    return this.http.get(endpoint);
  }

  getUsersGroup(idGroup: number): Observable<any> {
    const endpoint = `groups/${idGroup}/users`;

    return this.http.get(endpoint);
  }

  addUserGroup(idGroup: number, data: any): Observable<any> {
    const endpoint = `groups/${idGroup}/users`;

    return this.http.post(endpoint, data);
  }

  getInvoicesGroup(idGroup: number): Observable<any> {
    const endpoint = `groups/${idGroup}/invoices`;

    return this.http.get(endpoint);
  }

  getSpacesFromGroup(idGroup: number): Observable<any> {
    const endpoint = `groups/${idGroup}/children`;

    return this.http.get(endpoint);
  }

  createSpace(idGroup: number, data: any): Observable<any> {
    const endpoint = `groups/${idGroup}/spaces`;

    return this.http.post(endpoint, data);
  }
}
