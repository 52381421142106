import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Modules
import { FmxSharedModule } from '@app/fmx-shared';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { CalendarModule } from 'angular-calendar';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { AirbrakeErrorHandler } from './shared/handler/airbrake-error-handler';
import { MaterialModule } from './shared/modules/material.module';

// Init Component
import { InitComponent } from './init.component';

// COMPONENTS
import { AppComponent } from './app.component';
import { LoaderModalComponent } from './shared/components/loader-modal/loader-modal.component';

// Pipes
import { DecimalPipe, registerLocaleData } from '@angular/common';

// Services
import { AppInitService } from './core/services/app-init/app-init.service';

// Environment
import { environment } from 'environments/environment';

// Set Locale
// tslint:disable: match-default-export-name
// tslint:disable-next-line:import-name
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

export function initializerApp(config: AppInitService) {
  return (): Promise<any> =>
    config.loadConfig().then(
      // tslint:disable-next-line: no-empty
      () => {},
      // tslint:disable-next-line: no-empty
      () => {}
    );
}

// tslint:disable-next-line: function-name
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, InitComponent, LoaderModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    NgxMatDrpModule,
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot(),
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot(),
    // Core & Shared
    CoreModule,
    FmxSharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializerApp,
      multi: true,
      deps: [AppInitService]
    },
    {
      provide: ErrorHandler,
      useClass:
        environment.airbrake.environment !== 'qa'
          ? AirbrakeErrorHandler
          : ErrorHandler
    },
    AppInitService,
    TranslateService,
    FormBuilder,
    NgbActiveModal,
    DecimalPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoaderModalComponent]
})
export class AppModule {}
