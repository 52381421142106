var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
var CellComponent = /** @class */ (function () {
    function CellComponent() {
        // tslint:disable-next-line:no-output-on-prefix
        this.onClickCell = new EventEmitter();
    }
    CellComponent.prototype.clickCell = function () {
        if (this.cell.configuration && this.cell.configuration.linkable) {
            this.onClickCell.emit(this.cell.configuration.linkData || null);
        }
    };
    CellComponent.prototype.changeToggle = function (valueToggle) {
        var params = __assign({}, this.cell.configuration.linkData, { checked: valueToggle });
        this.onClickCell.emit(params);
    };
    return CellComponent;
}());
export { CellComponent };
