/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./fmx-banner-environment.component";
var styles_FmxBannerEnvironmentComponent = [];
var RenderType_FmxBannerEnvironmentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FmxBannerEnvironmentComponent, data: {} });
export { RenderType_FmxBannerEnvironmentComponent as RenderType_FmxBannerEnvironmentComponent };
export function View_FmxBannerEnvironmentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "fmx-banner-environment font-weight-bold flex-width flex-h-center flex-v-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" Entorno de pruebas ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTypeEnvironment(); _ck(_v, 1, 0, currVal_0); }); }
export function View_FmxBannerEnvironmentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fmx-banner-environment", [], null, null, null, View_FmxBannerEnvironmentComponent_0, RenderType_FmxBannerEnvironmentComponent)), i0.ɵdid(1, 49152, null, 0, i1.FmxBannerEnvironmentComponent, [], null, null)], null, null); }
var FmxBannerEnvironmentComponentNgFactory = i0.ɵccf("fmx-banner-environment", i1.FmxBannerEnvironmentComponent, View_FmxBannerEnvironmentComponent_Host_0, {}, {}, []);
export { FmxBannerEnvironmentComponentNgFactory as FmxBannerEnvironmentComponentNgFactory };
