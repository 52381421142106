import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'fmx-banner-environment',
  templateUrl: './fmx-banner-environment.component.html'
})
export class FmxBannerEnvironmentComponent {
  // tslint:disable-next-line: no-empty
  constructor() {}

  getTypeEnvironment(): string {
    return environment.environment.toUpperCase();
  }
}
