var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
import { ConfigurationService } from './../../../services/configuration.service';
var CellToggleComponent = /** @class */ (function () {
    function CellToggleComponent(configStyles) {
        this.configStyles = configStyles;
        // tslint:disable-next-line:no-output-on-prefix
        this.onChangeToggle = new EventEmitter();
        this._cell = {};
        this.defaultConf = {
            align: 'center',
            width: 'small',
            bold: true
        };
    }
    Object.defineProperty(CellToggleComponent.prototype, "cell", {
        set: function (item) {
            this._cell.value = item.value;
            this._cell.configuration = __assign({}, this.defaultConf, (item.configuration || {}));
            this.setStyles();
        },
        enumerable: true,
        configurable: true
    });
    CellToggleComponent.prototype.setStyles = function () {
        this.styles = this.configStyles.getStylesString(this._cell.configuration);
    };
    CellToggleComponent.prototype.changeValue = function (ev) {
        this.onChangeToggle.emit(ev.checked);
    };
    return CellToggleComponent;
}());
export { CellToggleComponent };
