import { Pipe, PipeTransform } from '@angular/core';

import { IMAGE_KINDS } from './../../constants/image-kinds.const';

@Pipe({
  name: 'imageKind'
})
export class ImageKindPipe implements PipeTransform {
  transform(value: string): string {
    return IMAGE_KINDS[value] || '';
  }
}
