import { ConfigurationService } from './../../services/configuration.service';
var ColumnComponent = /** @class */ (function () {
    function ColumnComponent(configStyles) {
        this.configStyles = configStyles;
        this._column = {};
    }
    Object.defineProperty(ColumnComponent.prototype, "column", {
        set: function (item) {
            this._column = item;
            this.setStyles();
        },
        enumerable: true,
        configurable: true
    });
    ColumnComponent.prototype.setStyles = function () {
        this.styles = this.configStyles.getColumnStylesString(this._column.configuration);
    };
    return ColumnComponent;
}());
export { ColumnComponent };
