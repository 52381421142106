import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HomeServiceService {
  constructor(private http: HttpClient) {}

  createHomeService(params: any = {}): Observable<any> {
    const endpoint = 'home_service_requests';

    return this.http.post(endpoint, params);
  }

  getHomeServiceProducts(idClient: string): Observable<any> {
    const endpoint = `company_aggrupations/${idClient}/home_service_products`;

    return this.http.get(endpoint);
  }

  getHomeServiceOptionalProducts(homeServiceProduct: string): Observable<any> {
    const endpoint = `home_service_products/${homeServiceProduct}/home_service_optional_products`;

    return this.http.get(endpoint);
  }
}
