<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <div class="fmx-form">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="fmx-form-group">
          <label>Seleccionar fichero</label>
          <fmx-upload-files
            [config]="configUploadFiles"
            (fileGenerated)="changeInputFiles($event)"
            (actionRemove)="removeInputFiles()"
          ></fmx-upload-files>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="fmx-form-group">
          <button
            class="fmx-btn fmx-btn-green fmx-btn-sm fmx-btn-clear fmx-btn-icon"
            (click)="downloadTemplate()"
          >
            <mat-icon class="mr-1">cloud_download</mat-icon> Descargar plantilla
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <button
          class="fmx-btn fmx-btn-clear text-uppercase fmx-btn-primary fmx-btn-bold font-14"
          (click)="close()"
        >
          Cancelar
        </button>
      </div>
      <div class="col-12 col-md-6">
        <button
          class="fmx-btn fmx-btn-green fmx-btn-block text-uppercase fmx-btn-bold font-14"
          (click)="importFile()"
          [disabled]="!fileToImport"
        >
          Importar
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
