import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fmx-editable-cell-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="fmx-form">
      <div class="fmx-form-group fmx-form-group-no-padding fmx-form-sm">
        <mat-checkbox [formControl]="control"></mat-checkbox>
      </div>
    </div>
  `
})
export class EditableCellCheckboxComponent {
  @Input() cell: any;
  @Input() control: FormControl;
}
