var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
// tslint:disable: no-void-expression
import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Services
import { CompanyAggrupationsService } from '@app/core/services/company-aggrupations/company-aggrupations.service';
import { ProvidersService } from '@app/core/services/providers/providers.service';
import { RechargesRepaysState } from '@app/fmx-shared/states/recharges-repays.state';
// Const
import { INITIAL_PAGE_SELECT } from '@app/fmx-shared/constants/initial-page-select.const';
// Utils
import { formattedDate } from '@app/core/utils/date-format-datepicker.utils';
// Const
import { STATUS_PROVIDERS_FILTER } from '@app/core/const/status-providers.const';
import { listSelectToggle } from '@app/fmx-shared/constants/list-select-toggle.const';
var FmxRechargesRepaysFilterComponent = /** @class */ (function () {
    function FmxRechargesRepaysFilterComponent(fb, state, companyAggrupationsService, providersService) {
        this.fb = fb;
        this.state = state;
        this.companyAggrupationsService = companyAggrupationsService;
        this.providersService = providersService;
        this.changeFilter = new EventEmitter();
        this.listToggle = listSelectToggle;
        this.showButtonsFilters = false;
        this.unsubscribe = new Subject();
        this.TYPE_SELECT = {
            selectProv: 'providers',
            selectComp: 'company_aggrupation'
        };
    }
    FmxRechargesRepaysFilterComponent.prototype.ngOnInit = function () {
        this.initFilter = this.state.get('filterRechargesRepaysSub');
        this.initFilterForm();
        this.enableListProviders
            ? this.getProviders(INITIAL_PAGE_SELECT)
            : this.getCompanyAggrupations(INITIAL_PAGE_SELECT);
        this.setParamsFilter();
    };
    FmxRechargesRepaysFilterComponent.prototype.setParamsFilter = function () {
        this.changeFilter.emit(this.formRawValue());
        this.showButtonsFilters = true;
    };
    FmxRechargesRepaysFilterComponent.prototype.resetFilter = function () {
        this.formFilter.patchValue(this.initFilter);
    };
    FmxRechargesRepaysFilterComponent.prototype.handleSearchSelect = function (search, selectMode) {
        switch (selectMode) {
            case this.TYPE_SELECT.selectProv:
                this.getProviders(1, search);
                break;
            default:
                this.getCompanyAggrupations(1, search);
        }
    };
    FmxRechargesRepaysFilterComponent.prototype.handleScrollBottom = function (page, selectMode) {
        if (page === void 0) { page = 1; }
        switch (selectMode) {
            case this.TYPE_SELECT.selectProv:
                this.getProviders(page);
                break;
            default:
                this.getCompanyAggrupations(page);
        }
    };
    FmxRechargesRepaysFilterComponent.prototype.formRawValue = function () {
        this.enableListProviders
            ? this.formFilter.removeControl('company_aggrupation')
            : this.formFilter.removeControl('provider');
        return __assign({}, this.formFilter.value, { created_at_from: formattedDate(this.getControl('created_at_from')), created_at_to: formattedDate(this.getControl('created_at_to')) });
    };
    FmxRechargesRepaysFilterComponent.prototype.initFilterForm = function () {
        this.formFilter = this.fb.group({
            company_aggrupation: [this.initFilter.company_aggrupation],
            provider: [this.initFilter.provider],
            service: [this.initFilter.service],
            created_at_from: [this.initFilter.created_at_from],
            created_at_to: [this.initFilter.created_at_to],
            pending: [this.initFilter.pending]
        });
    };
    FmxRechargesRepaysFilterComponent.prototype.getControl = function (nameControl) {
        return this.formFilter.get(nameControl).value;
    };
    FmxRechargesRepaysFilterComponent.prototype.getCompanyAggrupations = function (page, search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        this.companyAggrupationsService
            .getCompanyAggrupationsByPage(page, search)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (data) { return (_this.companyAggrupations = data); });
    };
    FmxRechargesRepaysFilterComponent.prototype.getProviders = function (page, search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        this.providersService
            .getProvidersByPage(page, search, STATUS_PROVIDERS_FILTER.all)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (data) { return (_this.listProviders = data); });
    };
    return FmxRechargesRepaysFilterComponent;
}());
export { FmxRechargesRepaysFilterComponent };
