import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public baseUrl: string = environment.apiUrl;
  constructor(public authService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getAuthToken();

    if (token) {
      // tslint:disable:no-parameter-reassignment
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }

    request = request.clone({
      url: checkExceptionUrls(request.url)
        ? request.url
        : // tslint:disable-next-line: restrict-plus-operands
          this.baseUrl + request.url
    });

    return next.handle(request);
  }
}

function checkExceptionUrls(requestUrl: string): boolean {
  if (
    requestUrl.indexOf('i18n') !== -1 ||
    requestUrl.indexOf('amazonaws') !== -1
  ) {
    return true;
  }

  return false;
}
