import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Services
import { ImportFileMassiveFacadeService } from '@app/fmx-shared/services/import-files/import-file-massive-facade.service';

// Models
import { ConfigUploadFiles } from '@app/core/models/config-upload-files.interface';

// Environment
import { environment } from 'environments/environment';

@Component({
  selector: 'app-fmx-upload-excel-massive-modal',
  templateUrl: './fmx-upload-excel-massive-modal.component.html'
})
export class FmxUploadExcelMassiveModalComponent implements OnInit {
  title: string;
  typeUpload: string;
  groupId: number;

  configUploadFiles: ConfigUploadFiles = {
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    text_button: 'Adjuntar',
    multiple: false,
    showThumbnails: true,
    only_icon: true,
    icon: 'add_box'
  };

  fileToImport: File;

  constructor(
    private facade: ImportFileMassiveFacadeService,
    private dialogRef: MatDialogRef<FmxUploadExcelMassiveModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit() {
    this.title = this.data && this.data.title;
    this.typeUpload = this.data && this.data.typeUpload;
    this.groupId = this.data && this.data.groupId;
  }

  close(refresh: boolean = false) {
    this.dialogRef.close(refresh);
  }

  changeInputFiles(event: File) {
    this.fileToImport = event;
  }

  removeInputFiles() {
    this.fileToImport = null;
  }

  importFile() {
    this.facade.sendFileMassive(
      this.typeUpload,
      this.getFormData(),
      this.groupId
    );

    this.close();
  }

  downloadTemplate() {
    const fileDownload =
      this.typeUpload === 'services'
        ? 'PlantillaImportacionServiciosFamaex.xlsx'
        : 'PlantillaImportacionEspaciosFamaex.xlsx';
    const route = `${environment.apiUrl}files/batches/${fileDownload}`;

    window.open(route, '_blank');
  }

  private getFormData(): FormData {
    const formData = new FormData();
    formData.append(`[batch][file]`, this.fileToImport);

    return formData;
  }
}
