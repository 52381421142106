import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fmx-editable-cell-price',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="fmx-form">
      <div
        class="fmx-form-group fmx-form-icon fmx-form-group-no-padding fmx-form-sm"
      >
        <div class="fmx-form-input-container">
          <input matInput type="number" [formControl]="control" />
          <span class="input-icon">&euro;</span>
        </div>
      </div>
    </div>
  `
})
export class EditableCellPriceComponent {
  @Input() cell: any;
  @Input() control: FormControl;
}
