
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <p class="font-16 color-secondary" [innerHTML]="data.content"></p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="fmx-btn fmx-btn-clear fmx-btn-secondary fmx-btn-bold"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button class="fmx-btn fmx-btn-red" (click)="success()">Confirmar</button>
    </mat-dialog-actions>
  