import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CompaniesService {
  constructor(private http: HttpClient) {}

  getCompany(idCompany: number): Observable<any> {
    const endpoint = `companies/${idCompany}`;

    return this.http.get(endpoint);
  }

  updateCompany(idCompany: number, data: any): Observable<any> {
    const endpoint = `companies/${idCompany}`;

    return this.http.put(endpoint, data);
  }

  addUserCompany(idCompany: number, data: any): Observable<any> {
    const endpoint = `companies/${idCompany}/users`;

    return this.http.post(endpoint, data);
  }

  getGroupsFromCompany(idCompany: number): Observable<any> {
    const endpoint = `companies/${idCompany}/children`;

    return this.http.get(endpoint);
  }

  createGroup(idCompany: number, data: any): Observable<any> {
    const endpoint = `companies/${idCompany}/groups`;

    return this.http.post(endpoint, data);
  }

  getFiscalInfoCompany(idCompany: number): Observable<any> {
    const endpoint = `companies/${idCompany}/fiscal_info?include=state.country`;

    return this.http.get(endpoint);
  }

  getUsersCompany(idCompany: number): Observable<any> {
    const endpoint = `companies/${idCompany}/users`;

    return this.http.get(endpoint);
  }

  getInvoicesCompany(idCompany: number): Observable<any> {
    const endpoint = `companies/${idCompany}/invoices`;

    return this.http.get(endpoint);
  }
}
