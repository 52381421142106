import { Pipe, PipeTransform } from '@angular/core';

const NOTIFICATION_TYPES = {
  pending: '#18C98C',
  expired: '#dc3545'
};

@Pipe({
  name: 'statusNotificationComplaintsColor'
})
export class StatusNotificationComplaintsColorPipe implements PipeTransform {
  transform(value: any): string {
    return NOTIFICATION_TYPES[value] || value;
  }
}
