// tslint:disable:no-reserved-keywords
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

// Models
import { Complaint } from '../models/complaint.model';

interface ComplaintsStateModel {
  complaints: Complaint[];
  loadingComplaints: boolean;
  filterComplaints: any;
  paginationComplaints: any;
}

const DEFAULT_STATE: ComplaintsStateModel = {
  complaints: null,
  loadingComplaints: false,
  filterComplaints: {
    service_id: '',
    severity: '',
    created_at_from: moment().startOf('month').toDate(),
    created_at_to: moment().endOf('month').toDate(),
    kind: '',
    status: '',
    provider: '',
    company_aggrupation: '',
    country: '',
    state: '',
    facility_manager: '',
    network_manager: ''
  },
  paginationComplaints: {
    per_page: 10,
    current_page: 1,
    last_page: null,
    page: 1
  }
};

@Injectable()
export class ComplaintsState {
  complaintsSub = new BehaviorSubject<Complaint[]>(DEFAULT_STATE.complaints);
  complaints$ = this.complaintsSub.asObservable();

  loadingComplaintsSub = new BehaviorSubject<boolean>(
    DEFAULT_STATE.loadingComplaints
  );
  loadingComplaints$ = this.loadingComplaintsSub.asObservable();

  filterComplaintsSub = new BehaviorSubject<boolean>(
    DEFAULT_STATE.filterComplaints
  );
  filterComplaints$ = this.filterComplaintsSub.asObservable();

  paginationComplaintsSub = new BehaviorSubject<boolean>(
    DEFAULT_STATE.paginationComplaints
  );
  paginationComplaints$ = this.paginationComplaintsSub.asObservable();

  set(key: string, payload: any) {
    this[key].next(payload);
  }

  get(key: string) {
    return this[key].getValue();
  }

  reset() {
    this.complaintsSub.next(DEFAULT_STATE.complaints);
    this.loadingComplaintsSub.next(DEFAULT_STATE.loadingComplaints);
  }

  resetKey(key: string, keySub: string) {
    this[key].next(DEFAULT_STATE[keySub]);
  }
}
