// tslint:disable: no-void-expression
// tslint:disable: restrict-plus-operands
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { finalize, take } from 'rxjs/operators';

import { SelectOption } from '@app/core/models/select-option.model';
import { DataService } from '@app/core/services/data/data.service';
import { ImagesService } from '@app/core/services/images/images.service';
import { getFormData } from '@app/core/utils/form-data.utils';

@Component({
  selector: 'fmx-add-image-note-visit',
  templateUrl: './fmx-add-image-note-visit.component.html'
})
export class FmxAddImageNoteVisitComponent implements OnInit, OnChanges {
  @Input() serviceId: string;
  @Input() visitId: string;
  @Input() showEdit = true;
  @Input() canAddNote = false;
  @Input() openAddNote = false;

  @Output() changeImages = new EventEmitter();
  @Output() withoutNote = new EventEmitter<boolean>();

  loadingImages = false;
  loadingNew = false;
  loadingDelete = false;
  images: any[];
  files: any[];
  kindControl = new FormControl('', [Validators.required]);

  @ViewChild('file') file: any;

  imageKinds: SelectOption[];

  constructor(
    private imagesService: ImagesService,
    private dataService: DataService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openAddNote && changes.openAddNote.currentValue) {
      this.addFiles();
    }
  }

  ngOnInit() {
    this.getImageKinds();
    this.getElements();
  }

  typesFilesAccept(): string {
    return this.canAddNote
      ? 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      : 'image/*';
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  deleteImage(image: any) {
    this.changeImages.emit();
    this.loadingDelete = true;
    this.imagesService
      .deleteServiceVisitImage(this.serviceId, this.visitId, image.id)
      .pipe(finalize(() => (this.loadingDelete = false)))
      .subscribe(() => {
        this.getElements();
      });
  }

  deleteFile(file: any) {
    this.changeImages.emit();
    this.loadingDelete = true;
    this.imagesService
      .deleteServiceVisitFile(this.serviceId, this.visitId, file.id)
      .pipe(finalize(() => (this.loadingDelete = false)))
      .subscribe(() => {
        this.getElements();
      });
  }

  onFilesAdded() {
    const files = this.file.nativeElement.files;

    if (files) {
      // tslint:disable-next-line:no-increment-decrement
      for (let i = 0; i < files.length; i++) {
        const isLastFile = i === files.length - 1 ? true : false;
        this.createFile(files[i], isLastFile);
      }
    }
  }

  // tslint:disable-next-line:no-reserved-keywords
  private isImage(type: string) {
    return type.includes('image/');
  }

  private getImageKinds() {
    this.dataService
      .getImageKinds()
      .pipe(take(1))
      .subscribe((data: SelectOption[]) => {
        this.imageKinds = data.filter((item: any) => item.value !== 'note');
      });
  }

  private getVisitImages() {
    this.loadingImages = true;

    this.imagesService
      .getServiceVisitImages(this.serviceId, this.visitId)
      .pipe(finalize(() => (this.loadingImages = false)))
      .subscribe((data: any[]) => {
        this.images = data;
      });
  }

  private getVisitFiles() {
    this.loadingImages = true;

    this.imagesService
      .getServiceVisitFiles(this.serviceId, this.visitId)
      .pipe(finalize(() => (this.loadingImages = false)))
      .subscribe((data: any[]) => {
        this.files = data;

        if (!this.files.length) {
          this.withoutNote.emit(true);
        }
      });
  }

  private getElements() {
    this.canAddNote ? this.getVisitFiles() : this.getVisitImages();
  }

  private createFile(data: File, isLastFile: boolean) {
    this.changeImages.emit();
    this.loadingNew = true;

    const body = {
      file: data,
      kind: this.kindControl.value ? this.kindControl.value : 'note'
    };

    if (this.isImage(data.type)) {
      this.imagesService
        .createServiceVisitImage(
          this.serviceId,
          this.visitId,
          getFormData(body, 'image')
        )
        .pipe(finalize(() => (this.loadingNew = false)))
        .subscribe(() => {
          if (isLastFile) {
            this.finishedUpload();
          }
        });
    } else {
      this.imagesService
        .createServiceVisitDocument(
          this.serviceId,
          this.visitId,
          getFormData(body, 'file_attachment')
        )
        .pipe(finalize(() => (this.loadingNew = false)))
        .subscribe(() => {
          if (isLastFile) {
            this.withoutNote.emit(false);
            this.finishedUpload();
          }
        });
    }
  }

  private finishedUpload() {
    this.getElements();
    this.resetFormControl();
  }

  private resetFormControl() {
    this.kindControl.reset();
  }
}
