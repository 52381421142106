import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Billing } from '@app/fmx-modules/fmx-billing/interfaces/billing.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class InvoicesService {
  constructor(private http: HttpClient) {}

  getClientInvoices(filter: any, pagination: any): Observable<any> {
    const endpoint = `client_invoices`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  uploadInvoiceToNavision(
    typeInvoice: string,
    idInvoice: number,
    data: any
  ): Observable<any> {
    const endpoint = `${typeInvoice}/${idInvoice}/navision_synchronizations`;

    return this.http.post(endpoint, data);
  }

  sendInvoice(
    typeInvoice: string,
    idInvoice: number,
    data: any
  ): Observable<any> {
    const endpoint = `${typeInvoice}/${idInvoice}/sendings`;

    return this.http.post(endpoint, data);
  }

  downloadInvoice(typeInvoice: string, idInvoice: number): Observable<any> {
    const endpoint = `${typeInvoice}/${idInvoice}.pdf`;

    return this.http.get(endpoint, { responseType: 'blob' });
  }

  updateInvoice(
    typeInvoice: string,
    idInvoice: number,
    data: any
  ): Observable<any> {
    const endpoint = `${typeInvoice}/${idInvoice}`;

    return this.http.put(endpoint, data);
  }

  getInvoices(filter: any, pagination: any): Observable<any> {
    const endpoint = `invoices`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  createInvoice(data: any): Observable<Billing> {
    const endpoint = `invoices`;

    return this.http
      .post(endpoint, data)
      .pipe(
        map((response: any) =>
          response.data.map((item: any) => new Billing(item))
        )
      );
  }

  amendmentInvoice(idInvoice: number): Observable<any> {
    const endpoint = `invoices/${idInvoice}/amendment_invoice`;
    const data = { invoice: { dummy: 'true' } };

    return this.http.post(endpoint, data);
  }

  getAmendmentInvoice(amendmentInvoiceId: number): Observable<any> {
    const endpoint = `invoices/${amendmentInvoiceId}/amendment_invoice`;

    return this.http.get(endpoint);
  }

  updateAmendmentInvoice(idInvoice: number, data: any): Observable<any> {
    const endpoint = `invoices/${idInvoice}/amendment_invoice`;

    return this.http.put(endpoint, data);
  }

  cancelInvoice(idInvoice: number): Observable<any> {
    const endpoint = `invoices/${idInvoice}`;

    return this.http.delete(endpoint);
  }

  downloadZipInvoices(params: any): Observable<any> {
    const endpoint = 'invoices.zip';

    return this.http.get(endpoint, { params, responseType: 'blob' });
  }

  downloadXls(params: any): Observable<any> {
    return this.http.get('invoices.xlsx', {
      params,
      responseType: 'blob'
    });
  }

  downloadAmendmentInvoice(idInvoice: number): Observable<any> {
    return this.http.get(`invoices/${idInvoice}/amendment_invoice.pdf`, {
      responseType: 'blob'
    });
  }
}
